import { React } from "react";
import { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import { listAgentMetabaseReports } from "./../../../config/reseller_api_calls";
import HelperClass from "./../../../config/helperClass";
import { addSettings, addMsaInfo } from "../../../actions";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { ADMIN_API_URL } from "./../../../config/config";
import { TableLoader } from "../../../components/ContentLoaders/newLoaders/TableLoader";
import RichTextEditorComponent from "../../../components/Reseller/RichTextEditorComponent";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import TextField from "../../../components/InputFields/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import { extendMoment } from "moment-range";
import dateFormat, { masks } from "dateformat";
const extendedMoment = extendMoment(moment);

const auth = new HelperClass();

class RequestAgentReport extends Component {
  constructor() {
    super();
    const today = extendedMoment();
    const monthAgo = moment().subtract(7, "day");
    this.state = {
      tableData: [],
      errorMessage: "",
      agentId: "",
      successMessage: "",
      waitMessage: "",
      title: "",
      start_date: monthAgo.format("MMMM DD, YYYY"),
      end_date: today.format("MMMM DD, YYYY"),
      value: moment.range(monthAgo, today),
      description: "",
      add_picture: "",
      add_pictures: [],
      add_picture_names: [],
    };
  }

  async componentDidMount() {
    const servicesResponce = await listAgentMetabaseReports(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("Available RFQs: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "integration_missing"
    ) {
      //window.location.replace("/error");
      this.setState({
        tableLoader: false,
        tableLoaderListing: false,
        reports_allowed: "No",
      });
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message !== "integration_missing"
    ) {
      //window.location.replace("/error");
      this.setState({
        tableLoader: false,
        tableLoaderListing: false,
      });
    } else if (servicesResponce.data.status === 200) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
        tableLoaderListing: false,
        reports_allowed: "Yes",
      });
    } else {
      this.setState({
        tableLoader: false,
        tableLoaderListing: false,
      });
      //window.location.replace("/error");
    }
  }

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  onSelect = (value) => {
    this.setState({
      value,
      start_date: value.start.format("MMMM DD, YYYY"),
      end_date: value.end.format("MMMM DD, YYYY"),
      isOpen: false,
    });
  };

  onToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files); // Convert FileList to an array

    if (!selectedFiles.length) return; // No files selected

    // File type validation
    const allowedFileTypes = [".txt", ".pdf", ".csv", ".xlsx", ".xls", ".zip"];
    const invalidFiles = selectedFiles.filter(
      (file) =>
        !allowedFileTypes.includes(
          `.${file.name.split(".").pop().toLowerCase()}`
        )
    );

    if (invalidFiles.length > 0) {
      this.setState({
        docErrorMessage:
          "Invalid file type detected. Please select valid files.",
      });
      return;
    }

    // File size validation (50MB max per file)
    const maxSizeBytes = 50 * 1024 * 1024;
    const oversizedFiles = selectedFiles.filter(
      (file) => file.size > maxSizeBytes
    );

    if (oversizedFiles.length > 0) {
      this.setState({
        docErrorMessage:
          "Some files exceed the 50MB limit. Choose smaller files.",
      });
      return;
    }

    // Debugging output to ensure files are being processed correctly
    console.log("Selected Files: ", selectedFiles);
    console.log("Current add_pictures in state: ", this.state.add_pictures);

    // Append new files to existing array
    this.setState((prevState) => {
      const updatedPictures = [...prevState.add_pictures, ...selectedFiles];
      console.log("Updated Pictures: ", updatedPictures);
      // **Limit total files to 5**
      if (updatedPictures.length > 5) {
        return {
          docErrorMessage: "You can upload a maximum of 5 files.",
        };
      }
      return {
        add_pictures: updatedPictures,
        docErrorMessage: "", // Clear error message
        add_picture_names: updatedPictures.map((file) => file.name),
      };
    });
  };

  handleRemoveFile = (index) => {
    this.setState((prevState) => {
      const updatedPictures = [...prevState.add_pictures];
      const updatedNames = [...prevState.add_picture_names];

      // Remove file from both arrays by index
      updatedPictures.splice(index, 1);
      updatedNames.splice(index, 1);

      return {
        add_pictures: updatedPictures,
        add_picture_names: updatedNames,
      };
    });
  };

  fieldValidationUsername = () => {
    let { title, description } = this.state;

    if (title === "") {
      this.setState({
        errorMessageTitle: "Missing Field",
        errorMessage: "Title is required.",
        disabled: false,
      });
      document
        .getElementById("Support_Block")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return false;
    } else if (description === "") {
      this.setState({
        errorMessageTitle: "Missing Field",
        errorMessage: "Description is required.",
        disabled: false,
      });
      document
        .getElementById("Support_Block")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return false;
    } else {
      this.setState({
        errorMessageTitle: "",
        errorMessage: "",
        disabled: true,
      });
    }
    return true;
  };

  submitRequest = async (e) => {
    e.preventDefault();
    let {
      title,
      description,
      start_date,
      end_date,
      add_picture,
      add_picture_name,
      add_pictures,
    } = this.state;

    this.setState({
      verifyPasswordButton: true,
      disabled: true,
      errorMessageTitle: "",
      errorMessage: "",
    });
    if (this.fieldValidationUsername() === true) {
      const formData = new FormData();
      formData.append("token", process.env.REACT_APP_API_TOKEN);
      formData.append("api_accountno", process.env.REACT_APP_API_ACCOUNTNO);
      formData.append("accountno", auth.getAccount());
      //formData.append("file", add_picture);
      //formData.append("fileName", add_picture_name);
      formData.append("title", title);
      formData.append("description", description);
      formData.append("start_date", start_date);
      formData.append("end_date", end_date);
      add_pictures.forEach((file) => {
        formData.append("files", file);
      });

      try {
        axios
          .post(
            ADMIN_API_URL + "metabase_external/submit_agent_report_request",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Credentials": true,
              },
            },
            {}
          )
          .then((response) => {
            console.log("response: ", response.data);
            if (
              response.data.status === 404 &&
              response.data.message === "no_record"
            ) {
              this.setState({
                errorMessageTitle: "Missing Client",
                errorMessage: "Agent does not found.",
                disabled: false,
                submitRfq_button_loader: false,
                successMessage: "",
              });
              document
                .getElementById("Support_Block")
                ?.scrollIntoView({ top: 0, behavior: "smooth" });
              setTimeout(() => {
                this.setState({
                  errorMessage: "",
                });
              }, 4000);
            } else if (response.data.status === 403) {
              this.setState({
                errorMessage: response.data.message,
                disabled: false,
                submitRfq_button_loader: false,
                successMessage: "",
              });
              document
                .getElementById("Support_Block")
                ?.scrollIntoView({ top: 0, behavior: "smooth" });
              setTimeout(() => {
                this.setState({
                  errorMessage: "",
                });
              }, 4000);
            } else if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                successMessage: "Your request has been submitted successfully.",
                errorMessage: "",
                final_login_url: response.data.login_url,
                showLoginButton: true,
              });
              document
                .getElementById("Support_Block")
                ?.scrollIntoView({ top: 0, behavior: "smooth" });
              setTimeout(() => {
                window.location.replace(
                  "/userReports"
                );
              }, 3000);

              //this.nextStep();
            } else {
              this.setState({
                errorMessageTitle: "System Error",
                errorMessage:
                  "There is some error while submitting the request.",
                disabled: false,
                successMessage: "",
                submitRfq_button_loader: false,
              });
              document
                .getElementById("Support_Block")
                ?.scrollIntoView({ top: 0, behavior: "smooth" });
              setTimeout(() => {
                this.setState({
                  errorMessage: "",
                });
              }, 4000);
            }
            this.setState({
              submitRfq_button_loader: false,
            });
          });
      } catch (error) {
        this.setState({
          errorMessageTitle: "System Error",
          errorMessage: "There is some error while submitting the request.",
          disabled: false,
          successMessage: "",
          submitRfq_button_loader: false,
        });
        document
          .getElementById("Support_Block")
          ?.scrollIntoView({ top: 0, behavior: "smooth" });
        setTimeout(() => {
          this.setState({
            errorMessage: "",
          });
        }, 4000);
      }
    }
  };

  handleChanges = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleEditorChange = (updatedContent) => {
    //console.log('updatedContent: ',updatedContent);
    this.setState({ description: updatedContent });
  };

  render() {
    const { step, isOpen, value, start_date, end_date } = this.state;

    const formattedDateRange = start_date ? `${start_date} - ${end_date}` : "";
    return (
      <div className="nk-content " id="Support_Block">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MSA WARNING END */}
          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}

              {/* RIGHTS MODAL End */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        color="inherit"
                        onClick={() => this.addMenuHistory("/")}
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <HomeIcon fontSize="inherit" className="" />
                        Home
                      </Link>
                      <Link
                        color="inherit"
                        onClick={() => this.addMenuHistory("/userReports")}
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        Requests
                      </Link>
                      <Typography color="textPrimary" className="">
                        Add Request
                      </Typography>
                    </Breadcrumbs>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      <div className="card card-bordered">
                        <div className="card-inner">
                          {this.state.errorMessage !== "" ? (
                            <div
                              className="example-alert example-alert-revoke mt-3 mb-3"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.successMessage !== "" ? (
                            <div
                              className="example-alert example-alert-revoke mt-3 mb-3"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Suucess</h4>
                                  <p>{this.state.successMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.tableLoader === true ? (
                            TableLoader()
                          ) : (
                            <form
                              method="post"
                              className="pageForm"
                              onSubmit={this.submitRequest}
                            >
                              <div className="row g-4">
                                <div className="col-lg-6 mt-4">
                                  <div class="form-group">
                                    <label class="form-label" for="title">
                                      Title <sup>*</sup>
                                    </label>
                                    <div class="form-control-wrap">
                                      <input
                                        type="text"
                                        class="form-control form-control-lg"
                                        id="title"
                                        name="title"
                                        value={this.state.title}
                                        onChange={this.handleChanges}
                                        placeholder=""
                                      />
                                      <small>
                                        Enter descriptive title for your report
                                        request.
                                      </small>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 mt-4">
                                  <div className="form-group">
                                    <div onClick={this.onToggle} className="">
                                      <TextField
                                        label="Date and Time"
                                        variant="outlined"
                                        fullWidth
                                        value={formattedDateRange}
                                        InputProps={{
                                          onClick: this.onToggle,
                                        }}
                                        placeholder="Select Date Range"
                                      />
                                      {/*<small>
                                        Select date and time for data range you
                                        want to include in the report.
                                      </small>*/}
                                    </div>
                                  </div>
                                  {isOpen && (
                                    <div
                                      className="fixed inset-0"
                                      style={{
                                        zIndex: 1000,
                                        position: "absolute",
                                        marginTop: "-20px",
                                      }}
                                    >
                                      <div
                                        className="absolute inset-0"
                                        onClick={this.onToggle}
                                      ></div>
                                      <div
                                        className="absolute top-40 left-1/2 -translate-x-1/2 bg-white shadow-lg rounded-lg p-4"
                                        style={{ zIndex: 1001 }}
                                      >
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "10px",
                                            right: "10px",
                                            cursor: "pointer",
                                            zIndex: 1002,
                                          }}
                                          onClick={this.onToggle}
                                        >
                                          <em className="icon ni ni-cross"></em>
                                        </div>
                                        <DateRangePicker
                                          value={value}
                                          onSelect={this.onSelect}
                                          singleDateRange={true}
                                          numberOfCalendars={2}
                                          showLegend={true}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>

                                <div
                                  className="col-lg-12"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <h6
                                    style={{ margin: 0, marginRight: "10px" }}
                                  >
                                    Description <sup>*</sup>
                                  </h6>
                                </div>

                                <div className="col-lg-12">
                                  <div className="form-group">
                                    <RichTextEditorComponent
                                      agreementContent={this.state.description}
                                      onContentChange={this.handleEditorChange}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-12 mt-4">
                                  <div className="nk-block-head">
                                    <div className="nk-block-head-content">
                                      <h5 className="nk-block-title title">
                                        Other Attachment(s){" "}
                                        <Tooltip
                                          title="Any other supporting attachments"
                                          placement="right"
                                        >
                                          <em
                                            className="icon ni ni-info"
                                            style={{
                                              color: "#6576ff",
                                            }}
                                          ></em>
                                        </Tooltip>
                                      </h5>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <div
                                      className="form-control-group"
                                      id="fileContainer"
                                    >
                                      <input
                                        type="file"
                                        className="file-block"
                                        //defaultValue={this.state.add_picture}
                                        accept=".pdf, .xlx, .xlxs, .csv, .zip, .txt"
                                        onChange={this.handleFileChange}
                                        multiple
                                      />
                                    </div>
                                    <p
                                      style={{
                                        fontSize: "0.8rem",
                                        margin: "5px 0 0 3px",
                                      }}
                                    >
                                      Maximum 5 files are allowed.
                                      <br />
                                      Supported file formats include: PDF, CSV,
                                      ZIP, TXT.
                                    </p>
                                    <p
                                      style={{
                                        fontSize: "0.8rem",
                                        margin: "2px 0 0 3px",
                                      }}
                                    >
                                      Maximum file size allowed: 50MB.
                                    </p>
                                    {this.state.add_picture_names.length >
                                      0 && (
                                      <ul style={{ marginLeft: "30px" }}>
                                        {this.state.add_picture_names.map(
                                          (name, index) => (
                                            <li
                                              class="text-primary"
                                              style={{ listStyle: "disc" }}
                                              key={index}
                                            >
                                              {name}{" "}
                                              <a
                                                onClick={() =>
                                                  this.handleRemoveFile(index)
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                  marginLeft: "10px",
                                                  fontSize: "16px",
                                                }}
                                                class="text-danger"
                                              >
                                                <em class="icon ni ni-trash"></em>
                                              </a>
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    )}
                                  </div>
                                </div>

                                <div className="col-lg-12">
                                  <div className="form-group text-right">
                                    <button
                                      type="submit"
                                      className="btn btn-lg btn-primary"
                                      disabled={this.state.disabled}
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RequestAgentReport);
