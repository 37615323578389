import { React } from "react";
import { Component } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addSettings } from "../../../actions";
import {
  listMetabaseReports,
  getSingleRfq,
} from "./../../../config/client_api_calls";

import $ from "jquery";

import BusinessInformation from "../../../components/Client/CreditApplication/BusinessInformation";
import FinancialInformation from "../../../components/Client/CreditApplication/FinancialInformation";
import TradeReferences from "../../../components/Client/CreditApplication/TradeReferences";
import SignatoryDetails from "../../../components/Client/CreditApplication/SignatoryDetails";
import SsoSuccess from "../../../components/Client/CreditApplication/Success";

import DataTableExtended from "../../../components/Tables/DataTableExtended";
import { TableLoader } from "../../../components/ContentLoaders/newLoaders/TableLoader";
import { tableLoaderListing } from "../../../components/ContentLoaders/table_loader_listing";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  APP_LIVE_URL,
} from "./../../../config/config";
import MutextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";

const auth = new HelperClass();

class MetabaseReports extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
      tableDataSingle: [],
      serviceData: [],
      tableLoaderListing: true,
      tableLoader: true,
      formLoader: true,
      errorMessage: "",
      successMessage: "",
      rfq_details: [],
      quotation_services: [],
      rfq_requirements: "",
      rfq_attachment: "",
      all_answers: [],
      reports_allowed: "",
      columnServices: [
        { name: "title", label: "Title" },
        {
          name: "created_at",
          label: "Created Date",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{auth.formatDateTime(value)}</div>;
            },
          },
        },
        {
          name: "published_at",
          label: "Published Date",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{auth.formatDateTime(value)}</div>;
            },
          },
        },
        {
          name: "status",
          label: "Status",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setStatus(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "report_status",
          label: "Published Status",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setStatusPublished(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "url",
          label: "Report",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setReportLink(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "id",
          label: "Action",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setEditLink(value)}</div>;
            },
          },
        },
      ],
    };
  }

  async componentDidMount() {
    const servicesResponce = await listMetabaseReports(
      auth.getClientAccount(),
      auth.getClientToken()
    );
    console.log("Available RFQs: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "integration_missing"
    ) {
      //window.location.replace("/error");
      this.setState({
        tableLoader: false,
        tableLoaderListing: false,
        reports_allowed: "No",
      });
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message !== "integration_missing"
    ) {
      //window.location.replace("/error");
      this.setState({
        tableLoader: false,
        tableLoaderListing: false,
      });
    } else if (servicesResponce.data.status === 200) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
        tableLoaderListing: false,
        reports_allowed: "Yes",
      });
    } else {
      this.setState({
        tableLoader: false,
        tableLoaderListing: false,
      });
      //window.location.replace("/error");
    }
  }

  setStatus = (value, tableMeta) => {
    let domain_str = value;
    //console.log("domain_str: ", domain_str);
    if (domain_str === "processing") {
      return (
        <div>
          <span className="badge badge-dim badge-danger">
            {" "}
            <em className="icon ni ni-alert-c" title="Active"></em> In process
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <span className="badge badge-success">
            {" "}
            <em className="icon ni ni-done "></em> Processed
          </span>
        </div>
      );
    }
  };

  setStatusPublished = (value, tableMeta) => {
    let domain_str = value;
    //console.log("domain_str: ", domain_str);
    if (domain_str === 0) {
      return (
        <div>
          <span className="badge badge-dim badge-danger">
            {" "}
            <em className="icon ni ni-alert-c" title="Active"></em> Not
            Published
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <span className="badge badge-success">
            {" "}
            <em className="icon ni ni-done "></em> Published
          </span>
        </div>
      );
    }
  };

  setReportLink = (value, tableMeta) => {
    let domain_str = value;
    let report_id = tableMeta.rowData[6];
    let is_published = tableMeta.rowData[4];
    if (domain_str && domain_str !== "") {
      if (is_published === 1) {
        return (
          <Tooltip title="View report">
            <a
              onClick={() =>
                this.addMenuHistory(
                  "/" +
                    auth.getResellerRegToken() +
                    "/client/viewRequest/" +
                    report_id
                )
              }
              style={{ cursor: "pointer" }}
            >
              <em
                class="icon ni ni-reports text-primary"
                style={{ fontSize: "22px" }}
              ></em>
            </a>
          </Tooltip>
        );
      } else {
        return "---";
      }
    } else {
      return "---";
    }
  };

  setEditLink = (value, tableMeta) => {
    let domain_str = value;
    //console.log("domain_str: ", domain_str);
    if (domain_str && domain_str !== "") {
      return (
        <Tooltip title="Edit report">
          <a
            onClick={() =>
              this.addMenuHistory(
                "/" +
                  auth.getResellerRegToken() +
                  "/client/editRequest/" +
                  domain_str
              )
            }
            style={{ cursor: "pointer" }}
          >
            <em
              class="icon ni ni-edit text-primary"
              style={{ fontSize: "22px" }}
            ></em>
          </a>
        </Tooltip>
      );
    } else {
      return "---";
    }
  };

  downloadPdfRFQ = async (d_f_name) => {
    var url = d_f_name;
    url = APP_LIVE_URL + "files_data/rfq/" + url;
    const file_name = "RFQ-" + d_f_name.split("/").pop();

    fetch(url)
      .then((x) => x.blob())
      .then((b) => {
        const url = window.URL.createObjectURL(b);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
        this.setState({ errorMessage: "", downloadingIndex: null });
      })
      .catch((error) => {
        this.setState({
          errorMessage: "There is some error while downloading the attachment.",
          downloadingIndex: null,
        });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 4000);
        console.error("Fetch error:", error);
      });
  };

  setComplete = (value, tableMeta) => {
    let domain_str = value;
    //console.log("domain_str: ", domain_str);
    if (domain_str === "incomplete") {
      return (
        <div>
          <span className="badge badge-outline-warning">
            {" "}
            <em className="icon ni ni-alert-c" title="Active"></em> In Progress
          </span>
        </div>
      );
    } else if (domain_str === "complete") {
      return (
        <div>
          <span className="badge badge-outline-secondary">
            {" "}
            <em className="icon ni ni-done "></em> Submitted
          </span>
        </div>
      );
    }
  };

  viewPdf = (value, tableMeta) => {
    let domain_str = value;
    //console.log("domain_str: ", domain_str);
    if (domain_str && domain_str !== "") {
      return (
        <div>
          <a
            onClick={() => this.downloadPdf(value, tableMeta)}
            style={{ cursor: "pointer" }}
            title="Download PDF"
          >
            <span className="badge badge-outline-secondary">
              {" "}
              <em class="icon ni ni-file-pdf"></em> View Attachement
            </span>
          </a>
        </div>
      );
    } else {
      return (
        <div>
          <span className="badge badge-outline-light">
            {" "}
            <em class="icon ni ni-file-pdf"></em> No Attachement
          </span>
        </div>
      );
    }
  };

  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };

  showEditpage = (value, tableMeta) => {
    this.props.history.push(
      "/" +
        auth.getResellerRegToken() +
        "/client/EditRfq/" +
        tableMeta.rowData[0]
    );
  };

  serviceSigning = async (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[6]);
  };

  defaultText = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = tableMeta.rowData[5];
    if (is_default === "No") {
      return (
        <a
          style={{ cursor: "pointer" }}
          onClick={() => this.setDefaultService(value, tableMeta)}
          disabled={
            this.props.is_msa_signed === "No" ||
            this.props.defaultSettings === "missing"
              ? true
              : false
          }
        >
          <span className="badge badge-outline-primary">
            <em className="icon ni ni-signin"></em> Make Default
          </span>
        </a>
      );
    } else {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Yes
        </span>
      );
    }
  };

  defaultMarketing = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = value.split("###");
    let marketing_content = is_default[0];
    let marketing_link = is_default[1];
    if (marketing_content === "link") {
      return (
        <a href={marketing_link} terget="_blank">
          <span className="badge badge-outline-primary">
            <em className="icon ni ni-signin"></em> Product Info
          </span>
        </a>
      );
    } else if (marketing_content === "pdf") {
      return (
        <a href={marketing_link} terget="_blank">
          <span className="badge badge-outline-primary">
            <em className="icon ni ni-download"></em> View PDF
          </span>
        </a>
      );
    } else {
      return "";
    }
  };

  setMarkUp = async (value, tableMeta) => {
    let service_id = tableMeta.rowData[0];

    window.$("#modalForm").modal("show");
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, h:MM:ss TT");
  };

  showRequirements = async (value, tableMeta) => {
    let rfq_accountno = tableMeta.rowData[0];
    this.setState({
      formLoader: true,
      rfq_requirements: "",
    });
    const servicesResponce = await getSingleRfq(
      auth.getClientAccount(),
      auth.getClientToken(),
      rfq_accountno
    );
    console.log("showDetails: ", servicesResponce.data);
    window.$("#modalFormOptions").modal("show");
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        rfq_details: [],
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      if (
        servicesResponce.data.data[0]["rfq_questions"] &&
        servicesResponce.data.data[0]["rfq_questions"].length > 0
      ) {
        let rfq_questions = servicesResponce.data.data[0]["rfq_questions"];
        for (let i = 0; i < rfq_questions.length; i++) {
          if (
            rfq_questions[i]["question_type"] === "radio" ||
            rfq_questions[i]["question_type"] === "dropdown"
          ) {
            let answer_id = this.doesAnswerIdExist(
              Number(rfq_questions[i]["answer_id"]),
              servicesResponce.data.data[0].all_answers
            );

            rfq_questions[i]["answer"] = answer_id;
            console.log(
              "radio answer answer_id: " +
                rfq_questions[i]["answer_id"] +
                " " +
                answer_id
            );
          } else if (rfq_questions[i]["question_type"] === "checkbox") {
            let answer_id = this.doesAnswerArrayExist(
              JSON.parse(rfq_questions[i]["answer_id"]),
              servicesResponce.data.data[0].all_answers
            );

            rfq_questions[i]["answer"] = answer_id;
            console.log(
              "checkbox answer answer_id: " +
                JSON.parse(rfq_questions[i]["answer_id"]) +
                " " +
                answer_id
            );
          } else {
            rfq_questions[i]["answer"] = rfq_questions[i]["answer_id"];
          }
        }
      }

      this.setState({
        rfq_details: servicesResponce.data.data,
        quotation_services: servicesResponce.data.data[0]["rfq_services"],
        rfq_requirements: servicesResponce.data.data[0]["rfq_comments"],
        rfq_attachment: servicesResponce.data.data[0]["rfq_attachement"],
        all_answers: servicesResponce.data.data[0].all_answers,
      });
    } else {
      this.setState({
        rfq_details: [],
      });
      // window.location.replace("/error");
    }

    window.$("#modalFormOptions").modal("show");
    setTimeout(() => {
      this.setState({
        formLoader: false,
      });
    }, 2000);
  };

  doesAnswerIdExist = (answerIdToCheck, answerArray) => {
    //return answerArray.some((answer) => answer.answer_id === answerIdToCheck);
    const answer = answerArray.find((item) => item.id === answerIdToCheck);
    return answer ? answer.question_options : "";
  };

  doesAnswerArrayExist = (answerIdToCheck, answerArray) => {
    answerIdToCheck = JSON.stringify(answerIdToCheck);
    //return answerArray.some((answer) => answer.answer_id === answerIdToCheck);
    const objectValues = answerIdToCheck.slice(1, -1).split(",");
    var matchingItems = "";
    objectValues.map((setString, index) => {
      console.log("setString: ", setString);
      let answer = answerArray.find((item) => item.id === Number(setString));
      if (answer) {
        matchingItems +=
          "<p>" + (Number(index) + 1) + ": " + answer.question_options + "</p>";
      }
    });

    return (
      <div
        dangerouslySetInnerHTML={{
          __html: matchingItems,
        }}
      />
    );
  };

  downloadPdf = async (d_f_name, tableMeta) => {
    var url = d_f_name;
    let file_name = "RFQ-" + this.getFileNameFromUrl(url);
    fetch(url)
      .then((x) => x.blob())
      .then((b) => {
        // console.log("B INSTANCE", b instanceof Blob);
        const url = window.URL.createObjectURL(b);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        this.setState({
          errorMessage: "There is some error while downloading the attachment.",
        });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 4000);
        // Handle any errors that occurred during the fetch
        console.error("Fetch error:", error);
      });
  };

  downloadPdfDetails = async (d_f_name) => {
    let file_name = "RFQ-" + this.getFileNameFromUrlFront(d_f_name);
    var url = d_f_name;
    fetch(url)
      .then((x) => x.blob())
      .then((b) => {
        // console.log("B INSTANCE", b instanceof Blob);
        const url = window.URL.createObjectURL(b);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        this.setState({
          errorMessage: "There is some error while downloading the attachment.",
        });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 4000);
        // Handle any errors that occurred during the fetch
        console.error("Fetch error:", error);
      });
  };

  getFileNameFromUrlFront = (url) => {
    try {
      // Create a URL object and extract the pathname
      const parsedUrl = new URL(url);
      const pathname = parsedUrl.pathname;
      // Extract the file name from the pathname
      return pathname.substring(pathname.lastIndexOf("/") + 1);
    } catch (error) {
      console.error("Invalid URL:", error);
      return url;
    }
  };

  getFileNameFromUrl = (url) => {
    try {
      const urlObject = new URL(url);
      // Using the pathname for simplicity; adjust as needed based on your URL structure
      const fileName = urlObject.pathname.split("/").pop();
      return fileName;
    } catch (error) {
      console.error("Error extracting file name:", error);
      return "attachement";
    }
  };

  handleChanges = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  handleChangeCheckbox = (input) => (e) => {
    this.setState({
      [input]:
        e.target.type === "checkbox" ? e.target.checked : e.target.checked,
    });
    //console.log("handleChangeCheckbox: ", input);
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  generateRandomNumber = (link) => {
    const min = 1000000; // Minimum 10-digit number (10^9)
    const max = 9999999; // Maximum 10-digit number (10^10 - 1)
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    this.props.history.push(link + "/" + randomNumber);
  };

  addMenuHistory = (link) => {
    this.props.history.push(link);
  };

  nextStep = () => {
    const { step } = this.state;

    this.setState({
      step: step + 1,
    });
  };

  // Go to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };
  firstStep = () => {
    this.setState({
      step: 1,
    });
  };

  render() {
    const { step } = this.state;
    const { email, VerifyUsernameButton } = this.state;
    const values = {
      email,
      VerifyUsernameButton,
    };
    //$(".nk-block .MUIDataTable-paper-2").css("padding", "10px");
    return (
      <div
        className={
          this.props.from === "dashboard"
            ? "nk-content newcontent_padding"
            : "nk-content"
        }
        id="Support_Block"
      >
        <div
          className={
            this.props.from === "dashboard"
              ? "container-fluid newcontainerfluid_padding"
              : "container-fluid"
          }
        >
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}

              {/* RIGHTS MODAL End */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle name="Reports" icon="icon ni ni-reports" />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          {this.state.reports_allowed === "Yes" ? (
                            <li className="nk-block-tools-opt">
                              {this.props.defaultSettings !== "missing" ? (
                                <a
                                  onClick={() =>
                                    this.addMenuHistory("requestReports")
                                  }
                                  style={{
                                    color: "#fff",
                                  }}
                                  className="btn btn-primary"
                                >
                                  <em className="icon ni ni-plus-round"></em>
                                  <span>Request Report</span>
                                </a>
                              ) : null}
                            </li>
                          ) : null}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      <div className="card card-bordered">
                        <div className="card-inner">
                          {this.state.errorMessage !== "" ? (
                            <div
                              className="example-alert example-alert-revoke mt-3 mb-3"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.tableLoader === true ? (
                            TableLoader()
                          ) : (
                            <>
                              {this.state.reports_allowed === "Yes" ? (
                                <DataTableExtended
                                  columns={this.state.columnServices}
                                  tableData={this.state.tableData}
                                  title=""
                                />
                              ) : this.state.reports_allowed === "No" ? (
                                <div
                                  className="example-alert example-alert-revoke mt-3 mb-3"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Missing Integration</h4>
                                      <p>
                                        Reports are not enabled. Please contact
                                        administrator to enable it.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MetabaseReports));
