import { React, createRef, Component } from "react";

import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import $ from "jquery";
import { checkAgent } from "./../../../config/api_calls";
import PROMO_A from "../../../assets/images/promo-a.png";
import PROMO_B from "../../../assets/images/promo-b.png";
import PROMO_C from "../../../assets/images/promo-c.png";
import { COMPANY_NAME, LOGO, THEME_COLOR } from "../../../config/config";
import { ValidateEmail, axiosConfig, normalize } from "../../../config/utility";

import { resendCodeFun } from "../../../config/api_calls";

import TextField from "./TextField";
import { tableLoader } from "../../../components/ContentLoaders/table_loader_register";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import { ThreeDots } from "../../../components/ContentLoaders/newLoaders/ThreeDots";
import HelperClass from "./../../../config/helperClass";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/build/index.css";
import ReCAPTCHA from "react-google-recaptcha";
import Select from "react-select";
import countryList from "react-select-country-list";
import { GoogleLogin } from "@react-oauth/google";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { PhoneNumberUtil } from "google-libphonenumber";
import MutextField from "@material-ui/core/TextField";

import PasswordStrengthBar from 'react-password-strength-bar';
import Tooltip from "@material-ui/core/Tooltip";

const auth = new HelperClass();

const decodeEmail = (encodedEmail) => {
  return Buffer.from(encodedEmail, "base64").toString("utf-8");
};

export default class Register extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      comp_name: "",
      title: "",
      email: "",
      password: "",
      showPassword: false,
      agent_address: "",
      agent_address2: "",
      agent_city: "",
      agent_state: "",
      agent_zip: "",
      agent_country: "",
      errorCountry: "",
      errorEmail: "",
      errorPass: "",
      errorcPass: "",
      errorName: "",
      errorAddress: "",
      errorCity: "",
      errorState: "",
      errorZip: "",
      errorMessage: "",
      errorCompName: "",
      errorTitle: "",
      disabled: false,
      successMessage: "",
      confirm_password: "",
      showConfirmPassword: false,
      errorConfirm: "",
      disableResendBtn: false,
      phone: "",
      errorPhone: "",
      fieldsHide: false,
      show_form: false,
      resellerData: [],
      tableLoader: true,
      imageLoader: true,
      res_accountno: "",
      res_title: "",
      super_agent_accountno: "",
      agent_title: "",
      action_type: "",
      pageError: "",
      res_url: "",
      url_type: "Simple",
      social_register: false,
      ssoSuccessMessage: "",
      useGooglePlaces: true,
      toggleButtonText: "Allow PO Box Address",
    };
    this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
    this.toggleConfirmPasswordVisibility =
      this.toggleConfirmPasswordVisibility.bind(this);
    this.site_key = process.env.REACT_APP_CAPTCHA_SITE_KEY;
    this.captcha_token = null;
    this.countryOptions = countryList()
      .getData()
      .filter(
        (country) => country.label !== "United States Minor Outlying Islands"
      );
    this.phoneInputRef = createRef();
  }

  async componentDidMount() {
    let reseller_url = this.props.match.params.reseller_url;
    let reseller_hash = this.props.match.params.reseller_hash;
    let action_type = "agent";
    if (this.props.match.params.action_type === "sub") {
      action_type = "sub";
    }
    console.log("this.props: ", this.props);
    console.log(
      "URL Details: ",
      reseller_url + " " + reseller_hash + " " + action_type
    );
    let urlElements = window.location.href.split("/");
    console.log("CUrrent URL: ", urlElements[0] + "//" + urlElements[2]);
    let host_url = urlElements[2];
    console.log("host_url: ", host_url);

    const getQueryParam = (param) => {
      let urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(param);
    };

    let email = getQueryParam("email");
    email = email ? decodeEmail(email) : "";

    console.log("Email:", email);

    this.setState({ email: email || "" });
    /*this.setState({ tableLoader: false });*/
    if (reseller_hash !== "") {
      //Errors
      //404 and no_reseller
      //404 and no_agent
      try {
        axios
          .post(
            process.env.REACT_APP_API_URL + "agents/check_reseller",
            JSON.stringify({
              reseller_url: reseller_url,
              reseller_hash: reseller_hash,
              action_type: action_type,
              token: process.env.REACT_APP_API_TOKEN,
              api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
              host_url: host_url,
            }),
            axiosConfig
          )
          .then((response) => {
            console.log("response.data : ", response.data);
            if (
              response.data.status === 404 ||
              response.data.status === 422 ||
              response.data.errors !== ""
            ) {
              this.setState({
                errorMessage: "There is some error.",
                successMessage: "",
                show_form: false,
                pageError: "",
              });
            }

            if (
              response.data.status === 404 &&
              response.data.message === "no_reseller"
            ) {
              this.setState({
                errorMessage: "",
                pageError:
                  "Reseller does not exists. Please contact support or try again with correct URL.",
                successMessage: "",
                show_form: false,
              });
            } else if (
              response.data.status === 404 &&
              response.data.message === "no_agent"
            ) {
              this.setState({
                pageError:
                  "Agent does not exists. Please contact support or try again with correct sub agent registration URL.",
                errorMessage: "",
                successMessage: "",
                show_form: false,
              });
            } else if (response.data.status === 403) {
              this.setState({
                errorMessage: "There is some error.",
                successMessage: "",
                show_form: false,
                pageError: "",
              });
            } else if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              let session_userName = localStorage.getItem("userName");
              let session_userEmail = localStorage.getItem("userEmail");
              let session_userSub = localStorage.getItem("userSub");
              let session_authProvider = localStorage.getItem("authProvider");
              const isSocialRegister =
                session_userName !== null &&
                session_userName !== "" &&
                session_userEmail !== null &&
                session_userEmail !== "" &&
                session_userSub !== null &&
                session_userSub !== "" &&
                session_authProvider !== null &&
                session_authProvider !== "";
              if (isSocialRegister) {
                const random_password = this.generateStrongPassword(12);
                this.setState({
                  name: session_userName,
                  email: session_userEmail,
                  social_register: true,
                  password: random_password,
                  confirm_password: random_password,
                });
              }
              Cookies.set(
                "residentifier__",
                CryptoJS.AES.encrypt(
                  reseller_url,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "restkn__",
                CryptoJS.AES.encrypt(
                  reseller_hash,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "resacct__",
                CryptoJS.AES.encrypt(
                  response.data.res_accountno,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "resregtype__",
                CryptoJS.AES.encrypt(
                  response.data.action_type,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "restitle__",
                CryptoJS.AES.encrypt(
                  response.data.reseller_title,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );

              if (action_type === "sub") {
                Cookies.set(
                  "agttitle__",
                  CryptoJS.AES.encrypt(
                    response.data.agent_title,
                    process.env.REACT_APP_SIGNATURE
                  ).toString()
                );
                Cookies.set(
                  "agtacctno__",
                  CryptoJS.AES.encrypt(
                    response.data.agent_accountno,
                    process.env.REACT_APP_SIGNATURE
                  ).toString()
                );
              }

              Cookies.set(
                "reslogo__",
                CryptoJS.AES.encrypt(
                  response.data.reseller_logo,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );

              Cookies.set(
                "ressig__",
                CryptoJS.AES.encrypt(
                  response.data.reseller_signature,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );

              Cookies.set(
                "urltype__",
                CryptoJS.AES.encrypt(
                  response.data.url_type,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );

              this.setState({
                errorMessage: "",
                res_accountno: response.data.res_accountno,
                res_title: response.data.reseller_title,
                resellerData: response.data,
                tableLoader: false,
                imageLoader: false,
                show_form: true,
                agent_title: response.data.agent_title,
                super_agent_accountno: response.data.agent_accountno,
                action_type: action_type,
                res_url: reseller_url,
                pageError: "",
              });
            }
          });
      } catch (error) {
        this.setState({
          errorMessage: "There is some error.",
          successMessage: "",
          show_form: false,
          pageError: "",
        });
        console.log("catch : ", error);
      }
    } else {
      this.setState({
        errorMessage: "",
        successMessage: "",
        show_form: false,
        pageError:
          "Please enter the valid URL or contact admin for more details",
      });
    }
  }

  handleToggle = () => {
    this.setState((prevState) => ({
      useGooglePlaces: !prevState.useGooglePlaces,
      toggleButtonText:
        prevState.toggleButtonText === "Allow PO Box Address"
          ? "Allow Simple Address"
          : "Allow PO Box Address",
    }));
  };

  togglePasswordVisibility() {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  }

  toggleConfirmPasswordVisibility() {
    this.setState((prevState) => ({
      showConfirmPassword: !prevState.showConfirmPassword,
    }));
  }

  handleChange = (event) => {
    const { target } = event;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    if (name === "name") {
      value = value.replace(/[^a-zA-Z\s'.,-]/g, "");
    } else if (name === "comp_name") {
      value = value.replace(/[^a-zA-Z0-9\s\-_.,&()]/g, "");
    } else if (name === "title") {
      value = value.replace(/[^a-zA-Z0-9\s\-_,&().]/g, "");
    }
    this.setState({
      [name]: value,
    });
  };

  phoneUtil = PhoneNumberUtil.getInstance();

  isPhoneValid = (phone) => {
    try {
      return this.phoneUtil.isValidNumber(
        this.phoneUtil.parseAndKeepRawInput(phone)
      );
    } catch (error) {
      return false;
    }
  };

  handleFocus = () => {
    // Move cursor to the end of the input value
    const input = this.phoneInputRef.current;
    if (input) {
      const value = input.value;
      input.setSelectionRange(value.length, value.length);
    }
  };

  handleCaptchaChange = (value) => {
    this.captcha_token = value;
  };

  fieldValidation = () => {
    let {
      name,
      email,
      password,
      confirm_password,
      phone,
      comp_name,
      title,
      agent_address,
      agent_address2,
      agent_city,
      agent_state,
      agent_zip,
      agent_country,
    } = this.state;
    console.log(
      "agent_address: " +
        this.state.agent_address +
        " " +
        agent_city +
        " " +
        agent_state +
        " " +
        agent_zip
    );
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
    if (comp_name === "") {
      this.setState({
        errorCompName: "error",
        errorMessage: "Company Name is required.",
        errorcPass: "",
        errorName: "",
      });
      return false;
    } else if (name === "") {
      this.setState({
        errorCompName: "",
        errorName: "error",
        errorMessage: "Full Name is required.",
        errorcPass: "",
      });
      return false;
    } /*else if (title === "") {
      this.setState({
        errorCompName: "",
        errorName: "",
        errorTitle: "error",
        errorMessage: "Title is required.",
        errorcPass: "",
      });
      return false;
    }*/ else if (email === "") {
      this.setState({
        errorEmail: "error",
        errorName: "",
        errorTitle: "",
        errorMessage: "Email is required.",
        errorcPass: "",
        errorCompName: "",
      });
      return false;
    } else if (phone === "") {
      this.setState({
        errorPhone: "error",
        errorEmail: "",
        errorMessage: "Phone number is required.",
        errorTitle: "",
        errorcPass: "",
        errorName: "",
        errorCompName: "",
      });
      return false;
    } else if (!this.isPhoneValid(phone)) {
      this.setState({
        errorPhone: "error",
        errorEmail: "",
        errorMessage: "Invalid phone number.",
        errorTitle: "",
        errorcPass: "",
        errorName: "",
        errorCompName: "",
      });
      return false;
    } else if (ValidateEmail(email) === false) {
      this.setState({
        errorEmail: "error",
        errorName: "",
        errorMessage: "Invalid email format.",
        errorcPass: "",
        errorCompName: "",
      });
      return false;
    } else if (agent_address === "") {
      this.setState({
        errorAddress: "error",
        errorPhone: "",
        errorEmail: "",
        errorMessage: "Address is required.",
        errorTitle: "",
        errorcPass: "",
        errorName: "",
        errorCompName: "",
      });
      return false;
    } else if (agent_city === "") {
      this.setState({
        errorCity: "error",
        errorPhone: "",
        errorEmail: "",
        errorMessage: "City is required.",
        errorTitle: "",
        errorcPass: "",
        errorName: "",
        errorCompName: "",
      });
      return false;
    } else if (agent_state === "") {
      this.setState({
        errorState: "error",
        errorPhone: "",
        errorEmail: "",
        errorCity: "",
        errorMessage: "State is required.",
        errorTitle: "",
        errorcPass: "",
        errorName: "",
        errorCompName: "",
      });
      return false;
    } else if (agent_zip === "") {
      this.setState({
        errorZip: "error",
        errorPhone: "",
        errorState: "",
        errorEmail: "",
        errorMessage: "Zip / postal code is required.",
        errorTitle: "",
        errorcPass: "",
        errorName: "",
        errorCompName: "",
      });
      return false;
    } else if (agent_country === "") {
      this.setState({
        errorCountry: "error",
        errorPhone: "",
        errorZip: "",
        errorEmail: "",
        errorMessage: "Country is required.",
        errorTitle: "",
        errorcPass: "",
        errorName: "",
        errorAddress: "",
        errorCompName: "",
      });
      return false;
    } else if (password === "") {
      this.setState({
        errorPass: "error",
        errorEmail: "",
        errorcPass: "",
        errorPhone: "",
        errorZip: "",
        errorCountry: "",
        errorMessage: "Password is required.",
        errorName: "",
        errorAddress: "",
        errorCompName: "",
      });
      return false;
    } else if (password.length < 8) {
      this.setState({
        errorPass: "error",
        errorEmail: "",
        errorcPass: "",
        errorPhone: "",
        errorZip: "",
        errorCountry: "",
        errorMessage: "Use 8 characters or more for your password",
        errorName: "",
        errorAddress: "",
        errorCompName: "",
      });
      return false;
    } else if (password !== "" && !passwordRegex.test(password)) {
      this.setState({
        errorPass: "error",
        errorEmail: "",
        errorcPass: "",
        errorPhone: "",
        errorZip: "",
        errorCountry: "",
        errorMessage: "Password must be at least 8 characters, with uppercase, lowercase, number & special character.",
        errorName: "",
        errorAddress: "",
        errorCompName: "",
      });
      return false;
    } else if (confirm_password === "") {
      this.setState({
        errorPass: "",
        errorcPass: "error",
        errorEmail: "",
        errorPhone: "",
        errorZip: "",
        errorCountry: "",
        errorMessage: "Confirm password is required.",
        errorName: "",
        errorAddress: "",
        errorCompName: "",
      });
      return false;
    } else if (confirm_password.length < 8) {
      this.setState({
        errorPass: "",
        errorcPass: "error",
        errorEmail: "",
        errorZip: "",
        errorCountry: "",
        errorPhone: "",
        errorMessage: "Use 8 characters or more for your confirm password",
        errorName: "",
        errorCompName: "",
      });
      return false;
    } else if (password !== confirm_password) {
      this.setState({
        errorPass: "",
        errorcPass: "error",
        errorEmail: "",
        errorZip: "",
        errorCountry: "",
        errorPhone: "",
        errorMessage: "Password and confirm password does not match.",
        errorName: "",
        errorCompName: "",
      });
      return false;
    } else if (!this.state.social_register) {
      if (this.captcha_token === "" || this.captcha_token === null) {
        this.setState({
          errorMessage: "Invalid captcha.",
          errorName: "",
        });
        return false;
      }
    } else {
      this.setState({
        errorcPass: "",
        errorPass: "",
        errorEmail: "",
        errorMessage: "",
        errorConfirm: "",
        errorPhone: "",
        errorAddress: "",
        errorCity: "",
        errorCountry: "",
        errorState: "",
        errorZip: "",
        disabled: true,
      });
    }
    return true;
  };

  formSubmit = (e) => {
    e.preventDefault();
    let { oauth_identifier, authProvider } = "";
    oauth_identifier = localStorage.getItem("userSub");
    authProvider = localStorage.getItem("authProvider");
    window.scrollTo({ top: 0, behavior: "smooth" });
    let {
      name,
      email,
      password,
      confirm_password,
      phone,
      comp_name,
      title,
      agent_address,
      agent_address2,
      agent_city,
      agent_state,
      agent_zip,
      agent_country,
    } = this.state;
    if (this.fieldValidation() === true) {
      let agent_title = "";
      let super_agent_accountno = "";
      if (this.state.action_type === "sub") {
        agent_title = this.state.agent_title;
        super_agent_accountno = this.state.super_agent_accountno;
      }

      try {
        axios
          .post(
            process.env.REACT_APP_API_URL + "register",
            JSON.stringify({
              name: name,
              email: email,
              password: password,
              token: process.env.REACT_APP_API_TOKEN,
              api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
              phone: phone,
              comp_name: comp_name,
              title: title,
              res_accountno: this.state.res_accountno,
              agent_title: agent_title,
              super_agent_accountno: super_agent_accountno,
              action_type: this.state.action_type,
              agent_address: agent_address,
              agent_address2: agent_address2,
              agent_city: agent_city,
              agent_state: agent_state,
              agent_zip: agent_zip,
              agent_country: agent_country,
              oauth_identifier: oauth_identifier,
              authProvider: authProvider,
            }),
            axiosConfig
          )
          .then((response) => {
            console.log("response.data : ", response.data);
            if (
              response.data.status === 404 ||
              response.data.status === 422 ||
              response.data.errors !== ""
            ) {
              this.setState({
                errorMessage: "Invalid Email OR Password",
                disabled: false,
              });
            }
            if (
              response.data.status === 409 &&
              response.data.message === "Address validation failed"
            ) {
              const formattedErrorMessage = response.data.errors
                ? String(response.data.errors).replace(/,/g, "<br />")
                : "Address validation failed";
              this.setState({
                errorMessage: formattedErrorMessage,
                disabled: false,
              });
              setTimeout(() => {
                this.setState({
                  errorMessage: "",
                  errorAddress: "",
                  errorAddress2: "",
                  errorCity: "",
                  errorState: "",
                  errorZip: "",
                });
              }, 4000);
            }

            if (
              response.data.status === 200 &&
              response.data.message === "Account Already Exists"
            ) {
              this.setState({
                errorMessage: response.data.message,
                disabled: false,
              });
            } else if (
              response.data.status === 200 &&
              response.data.message === "Success"
            ) {
              $("#resendBtn").show();
              $("#signupBtn").hide();
              localStorage.removeItem("userName");
              localStorage.removeItem("userEmail");
              localStorage.removeItem("authProvider");
              localStorage.removeItem("userSub");
              this.setState({
                successMessage: `Email Sent to your account. To complete
                    verification process please go to inbox.`,
                errorMessage: "",
                fieldsHide: true,
              });
            } else if (
              response.data.status === 200 &&
              response.data.message === "Success_sso"
            ) {
              //$("#resendBtn").show();
              $("#signupBtn").hide();
              localStorage.removeItem("userName");
              localStorage.removeItem("userEmail");
              localStorage.removeItem("authProvider");
              localStorage.removeItem("userSub");
              setTimeout(() => {
                window.location.href = response.data.redirect_url;
              }, 1000);
            }
          });
      } catch (error) {
        console.log("catch : ", error);
      }
    }
  };

  resendEmail = async () => {
    let { email } = this.state;

    if (email === "") {
      this.setState({
        errorEmail: "error",
        errorName: "",
        errorMessage: "Email is required.",
      });
      return false;
    } else if (ValidateEmail(email) === false) {
      this.setState({
        errorEmail: "error",
        errorName: "",
        errorMessage: "Invalid email format.",
      });
      return false;
    } else {
      this.setState({
        errorEmail: "",
        errorMessage: "",
        disableResendBtn: true,
      });

      let resendRes = await resendCodeFun(email);
      console.log("resendRes: ", resendRes.data);
      if (resendRes.data.status === 404 || resendRes.data.errors !== "") {
        this.setState({
          errorMessage: resendRes.data.errors,
          disableResendBtn: false,
          successMessage: "",
        });
      } else if (
        resendRes.data.status === 200 &&
        resendRes.data.message === "Success" &&
        resendRes.data.errors === ""
      ) {
        this.setState({
          successMessage: `Email Sent.Please check inbox/spam for account activation.`,
          errorMessage: "",
          disableResendBtn: false,
        });
      } else {
        window.location.replace("/error");
      }
    }
  };

  handleChangeCountry = (event) => {
    console.log("event.label: ", event.label);
    const country = event.label;
    this.setState({
      agent_country: country,
    });
  };

  handleGoogleSuccess = (credentialResponse) => {
    if (
      process.env.REACT_APP_GOOGLE_OAUTH_CLIENTID ===
      credentialResponse.clientId
    ) {
      this.authRegister(credentialResponse.credential);
    }
  };

  authRegister = (authToken) => {
    let reseller_hash = this.props.match.params.reseller_hash;
    let reseller_url = this.props.match.params.reseller_url;
    this.setState({
      ssoSuccessMessage:
        "Just a few more steps to get you all set up! Please complete these additional details to finish your registration.",
      tableLoader: true,
    });
    let urlElements = window.location.href.split("/");
    console.log("CUrrent URL: ", urlElements[0] + "//" + urlElements[2]);
    let host_url = urlElements[2];
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/check_reseller_oauth_login",
          JSON.stringify({
            reseller_url: reseller_url,
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            host_url: host_url,
            authToken: authToken,
          }),
          axiosConfig
        )
        .then((response) => {
          if (
            response.data.status === 200 &&
            response.data.message === "success" &&
            response.data.requestType === "user_register"
          ) {
            const userEmail = response.data.userEmail;
            const userName = response.data.userName;
            const userSub = response.data.userSub;
            const requestType = response.data.requestType;
            localStorage.setItem("userEmail", userEmail);
            localStorage.setItem("userName", userName);
            localStorage.setItem("userSub", userSub);
            localStorage.setItem("authProvider", "google");
            this.setState({
              successMessage:
                "Please wait, while we set up your account. You will shortly be redirected to complete the setup process.",
              errorMessage: "",
            });
            setTimeout(() => {
              const redirectUrl =
                auth.getUrlType() === "Simple"
                  ? `${reseller_url}/${reseller_hash}/register`
                  : `${reseller_hash}/register`;
              window.location.replace(redirectUrl);
            }, 2000);
          } else if (
            response.data.status === 200 &&
            response.data.message === "success" &&
            response.data.requestType === "user_login"
          ) {
            this.setState({
              successMessage: "Please wait, redirecting you to the dashboard.",
              errorMessage: "",
            });
            Cookies.set(
              "tkn__",
              CryptoJS.AES.encrypt(
                response.data.token,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "acn__",
              CryptoJS.AES.encrypt(
                response.data.accountno,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "eml__",
              CryptoJS.AES.encrypt(
                response.data.email,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "comp_nme__",
              CryptoJS.AES.encrypt(
                response.data.comp_name,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "name__",
              CryptoJS.AES.encrypt(
                response.data.name,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "phone__",
              CryptoJS.AES.encrypt(
                response.data.phone,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "adress__",
              CryptoJS.AES.encrypt(
                response.data.address,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "city__",
              CryptoJS.AES.encrypt(
                response.data.city,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "state__",
              CryptoJS.AES.encrypt(
                response.data.state,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "zip__",
              CryptoJS.AES.encrypt(
                response.data.zip,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "country__",
              CryptoJS.AES.encrypt(
                response.data.country,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            Cookies.set(
              "title__",
              CryptoJS.AES.encrypt(
                response.data.title,
                process.env.REACT_APP_SIGNATURE
              ).toString()
            );
            setTimeout(function () {
              window.location.replace("/dashboard");
            }, 1000);
          } else {
            this.setState({
              successMessage: "",
              errorMessage:
                "There was some error while processing your request. Please try again!",
            });
          }
        });
    } catch (error) {
      this.setState({
        errorMessage: "",
        successMessage: "",
        show_form: false,
        tableLoader: false,
        pageError:
          "Please enter the valid URL or contact admin for more details",
      });
      console.log("catch : ", error);
    }
  };

  handleAddressSelect = async (place) => {
    const { description } = place.value;
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          description
        )}&key=${process.env.REACT_APP_ADDRESS_AUTOCOMPLETE_API_KEY}`
      );
      const data = await response.json();

      if (data.status === "OK") {
        const addressComponents = data.results[0].address_components;
        const formattedAddress = data.results[0].formatted_address;
        let city = null;
        let state = null;
        let country = null;
        let zipCode = null;
        let streetAddress = "";
        let neighborhood = null;
        let locality = null;
        let address2 = "";
        addressComponents.forEach((component) => {
          if (component.types.includes("neighborhood")) {
            neighborhood = component.long_name;
          } else if (component.types.includes("locality")) {
            locality = component.long_name;
          } else if (component.types.includes("administrative_area_level_1")) {
            state = component.short_name;
          } else if (component.types.includes("country")) {
            country = component.long_name;
          } else if (component.types.includes("postal_code")) {
            zipCode = component.long_name;
          } else if (component.types.includes("subpremise")) {
            address2 = component.long_name;
          } else if (
            ![
              "neighborhood",
              "sublocality",
              "sublocality_level_1",
              "sublocality_level_2",
              "administrative_area_level_2",
              "administrative_area_level_3",
              "postal_code_suffix",
            ].includes(component.types[0])
          ) {
            if (streetAddress !== "") {
              streetAddress += " ";
            }
            streetAddress += component.long_name;
          }
        });
        // Check if neighborhood is part of the formatted address
        if (neighborhood && formattedAddress.includes(neighborhood)) {
          city = neighborhood;
        } else {
          city = locality;
        }
        this.setState({
          agent_city: city && city !== "" ? city : "",
          agent_state: state && state !== "" ? state : "",
          agent_zip: zipCode && zipCode !== "" ? zipCode : "",
          agent_country: country && country !== "" ? country : "",
          agent_address:
            streetAddress && streetAddress !== "" ? streetAddress : "",
          agent_address2: address2 && address2 !== "" ? address2 : "",
        });
      } else {
        console.error("Reverse geocoding request failed:", data.status);
      }
    } catch (error) {
      console.error("Error fetching reverse geocoding data:", error);
    }
  };

  logoutGoogle = () => {
    localStorage.removeItem("userName");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("authProvider");
    localStorage.removeItem("userSub");
    window.location.reload();
  };

  generateStrongPassword = (length) => {
    const lowercaseCharset = "abcdefghijklmnopqrstuvwxyz";
    const uppercaseCharset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const numberCharset = "0123456789";
    const specialCharset = "!@#$%^&*()-_=+";

    let password = "";

    password +=
      lowercaseCharset[Math.floor(Math.random() * lowercaseCharset.length)];
    password +=
      uppercaseCharset[Math.floor(Math.random() * uppercaseCharset.length)];
    password += numberCharset[Math.floor(Math.random() * numberCharset.length)];
    password +=
      specialCharset[Math.floor(Math.random() * specialCharset.length)];

    const remainingLength = length - 4;

    const combinedCharset =
      lowercaseCharset + uppercaseCharset + numberCharset + specialCharset;
    for (let i = 0; i < remainingLength; i++) {
      const randomIndex = Math.floor(Math.random() * combinedCharset.length);
      password += combinedCharset[randomIndex];
    }

    password = this.shuffleString(password);

    return password;
  };

  shuffleString = (str) => {
    const array = str.split("");
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array.join("");
  };

  render() {
    console.log("fieldsHide: ", this.state.fieldsHide);
    const { useGooglePlaces, toggleButtonText } = this.state;
    const errorStyle = this.state.errorAddress
      ? {
          input: (provided) => ({
            ...provided,
            borderColor: "red", // Change border color to red on error
          }),
          control: (provided) => ({
            ...provided,
            borderColor: "red", // Optionally, change control border color too
          }),
        }
      : {};
    return (
      <div className="nk-body npc-crypto ui-clean pg-auth">
        <div className="nk-app-root">
          <div className="nk-split nk-split-page nk-split-md registrations">
            <div className="nk-split-content nk-block-area nk-block-area-column nk-auth-container w-lg-45">
              <div className="absolute-top-right d-lg-none p-3 p-sm-5">
                <a
                  href="#"
                  className="toggle btn btn-white btn-icon btn-light"
                  data-target="athPromo"
                >
                  <em className="icon ni ni-info"></em>
                </a>
              </div>
              <div className="nk-block nk-block-middle nk-auth-body">
                <div className="brand-logo pb-5 d-flex justify-content-center">
                  {this.state.imageLoader === true ? (
                    ThreeDots()
                  ) : (
                    <>
                      {auth.getResellerLogo() !== "" ? (
                        <a href="/" className="logo-link">
                          <img
                            className="logo-light logo-img logo-img-lg"
                            src={auth.getResellerLogo()}
                            alt="logo"
                          />
                          <img
                            className="logo-dark logo-img logo-img-lg"
                            src={auth.getResellerLogo()}
                            alt="logo-dark"
                          />
                        </a>
                      ) : (
                        <a href="/" className="logo-link">
                          <img
                            className="logo-light logo-img logo-img-lg"
                            src={LOGO}
                            alt="logo"
                          />
                          <img
                            className="logo-dark logo-img logo-img-lg"
                            src={LOGO}
                            alt="logo-dark"
                          />
                        </a>
                      )}
                    </>
                  )}
                </div>
                {this.state.ssoSuccessMessage !== "" ? (
                  <div className="example-alert">
                    <div className="alert alert-pro alert-success">
                      <div className="alert-text">
                        <h4>Agent Sign Up</h4>
                        <p>{this.state.ssoSuccessMessage}</p>
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.state.pageError !== "" ? (
                  <div className="example-alert">
                    <div className="alert alert-pro alert-danger">
                      <div className="alert-text">
                        <h4>Error</h4>
                        <p>{this.state.pageError}</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {this.state.tableLoader === true ? (
                      FormLoader()
                    ) : (
                      <>
                        <div className="nk-block-head">
                          <div className="nk-block-head-content">
                            {this.state.action_type === "sub" ? (
                              <h5 className="nk-block-title">
                                Register Sub Agent of {this.state.agent_title}{" "}
                              </h5>
                            ) : null}
                            {this.state.social_register ? (
                              <>
                                {this.state.action_type !== "sub" ? (
                                  <h5 className="nk-block-title">
                                    Agent Sign Up with Google
                                  </h5>
                                ) : null}
                              </>
                            ) : (
                              <>
                                {this.state.action_type !== "sub" ? (
                                  <h5 className="nk-block-title">
                                    Agent Sign Up
                                  </h5>
                                ) : null}
                              </>
                            )}
                            {this.state.social_register ? (
                              <p className="">
                                Just a few more steps to get you all set up!
                                Please complete these additional details to
                                finish your registration.
                              </p>
                            ) : (
                              <>
                                {!this.state.fieldsHide ? (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        margin: "1.7rem",
                                      }}
                                    >
                                      <div class="googleLoginBtn-large">
                                        <GoogleLogin
                                          onSuccess={this.handleGoogleSuccess}
                                          onError={() => {
                                            console.log("Registeration Failed");
                                          }}
                                          size="large"
                                          theme="outline"
                                          text="signup_with"
                                          width="400px"
                                          logo_alignment="center"
                                        />
                                      </div>
                                      <div class="googleLoginBtn-small">
                                        <GoogleLogin
                                          onSuccess={this.handleGoogleSuccess}
                                          onError={() => {
                                            console.log("Login Failed");
                                          }}
                                          size="large"
                                          theme="outline"
                                          text="signup_with"
                                          width="315px"
                                          logo_alignment="center"
                                        />
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <hr
                                        style={{
                                          flex: 1,
                                          border: "none",
                                          borderBottom: "1px solid #ccc",
                                        }}
                                      />
                                      <span style={{ padding: "0 10px" }}>
                                        OR
                                      </span>
                                      <hr
                                        style={{
                                          flex: 1,
                                          border: "none",
                                          borderBottom: "1px solid #ccc",
                                        }}
                                      />
                                    </div>
                                  </>
                                ) : null}
                              </>
                            )}
                            <div className="nk-block-des">
                              {/* Error Start */}
                              {this.state.errorMessage !== "" ? (
                                <div className="example-alert">
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: this.state.errorMessage,
                                        }}
                                      ></p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              {/* Error End */}

                              {/* Resend Email Button Start */}
                              {this.state.successMessage !== "" ? (
                                <div className="example-alert">
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>{this.state.successMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {/* Resend Email Button End */}
                            </div>
                          </div>
                        </div>
                        <form
                          method="post"
                          className="registerForm"
                          onSubmit={this.formSubmit}
                        >
                          {this.state.fieldsHide === false ? (
                            <div className="row g-gs">
                              <div className="col-md-6">
                                <TextField
                                  label="Company Name *"
                                  error={this.state.errorCompName}
                                  id="comp_name"
                                  name="comp_name"
                                  value={this.state.comp_name}
                                  placeholder="Company name"
                                  change={this.handleChange}
                                  maxLength="50"
                                />
                                <small
                                  className="form-text text-muted"
                                  style={{
                                    position: "absolute",
                                    top: "92px",
                                    color: "#555",
                                    fontSize: "0.75rem",
                                    fontWeight: "400",
                                    fontFamily:
                                      '"Roboto", "Helvetica", "Arial", sans-serif',
                                  }}
                                >
                                  Allowed characters: (&nbsp; _ &nbsp; . &nbsp;
                                  - &nbsp; , &nbsp; &amp; &nbsp; ( ) )
                                </small>
                              </div>
                              <div className="col-md-6">
                                <TextField
                                  label="Full Name *"
                                  error={this.state.errorName}
                                  id="name"
                                  name="name"
                                  value={this.state.name}
                                  placeholder="Full Name"
                                  change={this.handleChange}
                                  maxLength="50"
                                />
                                <small
                                  className="form-text text-muted"
                                  style={{
                                    position: "absolute",
                                    top: "92px",
                                    color: "#555",
                                    fontSize: "0.75rem",
                                    fontWeight: "400",
                                    fontFamily:
                                      '"Roboto", "Helvetica", "Arial", sans-serif',
                                  }}
                                >
                                  Allowed characters: (&nbsp; ' &nbsp; - &nbsp;
                                  . &nbsp; , )
                                </small>
                              </div>
                              <div className="col-md-6">
                                <TextField
                                  label="Title"
                                  error={this.state.errorTitle}
                                  id="title"
                                  name="title"
                                  value={this.state.title}
                                  placeholder="Title"
                                  change={this.handleChange}
                                  maxLength="30"
                                />
                                <small
                                  className="form-text text-muted"
                                  style={{
                                    position: "absolute",
                                    top: "92px",
                                    color: "#555",
                                    fontSize: "0.75rem",
                                    fontWeight: "400",
                                    fontFamily:
                                      '"Roboto", "Helvetica", "Arial", sans-serif',
                                  }}
                                >
                                  Allowed characters: (&nbsp; _ &nbsp; - &nbsp;
                                  . &nbsp; &amp; &nbsp; , &nbsp; ( ) )
                                </small>
                              </div>
                              <div className="col-md-6">
                                <TextField
                                  label="Email *"
                                  type="text"
                                  error={this.state.errorEmail}
                                  id="email"
                                  name="email"
                                  value={this.state.email}
                                  placeholder="Enter your email address"
                                  change={this.handleChange}
                                  maxLength="100"
                                />
                              </div>
                              <div className="col-md-6">
                                <label className="form-label" htmlFor="name">
                                  Phone Number *
                                </label>
                                {/*<PhoneInput
                                  required
                                  defaultCountry={"us"}
                                  error={this.state.errorPhone}
                                  inputProps={{
                                    name: "phone",
                                    id: "phone",
                                    maxLength: 20,
                                  }}
                                  value={this.state.phone}
                                  onChange={(value) =>
                                    this.handleChange({
                                      target: { name: "phone", value },
                                    })
                                  }
                                  helperText="Enter the phone number."
                                  label="Phone Number"
                                  ref={this.phoneInputRef}
                                  onFocus={this.handleFocus}
                                />*/}
                                <PhoneInput
                                  required
                                  defaultCountry={"us"}
                                  error={this.state.errorPhone}
                                  inputProps={{
                                    name: "phone",
                                    id: "phone",
                                    maxLength: 20,
                                    className: this.state.errorPhone
                                      ? "error-border"
                                      : "default-border",
                                  }}
                                  dialCodePreviewStyleProps={{
                                    className: this.state.errorPhone
                                      ? "error-border"
                                      : "default-border",
                                  }}
                                  value={this.state.phone}
                                  onChange={(value) =>
                                    this.handleChange({
                                      target: { name: "phone", value },
                                    })
                                  }
                                  helperText="Enter the phone number."
                                  label="Agent Phone Number"
                                  ref={this.phoneInputRef}
                                  onFocus={this.handleFocus}
                                />
                              </div>
                              <div className="col-md-6 mt-3 mt-md-0 googleLocation-autocomplete-wrapper">
                                <div class="nk-block-between-md g-4">
                                  <div class="nk-block-head-content">
                                    <label
                                      htmlFor="googleLocation-autocomplete"
                                      style={{
                                        color: "black",
                                        fontWeight: "500",
                                        marginBottom: "8px",
                                      }}
                                    >
                                      Address *
                                    </label>
                                  </div>
                                  <div class="nk-block-head-content">
                                    <ul class="nk-block-tools gx-3">
                                      <li>
                                        <a
                                          onClick={this.handleToggle}
                                          class="text-primary fs-11"
                                          style={{
                                            cursor: "pointer",
                                            fontSize: "11px",
                                          }}
                                        >
                                          {toggleButtonText}
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div>
                                  {useGooglePlaces ? (
                                    <GooglePlacesAutocomplete
                                      apiKey={
                                        process.env
                                          .REACT_APP_ADDRESS_AUTOCOMPLETE_API_KEY
                                      }
                                      selectProps={{
                                        value: this.state.agent_address
                                          ? {
                                              label: this.state.agent_address,
                                              value: this.state.agent_address,
                                            }
                                          : null,
                                        onChange: this.handleAddressSelect,
                                        placeholder: this.state.agent_address
                                          ? null
                                          : "Enter your address..",

                                        styles: {
                                          ...errorStyle,
                                          input: (provided) => ({
                                            ...provided,
                                            color: "black",
                                          }),
                                          option: (provided) => ({
                                            ...provided,
                                            color: "black",
                                          }),
                                          singleValue: (provided) => ({
                                            ...provided,
                                            color: "black",
                                          }),
                                          menu: (provided) => ({
                                            ...provided,
                                            zIndex: 9,
                                          }),
                                        },
                                      }}
                                      id="googleLocation-autocomplete"
                                    />
                                  ) : (
                                    <>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="agent_address"
                                        name="agent_address"
                                        defaultValue={this.state.agent_address}
                                        onChange={this.handleChange}
                                        placeholder="PO Box Address"
                                        maxLength="50"
                                        fullWidth
                                      />
                                    </>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-4">
                                <TextField
                                  id="agent_address2"
                                  name="agent_address2"
                                  label="Apartment, Suite, Etc."
                                  value={this.state.agent_address2}
                                  change={this.handleChange}
                                  maxLength="64"
                                />
                              </div>

                              <div className="col-md-2">
                                <TextField
                                  id="agent_city"
                                  name="agent_city"
                                  label="City *"
                                  value={this.state.agent_city}
                                  maxLength="32"
                                  change={this.handleChange}
                                  error={this.state.errorCity}
                                />
                              </div>
                              <div className="col-md-2">
                                <TextField
                                  id="agent_state"
                                  name="agent_state"
                                  label="State *"
                                  value={this.state.agent_state}
                                  maxLength="32"
                                  change={this.handleChange}
                                  error={this.state.errorState}
                                />
                              </div>
                              <div className="col-md-2">
                                <TextField
                                  id="agent_zip"
                                  name="agent_zip"
                                  label="Zip / Postal Code *"
                                  value={this.state.agent_zip}
                                  maxLength="10"
                                  change={this.handleChange}
                                  error={this.state.errorZip}
                                />
                              </div>
                              <div className="col-sm-2">
                                <div className="form-group">
                                  <label
                                    className="form-label"
                                    htmlFor="agent_country"
                                  >
                                    Country <sup>*</sup>
                                  </label>
                                  <div className="form-control-wrap">
                                    <Select
                                      className="customStyle-country"
                                      options={this.countryOptions}
                                      value={{
                                        label: this.state.agent_country,
                                        value: this.state.agent_country,
                                      }}
                                      onChange={this.handleChangeCountry}
                                    />
                                  </div>
                                </div>
                              </div>
                              {/* <div className="col-md-2">
                                <TextField
                                  id="agent_country"
                                  name="agent_country"
                                  label="Zip / Postal Code *"
                                  value={this.state.agent_country}
                                  change={this.handleChange}
                                />
                              </div> */}
                              {this.state.social_register ? null : (
                                <>
                                  <div className="form-group col-md-6">
                                  <label
                                      className="form-label"
                                      htmlFor="password"
                                    >
                                      <Tooltip
                                        title="Password must be at least 8 characters, with uppercase, lowercase, number & special character."
                                        placement="top"
                                      >
                                        <a
                                          className="btn btn-icon btn-sm"
                                          style={{
                                            fontSize: "0.875rem"
                                          }}
                                        >
                                          <em
                                            class="icon ni ni-info"
                                          ></em>
                                          Password *
                                        </a>
                                      </Tooltip>
                                    </label>
                                    <div className="form-control-wrap">
                                      <a
                                        tabIndex="-1"
                                        className="form-icon form-icon-right passcode-switch"
                                        onClick={this.togglePasswordVisibility}
                                        style={{
                                          left: "auto",
                                          right: "2px",
                                          top: "24px",
                                          position: "absolute",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {this.state.showPassword ? (
                                          <em className="passcode-icon icon-show icon ni ni-eye-off"></em>
                                        ) : (
                                          <em className="passcode-icon icon-show icon ni ni-eye"></em>
                                        )}
                                      </a>
                                      <input
                                        type={
                                          this.state.showPassword
                                            ? "text"
                                            : "password"
                                        }
                                        className={
                                          "form-control form-control-lg " +
                                          this.state.errorPass
                                        }
                                        id="password"
                                        name="password"
                                        value={this.state.password}
                                        onChange={this.handleChange}
                                        placeholder="Enter your password"
                                        maxLength="50"
                                      />
                                      <div style={{ marginTop: "15px" }}>
                                        <PasswordStrengthBar password={this.state.password} />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="form-group col-md-6">
                                  <label
                                      className="form-label"
                                      htmlFor="confirm_password"
                                    >
                                      <Tooltip
                                        title="Confirm Password must be at least 8 characters, with uppercase, lowercase, number & special character. Confirm Password must match the Password."
                                        placement="top"
                                      >
                                        <a
                                          className="btn btn-icon btn-sm"
                                          style={{
                                            fontSize: "0.875rem"
                                          }}
                                        >
                                          <em
                                            class="icon ni ni-info"
                                          ></em>
                                          Confirm Password *
                                        </a>
                                      </Tooltip>
                                    </label>
                                    <div className="form-control-wrap">
                                      <a
                                        tabIndex="-1"
                                        className="form-icon form-icon-right passcode-switch"
                                        onClick={
                                          this.toggleConfirmPasswordVisibility
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        {this.state.showConfirmPassword ? (
                                          <em className="passcode-icon icon-show icon ni ni-eye-off"></em>
                                        ) : (
                                          <em className="passcode-icon icon-show icon ni ni-eye"></em>
                                        )}
                                      </a>
                                      <input
                                        type={
                                          this.state.showConfirmPassword
                                            ? "text"
                                            : "password"
                                        }
                                        className={
                                          "form-control form-control-lg " +
                                          this.state.errorcPass
                                        }
                                        id="confirm_password"
                                        name="confirm_password"
                                        value={this.state.confirm_password}
                                        onChange={this.handleChange}
                                        placeholder="Confirm your password"
                                        maxLength="50"
                                      />
                                    </div>
                                  </div>

                                  <div className="form-group col-md-12">
                                    <ReCAPTCHA
                                      sitekey={this.site_key}
                                      onChange={this.handleCaptchaChange}
                                      style={{ padding: "0px 0px 15px" }}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          ) : null}
                          <br />

                          <div className="form-group">
                            <button
                              className="btn btn btn-dim btn-primary"
                              onClick={() => this.resendEmail()}
                              disabled={this.state.disableResendBtn}
                              id="resendBtn"
                              style={{ display: "none" }}
                            >
                              Resend Email
                            </button>{" "}
                            <button
                              id="signupBtn"
                              className="btn btn-lg btn-block"
                              type="submit"
                              disabled={this.state.disabled}
                              style={{
                                backgroundColor: THEME_COLOR,
                                color: "white",
                              }}
                            >
                              {this.state.social_register
                                ? "Submit"
                                : "Register"}
                            </button>
                          </div>
                        </form>
                        {this.state.social_register &&
                        !this.state.fieldsHide ? (
                          <>
                            <button
                              id="logoutBtn"
                              className="btn btn-lg btn-block mt-3"
                              onClick={() => this.logoutGoogle()}
                              style={{
                                // backgroundColor: THEME_COLOR,
                                color: THEME_COLOR,
                                border: `1px solid ${THEME_COLOR}`,
                              }}
                            >
                              Log Out
                            </button>
                          </>
                        ) : null}
                        {!this.state.fieldsHide ? (
                          <div className="form-note-s2 pt-4">
                            {" "}
                            Already have an account ?{" "}
                            {auth.getUrlType() === "Simple" ? (
                              <a href={`/${this.state.res_url}`}>
                                <strong>Sign in instead</strong>
                              </a>
                            ) : (
                              <a href="/">
                                <strong>Sign in instead</strong>
                              </a>
                            )}
                          </div>
                        ) : null}
                      </>
                    )}
                  </>
                )}
              </div>
              <div className="nk-block nk-auth-footer d-flex justify-content-center">
                <div className="mt-3">
                  <p>
                  ERP/OBS License. All Rights Reserved {new Date().getFullYear()}
                  </p>
                </div>
              </div>
            </div>
            {/*<div
              className="nk-split-content nk-split-stretch bg-lighter d-flex toggle-break-lg toggle-slide toggle-slide-right"
              data-content="athPromo"
              data-toggle-screen="lg"
              data-toggle-overlay="true"
            >
              <div className="slider-wrap w-100 w-max-550px p-3 p-sm-5 m-auto">
                <div
                  className="slider-init"
                  data-slick='{"dots":true, "arrows":false}'
                >
                  <div className="slider-item">
                    <div className="nk-feature nk-feature-center">
                      <div className="nk-feature-img">
                        <img className="round" src={PROMO_A} alt="" />
                      </div>
                      <div className="nk-feature-content py-4 p-sm-5">
                        <h4>
                          {this.state.res_title !== "" ? (
                            <>
                              {this.state.res_title} Agents / Channel Partners
                            </>
                          ) : (
                            <>{COMPANY_NAME}</>
                          )}
                        </h4>
                        <p>
                          Enter details here to signup and start using our
                          exciting product.
                        </p>
                      </div>
                    </div>
                  </div>
                  
                </div>
                <div className="slider-dots"></div>
                <div className="slider-arrows"></div>
              </div>
            </div>*/}
          </div>
        </div>
      </div>
    );
  }
}
