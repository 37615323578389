import { React } from "react";
import { Component } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";
import { formatAsCurrency } from "../../../config/utility";
import { addSettings } from "../../../actions";
import {
  listClients,
  getQuotations,
  listSingleClient,
  getSingleQuotation,
  getAssignedQuotationSettings,
  createDuplicateQuotation,
  getSingleRfq,
} from "./../../../config/quotation_api_calls";

import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import DataTableExtended from "../../../components/Tables/DataTableExtended";
import { tableLoaderListing } from "../../../components/ContentLoaders/table_loader_listing";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import { Invoice } from "../../../components/ContentLoaders/newLoaders/Invoice";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  ADMIN_URL,
  APP_LIVE_URL
} from "./../../../config/config";
import MutextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { parsePhoneNumberFromString } from "libphonenumber-js";
const animatedComponents = makeAnimated();

const auth = new HelperClass();

class PartnerQuotations extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
      serviceData: [],
      tableDataBundle: [],
      completeData: [],
      defaultServices: [],
      errorMessage: "",
      serviceId: "",
      successMessage: "",
      waitMessage: "",
      tableLoader: true,
      tableLoaderListing: true,
      tableLoaderBundle: true,
      all_active_clients: [],
      client_accountno: "",
      selected_accountno: null,
      choose_accountno: "",
      choose_accountno_duplicate: "",
      quote_accountno: "",
      invoice_loader: true,
      rfq_requirements: "",
      show_quotation_email_block: false,
      quotation_email_button: false,
      quotationEmailError: "",
      quotationEmailErrorTitle: "",
      quotationEmailSuccess: "",
      quotation_email: "",
      quotation_email_content: "",
      formLoaderDuplicate: false,
      errorMessageDuplicate: "",
      successMessageDuplicate: "",
      duplicate_button: false,
      client_address: "",
      client_address2: "",
      client_city: "",
      client_state: "",
      client_zip: "",
      client_country: "",
      columnServices: [
        {
          name: "quote_accountno",
          label: "Quote #",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.create_link(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "rfq_accountno",
          label: "RFQ #",
        },
        {
          name: "quote_title",
          label: "Title",
        },
        {
          name: "client_title",
          label: "Client",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.defaultTitle(value, tableMeta)}</div>;
            },
          },
        },

        {
          name: "dateCreated",
          label: "Date Created",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },

        {
          name: "quote_status",
          label: "STATUS",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.defaultText(value, tableMeta)}</div>;
            },
          },
        },

        {
          name: "ACTIONS",
          options: {
            customBodyRender: (value, tableMeta) => {
              let getaccount = tableMeta.rowData[3].split("###");
              let edit_url =
                "/EditQuotations/" + getaccount[0] + "/" + tableMeta.rowData[0];
              if (tableMeta.rowData[1] && tableMeta.rowData[1] !== "") {
                edit_url =
                  "/EditQuotations/" +
                  getaccount[0] +
                  "/" +
                  tableMeta.rowData[0] +
                  "/" +
                  tableMeta.rowData[1];
              }
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            className="dropdown-toggle btn btn-icon btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.showQuotation(value, tableMeta)
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                  title="Show answer options"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-card-view"></em>
                                  <span>View</span>
                                </a>
                              </li>

                              <li>
                                <a
                                  onClick={() => this.addMenuHistory(edit_url)}
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                  title="Assigned Services"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-edit"></em>
                                  <span>Edit</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.open_duplicate(value, tableMeta)
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                  title="Show answer options"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-copy"></em>
                                  <span>Duplicate</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
  }

  async componentDidMount() {
    const servicesResponce = await getQuotations(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("getQuotations: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        tableData: [],
        tableLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      this.setState({
        tableData: [],
        tableLoader: false,
      });
      // window.location.replace("/error");
    }
    this.listAllActiveClients();
  }

  listAllActiveClients = async () => {
    const servicesResponce = await listClients(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("listAllActiveClients: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      //auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        errorMessage: "The question not found.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let all_clients = [];
      if (
        servicesResponce.data.clients &&
        servicesResponce.data.clients.length > 0
      ) {
        for (let i = 0; i < servicesResponce.data.clients.length; i++) {
          let newArr = {
            label:
              servicesResponce.data.clients[i]["name"] +
              " (" +
              servicesResponce.data.clients[i]["comp_name"] +
              ")",
            value: servicesResponce.data.clients[i]["accountno"],
          };
          all_clients.push(newArr);
        }
      }
      this.setState({
        all_active_clients: all_clients,
      });
    } else {
      this.setState({
        errorMessage: "There is some error while deleting the question.",
      });
    }
  };

  reloadClients = async () => {
    this.setState({
      tableLoader: true,
    });
    const servicesResponce = await getQuotations(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("getQuotations: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        tableData: [],
        tableLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      this.setState({
        tableData: [],
        tableLoader: false,
      });
      // window.location.replace("/error");
    }
  };

  downloadPdfDetails = async (d_f_name) => {
    //var url = d_f_name;
    this.setState({
      quotation_attachement_button: true,
      errorMessage: "",
    });
    if (d_f_name && d_f_name !== "") {
      let file_name = d_f_name;
      var url = APP_LIVE_URL + "files_data/rfq/" + d_f_name;
      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = file_name;
          a.click();
          window.URL.revokeObjectURL(url);
          this.setState({ quotation_attachement_button: false });
        })
        .catch((error) => {
          this.setState({
            errorMessage:
              "There is some error while downloading the attachment.",
          });
          setTimeout(() => {
            this.setState({
              errorMessage: "",
              quotation_attachement_button: false,
            });
          }, 4000);
          // Handle any errors that occurred during the fetch
          console.error("Fetch error:", error);
        });
    } else {
      this.setState({
        errorMessage: "Attachement not found.",
        quotation_attachement_button: false,
      });
      setTimeout(() => {
        this.setState({ errorMessage: "" });
      }, 4000);
    }
  };

  formatAsCurrency = (amount) => {
    //console.log("amount: ", typeof amount);
    let decimal_point = this.getBillingDecimalPoint();

    if (typeof amount === "string") {
      amount = parseFloat(amount);
    }
    return amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: decimal_point,
      maximumFractionDigits: decimal_point,
    });
  };

  formatAsCurrencySimple = (amount) => {
    //console.log("amount: ", typeof amount);
    let decimal_point = this.getDecimalPoint();

    if (typeof amount === "string") {
      amount = parseFloat(amount);
    }
    return amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: decimal_point,
      maximumFractionDigits: decimal_point,
    });
  };

  formatPhoneNumber = (phoneNumber) => {
    const phoneNumberObj = parsePhoneNumberFromString(phoneNumber);
    if (phoneNumberObj) {
      if (phoneNumberObj.country === "US") {
        const nationalNumber = phoneNumberObj
          .formatNational()
          .replace(/\D/g, ""); // e.g. "2123727200"
        const areaCode = nationalNumber.slice(0, 3);
        const firstPart = nationalNumber.slice(3, 6);
        const secondPart = nationalNumber.slice(6);
        return `(${areaCode}) ${firstPart}-${secondPart}`;
      }
      return phoneNumberObj.formatInternational();
    }
    return phoneNumber; // Return original number if parsing fails
  };

  showQuotation = async (value, tableMeta) => {
    let all_data = tableMeta.rowData[3].split("###");
    //console.log("showQuotation: ", all_data);
    let quote_accountno = tableMeta.rowData[0];
    let client_accountno = all_data[0];
    this.setState({
      quote_accountno: quote_accountno,
      invoice_loader: true,
      rfq_requirements: "",
      show_quotation_email_block: false,
      quotation_email_button: false,
      quotationEmailError: "",
      quotationEmailErrorTitle: "",
      quotationEmailSuccess: "",
      quotation_email: "",
      quotation_email_content: "",
    });
    window.$("#modalDefaultDetails").modal("show");
    console.log(
      "quote_accountno client_accountno: " +
        quote_accountno +
        " " +
        client_accountno
    );
    await this.getSingleQuotation(client_accountno, quote_accountno);
    await this.getQuotationSettings(quote_accountno);
    await this.getSingleClient(client_accountno);
  };

  getSingleClient = async (client_accountno) => {
    const servicesResponce = await listSingleClient(
      auth.getAccount(),
      auth.getToken(),
      client_accountno
    );
    //console.log("getSingleClient: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        client_details: servicesResponce.data.client_details,
        //quotation_notes: quotation_notes,
        //quote_notes: quotation_notes,
        client_name:
          servicesResponce.data.client_details[0]["name"] &&
          servicesResponce.data.client_details[0]["name"] !== ""
            ? servicesResponce.data.client_details[0]["name"]
            : "",
        client_company: servicesResponce.data.client_details[0]["comp_name"]
          ? servicesResponce.data.client_details[0]["comp_name"]
          : "",
        client_email: servicesResponce.data.client_details[0]["email"]
          ? servicesResponce.data.client_details[0]["email"]
          : "",
        client_phone: servicesResponce.data.client_details[0]["phone"]
          ? servicesResponce.data.client_details[0]["phone"]
          : "",
        client_address: servicesResponce.data.client_details[0]["address"]
          ? servicesResponce.data.client_details[0]["address"]
          : "",
        client_address2: servicesResponce.data.client_details[0]["address2"]
          ? servicesResponce.data.client_details[0]["address2"]
          : "",
        client_city: servicesResponce.data.client_details[0]["city"]
          ? servicesResponce.data.client_details[0]["city"]
          : "",
        client_state: servicesResponce.data.client_details[0]["state"]
          ? servicesResponce.data.client_details[0]["state"]
          : "",
        client_zip: servicesResponce.data.client_details[0]["zip"]
          ? servicesResponce.data.client_details[0]["zip"]
          : "",
        client_country: servicesResponce.data.client_details[0]["country"]
          ? servicesResponce.data.client_details[0]["country"]
          : "",
        reseller_details: servicesResponce.data.reseller_details,
        reseller_name: servicesResponce.data.reseller_details[0]["name"]
          ? servicesResponce.data.reseller_details[0]["name"]
          : "",
        reseller_company: servicesResponce.data.reseller_details[0]["comp_name"]
          ? servicesResponce.data.reseller_details[0]["comp_name"]
          : "",
        reseller_email: servicesResponce.data.reseller_details[0]["email"]
          ? servicesResponce.data.reseller_details[0]["email"]
          : "",
        reseller_phone: servicesResponce.data.reseller_details[0]["phone"]
          ? servicesResponce.data.reseller_details[0]["phone"]
          : "",
        reseller_address: servicesResponce.data.reseller_details[0]["address"]
          ? servicesResponce.data.reseller_details[0]["address"]
          : "",
        reseller_city: servicesResponce.data.reseller_details[0]["city"]
          ? servicesResponce.data.reseller_details[0]["city"]
          : "",
        reseller_state: servicesResponce.data.reseller_details[0]["state"]
          ? servicesResponce.data.reseller_details[0]["state"]
          : "",
        reseller_zip: servicesResponce.data.reseller_details[0]["zip"]
          ? servicesResponce.data.reseller_details[0]["zip"]
          : "",
        tableLoader: false,
      });
    } else {
      // window.location.replace("/error");
    }
  };

  getSingleQuotation = async (client_accountno, quote_accountno) => {
    const servicesResponce = await getSingleQuotation(
      auth.getAccount(),
      auth.getToken(),
      client_accountno,
      quote_accountno
    );
    //console.log("getSingleQuotation: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({ invoice_loader: false });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let discount_value_total = 0;
      let discount_type = "amount";
      let discount_value = 0;
      let discount_reason = "";
      if (
        servicesResponce.data.data[0]["discounts"] &&
        servicesResponce.data.data[0]["discounts"].length > 0
      ) {
        discount_value_total =
          servicesResponce.data.data[0]["discounts"][0]["discount_total"];
        discount_type =
          servicesResponce.data.data[0]["discounts"][0]["discount_type"];
        discount_value =
          servicesResponce.data.data[0]["discounts"][0]["discount_value"];
        discount_reason =
          servicesResponce.data.data[0]["discounts"][0]["discount_reason"];
      }
      let tax_value_total = 0;
      let tax_type = "amount";
      let tax_value = 0;
      let tax_reason = "";
      if (
        servicesResponce.data.data[0]["taxes"] &&
        servicesResponce.data.data[0]["taxes"].length > 0
      ) {
        tax_value_total =
          servicesResponce.data.data[0]["taxes"][0]["tax_total"];
        tax_type = servicesResponce.data.data[0]["taxes"][0]["tax_type"];
        tax_value = servicesResponce.data.data[0]["taxes"][0]["tax_value"];
        tax_reason = servicesResponce.data.data[0]["taxes"][0]["tax_reason"];
      }
      let shipping_value_total = 0;
      let shipping_title = "";
      let shipping_value = "";
      if (
        servicesResponce.data.data[0]["shipping"] &&
        servicesResponce.data.data[0]["shipping"].length > 0
      ) {
        shipping_value_total =
          servicesResponce.data.data[0]["shipping"][0]["shipping_value"];
        shipping_title =
          servicesResponce.data.data[0]["shipping"][0]["shipping_title"];
        shipping_value =
          servicesResponce.data.data[0]["shipping"][0]["shipping_value"];
      }
      //console.log("rfq_questions: "+servicesResponce.data.data);
      let servicesRows = [];
      let total = 0;
      if (
        servicesResponce.data.data[0]["quote_services"] &&
        servicesResponce.data.data[0]["quote_services"].length > 0
      ) {
        let rfq_services = servicesResponce.data.data[0]["quote_services"];
        for (let i = 0; i < rfq_services.length; i++) {
          let description = rfq_services[i]["description"];
          if (
            rfq_services[i]["description"] &&
            rfq_services[i]["description"] !== ""
          ) {
            description = rfq_services[i]["description"];
          } else {
            description = "";
          }
          let newArray = {
            id: `row-${i}`,
            data: {
              id: rfq_services[i]["service_id"],
              name: rfq_services[i]["service_name"],
              quantity: rfq_services[i]["quantity"],
              unit: rfq_services[i]["service_unit"],
              price: rfq_services[i]["service_price"],
              total: Number(
                Number(rfq_services[i]["quantity"]) *
                  rfq_services[i]["service_price"]
              ).toFixed(2),
              type: rfq_services[i]["item_type"],
              description: description,
            },
          };
          servicesRows.push(newArray);
          total =
            total +
            Number(rfq_services[i]["service_price"]) *
              Number(rfq_services[i]["quantity"]);
        }
      }
      //Minus Discount
      let with_discount = total - parseFloat(discount_value_total);
      //Calculate Tax
      with_discount = with_discount + parseFloat(tax_value_total);
      //Calculate Shipping
      with_discount = with_discount + parseFloat(shipping_value_total);

      //**** Get Optional Services ****/rows_optional
      let servicesOptionalRows = [];
      if (
        servicesResponce.data.data[0]["quote_optional_services"] &&
        servicesResponce.data.data[0]["quote_optional_services"].length > 0
      ) {
        let optional_services =
          servicesResponce.data.data[0]["quote_optional_services"];
        for (let i = 0; i < optional_services.length; i++) {
          let newArrays = {
            id: `row-${i}`,
            data: {
              id: optional_services[i]["service_id"],
              name: optional_services[i]["service_name"],
              quantity: optional_services[i]["quantity"],
              unit: optional_services[i]["service_unit"],
              price: optional_services[i]["service_price"],
              total: Number(
                Number(optional_services[i]["quantity"]) *
                  optional_services[i]["service_price"]
              ).toFixed(2),
              type: optional_services[i]["item_type"],
            },
          };
          servicesOptionalRows.push(newArrays);
        }
      }
      this.setState({
        rows: servicesRows,
        rows_optional: servicesOptionalRows,
        quote_title: servicesResponce.data.data[0]["quote_title"],
        quote_notes: servicesResponce.data.data[0]["quote_notes"],
        quote_attachement: servicesResponce.data.data[0]["quote_attachement"],
        service_grand_total:
          with_discount && with_discount > 0
            ? with_discount.toFixed(2)
            : "0.00",
        service_grand_sub_total: total && total > 0 ? total.toFixed(2) : "0.00",
        discount_value_total: discount_value_total,
        discount_type: discount_type,
        discount_value: discount_value,
        discount_reason: discount_reason,
        tax_value_total: tax_value_total,
        tax_type: tax_type,
        tax_value: tax_value,
        tax_reason: tax_reason,
        shipping_value_total: shipping_value_total,
        shipping_value: shipping_value,
        shipping_title: shipping_title,
        payment_status: servicesResponce.data.data[0]["payment_status"],
        quotation_attachement:
          servicesResponce.data.data[0]["quote_attachement"],
        tableLoader: false,
        invoice_loader: false,
      });
      if (
        servicesResponce.data.data[0]["rfq_accountno"] &&
        servicesResponce.data.data[0]["rfq_accountno"] !== ""
      ) {
        await this.getSingleRfq(
          client_accountno,
          servicesResponce.data.data[0]["rfq_accountno"]
        );
      }
    } else {
      // window.location.replace("/error");
      this.setState({ invoice_loader: false });
    }
  };

  getDecimalPoint = () => {
    if (
      this.props.quotationSettings[0]?.decimal_point &&
      this.props.quotationSettings[0]?.decimal_point !== ""
    ) {
      const decimalPoint = this.props.quotationSettings[0]?.decimal_point;
      return decimalPoint !== "" ? Number(decimalPoint) : 2;
    }
    return 2;
  };

  getBillingDecimalPoint = () => {
    if (
      this.props.quotationSettings[0]?.billing_decimal_point &&
      this.props.quotationSettings[0]?.billing_decimal_point !== ""
    ) {
      const decimalPoint = this.props.quotationSettings[0]?.billing_decimal_point;
      return decimalPoint !== "" ? Number(decimalPoint) : 2;
    }
    return 2;
  };

  getSingleRfq = async (client_accountno, rfq_accountno) => {
    const servicesResponce = await getSingleRfq(
      auth.getAccount(),
      auth.getToken(),
      client_accountno,
      rfq_accountno
    );
    //console.log("getSingleRfq: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      if (
        servicesResponce.data.data[0]["rfq_questions"] &&
        servicesResponce.data.data[0]["rfq_questions"].length > 0
      ) {
        let rfq_questions = servicesResponce.data.data[0]["rfq_questions"];
        for (let i = 0; i < rfq_questions.length; i++) {
          if (
            rfq_questions[i]["question_type"] === "radio" ||
            rfq_questions[i]["question_type"] === "dropdown"
          ) {
            let answer_id = this.doesAnswerIdExist(
              Number(rfq_questions[i]["answer_id"]),
              servicesResponce.data.all_answers
            );

            rfq_questions[i]["answer"] = answer_id;
          } else if (rfq_questions[i]["question_type"] === "checkbox") {
            let answer_id = this.doesAnswerArrayExist(
              JSON.parse(rfq_questions[i]["answer_id"]),
              servicesResponce.data.all_answers
            );

            rfq_questions[i]["answer"] = answer_id;
          } else {
            rfq_questions[i]["answer"] = rfq_questions[i]["answer_id"];
          }
        }
      }

      //console.log("rfq_questions: "+servicesResponce.data.data);
      let servicesRows = [];
      if (
        servicesResponce.data.data[0]["rfq_services"] &&
        servicesResponce.data.data[0]["rfq_services"].length > 0
      ) {
        let rfq_services = servicesResponce.data.data[0]["rfq_services"];
        for (let i = 0; i < rfq_services.length; i++) {
          let newArray = {
            id: `row-${i}`,
            data: {
              id: rfq_services[i]["service_id"],
              name: rfq_services[i]["service_name"],
              quantity: rfq_services[i]["quantity"],
              unit: rfq_services[i]["service_unit"],
              price: 0,
              total: 0,
              type: "service",
            },
          };
          servicesRows.push(newArray);
        }
      }
      //console.log("servicesRows: ", servicesRows);
      this.setState({
        rows_rfq_services: servicesRows,
        //tableData: servicesResponce.data.data,
        rows_rfq_questions: servicesResponce.data.data[0]["rfq_questions"],
        //quotation_services: servicesResponce.data.data[0]["rfq_services"],
        rfq_requirements: servicesResponce.data.data[0]["rfq_comments"],
        rfq_attachment: servicesResponce.data.data[0]["rfq_attachement"],
        all_answers: servicesResponce.data.all_answers,

        tableLoader: false,
      });
    } else {
      // window.location.replace("/error");
    }
  };

  doesAnswerIdExist = (answerIdToCheck, answerArray) => {
    //return answerArray.some((answer) => answer.answer_id === answerIdToCheck);
    const answer = answerArray.find((item) => item.id === answerIdToCheck);
    return answer ? answer.question_options : "";
  };

  doesAnswerArrayExist = (answerIdToCheck, answerArray) => {
    answerIdToCheck = JSON.stringify(answerIdToCheck);
    //return answerArray.some((answer) => answer.answer_id === answerIdToCheck);
    const objectValues = answerIdToCheck.slice(1, -1).split(",");
    var matchingItems = "";
    objectValues.map((setString, index) => {
      console.log("setString: ", setString);
      let answer = answerArray.find((item) => item.id === Number(setString));
      if (answer) {
        matchingItems +=
          "<p>" + (Number(index) + 1) + ": " + answer.question_options + "</p>";
      }
    });

    return (
      <div
        dangerouslySetInnerHTML={{
          __html: matchingItems,
        }}
      />
    );
  };

  getQuotationSettings = async (quote_accountno) => {
    const servicesResponce = await getAssignedQuotationSettings(
      auth.getAccount(),
      auth.getToken(),
      quote_accountno
    );
    //console.log("getQuotationSettings: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        payment_terms: servicesResponce.data.data[0]["payment_terms"],
        currency: servicesResponce.data.data[0]["currency"].toUpperCase(),
        contact_person: servicesResponce.data.data[0]["contact_person"],
        contact_email: servicesResponce.data.data[0]["contact_email"],
        validity: servicesResponce.data.data[0]["validity"],
        //quote_notes: servicesResponce.data.data[0]["quote_notes"],
        tableLoader: false,
      });
    } else {
      // window.location.replace("/error");
    }
  };

  open_duplicate = async (value, tableMeta) => {
    let quote_accountno = tableMeta.rowData[0];

    this.setState({
      formLoaderDuplicate: true,
      errorMessageDuplicate: "",
      successMessageDuplicate: "",
      quote_accountno: quote_accountno,
      duplicate_button: false,
    });
    window.$("#modalFormDuplicate").modal("show");
    setTimeout(() => {
      this.setState({
        formLoaderDuplicate: false,
      });
    }, 2000);
  };

  create_duplicate = async () => {
    const { client_accountno, quote_accountno } = this.state;
    this.setState({ duplicate_button: true });
    const servicesResponce = await createDuplicateQuotation(
      auth.getAccount(),
      auth.getToken(),
      client_accountno,
      quote_accountno
    );
    //console.log("addRfqQuestion: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      //auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessageDuplicate:
          "There is some error while creating the copy of the quotation.",
        successMessageDuplicate: "",
        disabled: false,
        duplicate_button: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        errorMessageDuplicate: "",
        successMessageDuplicate: "Quotation created successfully.",
        disabled: false,
        duplicate_button: false,
      });
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    } else {
      this.setState({
        errorMessageDuplicate:
          "There is some error while creating the copy of the quotation.",
        successMessageDuplicate: "",
        disabled: false,
        duplicate_button: false,
      });
    }

    setTimeout(() => {
      this.setState({ errorMessageDuplicate: "", successMessageDuplicate: "" });
    }, 5000);
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy");
  };

  create_link = (value, tableMeta) => {
    let getaccount = tableMeta.rowData[3].split("###");
    //console.log("create_link: ", getaccount);
    let edit_url =
      "/EditQuotations/" + getaccount[0] + "/" + tableMeta.rowData[0];
    if (tableMeta.rowData[1] && tableMeta.rowData[1] !== "") {
      edit_url =
        "/EditQuotations/" +
        getaccount[0] +
        "/" +
        tableMeta.rowData[0] +
        "/" +
        tableMeta.rowData[1];
    }

    let quote_accountno = tableMeta.rowData[0];
    if (quote_accountno !== "") {
      return (
        <div>
          <a
            style={{ cursor: "pointer", textDecoration: "underline" }}
            className="text-primary"
            onClick={() => this.addMenuHistory(edit_url)}
            data-toggle="tooltip"
            data-placement="top"
            title="View Quotation"
            disabled={
              this.props.is_msa_signed === "No" ||
              this.props.defaultSettings === "missing"
                ? true
                : false
            }
          >
            {tableMeta.rowData[0]}
          </a>
        </div>
      );
    }
  };

  defaultTitle = (value, tableMeta) => {
    if (value && value !== "") {
      let is_default = value.split("###");
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: is_default[1],
          }}
        />
      );
    }
    return "---";
  };

  defaultTitleAgent = (value, tableMeta) => {
    if (value && value !== "") {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: value,
          }}
        />
      );
    }
    return "---";
  };

  defaultText = (value, tableMeta) => {
    let is_default = value;

    if (is_default === "pending") {
      return <span className="badge badge-outline-primary">Pending</span>;
    } else if (is_default === "completed") {
      return <span className="badge badge-success">Completed</span>;
    } else if (is_default === "cancelled") {
      return <span className="badge badge-outline-danger">Cancelled</span>;
    } else if (is_default === "in_review") {
      return <span className="badge badge-outline-success">Submitted</span>;
    } else if (is_default === "drafted") {
      return <span className="badge badge-warning">Drafted</span>;
    }
  };

  open_clients = async () => {
    this.setState({
      formLoader: false,
      errorMessageClient: "",
    });
    window.$("#modalFormClients").modal("show");
    setTimeout(() => {
      this.setState({
        formLoader: false,
      });
    }, 2000);
  };

  add_quotation = async () => {
    const { client_accountno } = this.state;
    if (client_accountno && client_accountno !== "") {
      window.$("#modalFormClients").modal("hide");
      this.addMenuHistory(
        "/EditQuotations/" +
          client_accountno +
          "/" +
          this.generateRandomNumber() +
          ""
      );
    } else {
      this.setState({
        errorMessageClient: "Please choose a client.",
      });
    }
  };

  generateRandomNumber = () => {
    const min = 1000000; // Minimum 10-digit number (10^9)
    const max = 9999999; // Maximum 10-digit number (10^10 - 1)
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomNumber;
  };

  handleChangeClient = async (changed_value) => {
    //console.log("handleChangeSearch: ", changed_value);
    if (changed_value) {
      this.setState(
        (prevState) => ({
          client_accountno: changed_value.value,
          choose_accountno: changed_value,
        }),
        () => {
          // This callback function is called right after the state is updated
          console.log("Updated state:", this.state.client_accountno);
          //this.reloadClients();
        }
      );
    } else {
      this.setState({ choose_accountno: null });
    }
  };

  handleChangeSearch = async (changed_value) => {
    //console.log("handleChangeSearch: ", changed_value);

    if (changed_value) {
      this.setState(
        (prevState) => ({
          client_accountno: changed_value.value,
          selected_accountno: changed_value,
        }),
        () => {
          // This callback function is called right after the state is updated
          console.log("Updated state:", this.state.client_accountno);
          this.reloadClients();
        }
      );
    } else {
      this.setState(
        {
          client_accountno: null,
          selected_accountno: null,
        },
        () => {
          console.log("Cleared selection");
          this.reloadClients();
        }
      );
    }
  };

  handleChangeDuplicate = async (changed_value) => {
    //console.log("handleChangeDuplicate: ", changed_value);
    if (changed_value) {
      this.setState(
        (prevState) => ({
          client_accountno: changed_value.value,
          choose_accountno_duplicate: changed_value,
        }),
        () => {
          // This callback function is called right after the state is updated
          console.log("Updated state:", this.state.client_accountno);
          //this.reloadClients();
        }
      );
    } else {
      this.setState({ choose_accountno_duplicate: null });
    }
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  calculatePercent = (percent, num) => {
    return (percent / 100) * num;
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  render() {
    //$(".nk-block .MUIDataTable-paper-2").css("padding", "10px");
    console.log("this.props: ", this.props);
    return (
      <div className="nk-content " id="Services_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle
                        name="Quotations"
                        icon="icon ni ni-card-view"
                      />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          <li className="nk-block-tools-opt">
                            <a
                              style={{ cursor: "pointer", color: "#fff" }}
                              onClick={() => this.open_clients()}
                              className="btn btn-primary"
                            >
                              <em className="icon ni ni-plus-round"></em>
                              <span>Add New Quotation</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      <div className="card" style={{ borderRadius: "0px" }}>
                        <div className="card-inner border-bottom">
                          <div className="row g-gs mb-3">
                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    Choose Client{" "}
                                    <span className="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <Select
                                    options={this.state.all_active_clients}
                                    name="selected_accountno"
                                    placeholder="Select a client to view their quotations."
                                    value={this.state.selected_accountno}
                                    autoComplete="off"
                                    emptyMessage="Clients not found"
                                    onChange={this.handleChangeSearch}
                                    isClearable
                                    isSearchable
                                    components={animatedComponents}
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        lineHeight: "40px",
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* START DATATABLE */}
                      {this.state.tableLoader === true ? (
                        tableLoader()
                      ) : (
                        <DataTable
                          columns={this.state.columnServices}
                          tableData={this.state.tableData}
                          title=""
                        />
                      )}
                    </div>
                  </div>
                  {/* Table 5th Col End */}

                  {/**** Clients Modal ****/}

                  <div
                    className="modal fade zoom"
                    tabIndex="-1"
                    id="modalFormClients"
                  >
                    <div
                      className="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <div
                            className="modal-title"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <strong>
                              <em className="icon ni ni-list-round"></em> Select
                              Client{" "}
                            </strong>
                          </div>
                          <a
                            href="#"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                        </div>

                        <div className="modal-body">
                          {/* NRC DIVS START */}

                          {this.state.formLoader === true ? (
                            FormLoader()
                          ) : (
                            <>
                              <div>
                                <div className="row g-gs mb-3">
                                  <div className="col-md-12">
                                    {this.state.errorMessageClient !== "" ? (
                                      <div className="example-alert mb-2">
                                        <div className="alert alert-pro alert-danger alert-dismissible">
                                          <div className="alert-text">
                                            <h6>Error</h6>
                                            <p>
                                              {this.state.errorMessageClient}{" "}
                                            </p>
                                          </div>
                                          <button
                                            className="close"
                                            data-dismiss="alert"
                                          ></button>
                                        </div>
                                      </div>
                                    ) : null}
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          Choose Client{" "}
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group">
                                        <Select
                                          options={
                                            this.state.all_active_clients
                                          }
                                          name="choose_accountno"
                                          placeholder="Select a client"
                                          value={this.state.choose_accountno}
                                          autoComplete="off"
                                          emptyMessage="Clients not found"
                                          onChange={this.handleChangeClient}
                                          isClearable
                                          isSearchable
                                          components={animatedComponents}
                                          styles={{
                                            control: (baseStyles, state) => ({
                                              ...baseStyles,
                                              lineHeight: "40px",
                                            }),
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <ul className="nk-stepper-pagination pt-4 gx-4 gy-2 stepper-pagination d-flex justify-content-end">
                                        <li className="step-next">
                                          <button
                                            type="button"
                                            className="btn btn-wider btn-primary"
                                            onClick={this.add_quotation}
                                          >
                                            <span>Continue</span>
                                            <em className="icon ni ni-arrow-right"></em>
                                          </button>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/**** Clients Modal ****/}
                  {/**** Quotation View ****/}
                  <div
                    className="modal fade"
                    tabindex="-1"
                    id="modalDefaultDetails"
                  >
                    <div
                      className="modal-dialog modal-dialog-top modal-xl"
                      role="document"
                    >
                      <div className="modal-content">
                        <a
                          href="#"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-header">
                          <h5 className="modal-title">
                            <em className="icon ni ni-file-docs"></em> Quotation
                            Details
                            {this.state.payment_status === "unpaid" ? (
                              <span className="badge badge-pill badge-outline-danger ml-3">
                                Unpaid
                              </span>
                            ) : this.state.payment_status === "paid" ? (
                              <span className="badge badge-pill badge-outline-success ml-3">
                                Paid
                              </span>
                            ) : this.state.payment_status === "partial" ? (
                              <span className="badge badge-pill badge-outline-primary ml-3">
                                Partially Paid
                              </span>
                            ) : this.state.payment_status === "agreed" ? (
                              <span className="badge badge-pill badge-outline-warning ml-3">
                                Agreed (But not paid)
                              </span>
                            ) : null}
                          </h5>
                        </div>
                        <div className="modal-body ">
                          <div className="row g-gs">
                            <div className="col-md-12 invoice_details_popup">
                              {this.state.invoice_loader === true ? (
                                Invoice()
                              ) : (
                                <div className="nk-block">
                                  <div className="invoice">
                                    <div className="col-md-12 mb-3">
                                      <div className="d-flex justify-content-end">
                                        <ul className="nk-block-tools gx-3">
                                          <li className="btn-wrap">
                                            <a
                                              style={{
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                this.setState({
                                                  show_quotation_email_block: true,
                                                });
                                              }}
                                              className="btn btn-icon btn-xl btn-dim btn-outline-light"
                                            >
                                              <em className="icon ni ni-emails"></em>
                                            </a>
                                            <span className="btn-extext">
                                              Send
                                            </span>
                                          </li>
                                          {this.state.quotation_attachement &&
                                          this.state.quotation_attachement !==
                                            "" ? (
                                            <li className="btn-wrap">
                                              {this.state
                                                .quotation_attachement_button ===
                                              true ? (
                                                <>
                                                  <button
                                                    disabled
                                                    style={{
                                                      cursor: "pointer",
                                                      color: "#fff",
                                                    }}
                                                    className="btn btn-icon btn-xl btn-success"
                                                  >
                                                    <em className="icon ni ni-file-pdf"></em>
                                                  </button>
                                                  <span className="btn-extext">
                                                    Downloading
                                                  </span>
                                                </>
                                              ) : (
                                                <>
                                                  <button
                                                    onClick={() =>
                                                      this.downloadPdfDetails(
                                                        this.state
                                                          .quotation_attachement
                                                      )
                                                    }
                                                    style={{
                                                      cursor: "pointer",
                                                      color: "#fff",
                                                    }}
                                                    className="btn btn-icon btn-xl btn-success"
                                                  >
                                                    <em className="icon ni ni-file-pdf"></em>
                                                  </button>
                                                  <span className="btn-extext">
                                                    Download
                                                  </span>
                                                </>
                                              )}
                                            </li>
                                          ) : null}
                                        </ul>
                                      </div>
                                    </div>
                                    {this.state.show_quotation_email_block ===
                                    true ? (
                                      <div className="card card-bordered mb-3">
                                        <div className="card-inner">
                                          <div className="row g-gs">
                                            {this.state.quotationEmailError !==
                                            "" ? (
                                              <div className="col-sm-12">
                                                <div className="alert alert-pro alert-danger mb-3">
                                                  <div className="alert-text">
                                                    <h6>
                                                      {
                                                        this.state
                                                          .quotationEmailErrorTitle
                                                      }
                                                    </h6>
                                                    <p>
                                                      {
                                                        this.state
                                                          .quotationEmailError
                                                      }
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}
                                            {this.state
                                              .quotationEmailSuccess !== "" ? (
                                              <div className="col-sm-12">
                                                <div className="alert alert-pro alert-success mb-3">
                                                  <div className="alert-text">
                                                    <h6>Success</h6>
                                                    <p>
                                                      {
                                                        this.state
                                                          .quotationEmailSuccess
                                                      }
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}
                                            <div className="col-sm-12">
                                              <div className="form-group">
                                                <label
                                                  className="form-label"
                                                  for="default-01"
                                                >
                                                  To
                                                </label>
                                                <div className="form-control-wrap">
                                                  <input
                                                    type="text"
                                                    className="form-control form-control-lg"
                                                    id="quotation_email"
                                                    name="quotation_email"
                                                    value={
                                                      this.state.quotation_email
                                                    }
                                                    onChange={this.handleChange}
                                                    placeholder="Enter email address where you want to send email."
                                                  />
                                                </div>
                                              </div>
                                            </div>

                                            <div className="col-sm-12">
                                              <div className="form-group">
                                                <label
                                                  className="form-label"
                                                  for="default-07"
                                                >
                                                  Custom Content
                                                </label>
                                                <div className="form-control-wrap">
                                                  <textarea
                                                    className="form-control"
                                                    id="quotation_email_content"
                                                    name="quotation_email_content"
                                                    value={
                                                      this.state
                                                        .quotation_email_content
                                                    }
                                                    onChange={this.handleChange}
                                                  ></textarea>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="col-sm-12">
                                              <div className="form-group d-flex justify-content-end">
                                                {this.state
                                                  .quotation_email_button ===
                                                true ? (
                                                  <button
                                                    className="btn btn-primary"
                                                    type="button"
                                                    disabled
                                                  >
                                                    <span
                                                      className="spinner-border spinner-border-sm"
                                                      role="status"
                                                      aria-hidden="true"
                                                    ></span>
                                                    <span> Loading... </span>
                                                  </button>
                                                ) : (
                                                  <>
                                                    <button
                                                      type="button"
                                                      style={{ marginRight: 5 }}
                                                      className="btn btn-light"
                                                      onClick={() => {
                                                        this.setState({
                                                          show_quotation_email_block: false,
                                                          quotation_email: "",
                                                          quotation_email_content:
                                                            "",
                                                          quotation_email_button: false,
                                                          quotationEmailError:
                                                            "",
                                                          quotationEmailErrorTitle:
                                                            "",
                                                          quotationEmailSuccess:
                                                            "",
                                                        });
                                                      }}
                                                    >
                                                      Cancel
                                                    </button>
                                                    <button
                                                      type="button"
                                                      className="btn btn-primary"
                                                      onClick={() => {
                                                        this.submitQuotationEmail();
                                                      }}
                                                    >
                                                      Send Email
                                                    </button>
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                    <div className="invoice-wrap">
                                      <div className="invoice-head">
                                        <div className="invoice-contact">
                                          <span className="overline-title">
                                            Quotation To
                                          </span>
                                          <div className="invoice-contact-info">
                                            <h4 className="title">
                                              {this.state.client_name} -{" "}
                                              {this.state.client_company}
                                            </h4>
                                            <ul className="list-plain">
                                              {this.state.client_address &&
                                              this.state.client_address !==
                                                "" ? (
                                                <li>
                                                  <em className="icon ni ni-map-pin-fill"></em>
                                                  <span>
                                                    {this.state
                                                      .client_address &&
                                                    this.state
                                                      .client_address !== ""
                                                      ? this.state
                                                          .client_address
                                                      : ""}{" "}
                                                    {this.state
                                                      .client_address2 &&
                                                    this.state
                                                      .client_address2 !== ""
                                                      ? this.state
                                                          .client_address2
                                                      : ""}
                                                    <br />
                                                    {this.state.client_city &&
                                                    this.state.client_city !==
                                                      ""
                                                      ? this.state.client_city
                                                      : ""}{" "}
                                                    {this.state.client_state &&
                                                    this.state.client_state !==
                                                      ""
                                                      ? this.state.client_state
                                                      : ""}{" "}
                                                    {this.state.client_zip &&
                                                    this.state.client_zip !== ""
                                                      ? this.state.client_zip
                                                      : ""}{" "}
                                                    {this.state
                                                      .client_country &&
                                                    this.state
                                                      .client_country !== ""
                                                      ? this.state
                                                          .client_country
                                                      : ""}
                                                  </span>
                                                </li>
                                              ) : null}
                                              {this.state.client_phone &&
                                              this.state.client_phone !== "" ? (
                                                <li>
                                                  <em className="icon ni ni-call-fill"></em>
                                                  <span>
                                                    {this.state.client_phone &&
                                                    this.state.client_phone !==
                                                      ""
                                                      ? this.formatPhoneNumber(
                                                          this.state
                                                            .client_phone
                                                        )
                                                      : "---"}
                                                  </span>
                                                </li>
                                              ) : null}
                                            </ul>
                                          </div>
                                        </div>
                                        <div className="invoice-desc right_invoice_details">
                                          <h3 className="title">Quotation</h3>
                                          <ul className="list-plain">
                                            <li className="invoice-id border-bottom">
                                              <span className="text-dark">
                                                Quotation No
                                              </span>
                                              :
                                              <span className="text-dark">
                                                {this.state.quote_accountno}
                                              </span>
                                            </li>
                                            <li className="invoice-date border-bottom">
                                              <span className="text-dark">
                                                Issue Date
                                              </span>
                                              :
                                              <span className="text-dark">
                                                {this.format_date(new Date())}
                                              </span>
                                            </li>
                                            <li className="invoice-date border-bottom">
                                              <span className="text-dark">
                                                From
                                              </span>
                                              :
                                              <span className="text-dark">
                                                {this.state.reseller_company}
                                              </span>
                                            </li>
                                            <li className="invoice-date border-bottom">
                                              <span className="text-dark">
                                                Company
                                              </span>
                                              :
                                              <span className="text-dark">
                                                {this.state.client_company}
                                              </span>
                                            </li>

                                            {this.state.payment_terms !== "" ? (
                                              <li className="invoice-date border-bottom">
                                                <span className="text-dark">
                                                  Terms of Payment
                                                </span>
                                                :
                                                <span className="text-dark">
                                                  {this.state.payment_terms}
                                                </span>
                                              </li>
                                            ) : null}
                                            {this.state.currency !== "" ? (
                                              <li className="invoice-date border-bottom">
                                                <span className="text-dark">
                                                  Currency
                                                </span>
                                                :
                                                <span className="text-dark">
                                                  {this.state.currency}
                                                </span>
                                              </li>
                                            ) : null}
                                            {this.state.contact_person !==
                                            "" ? (
                                              <li className="invoice-date border-bottom">
                                                <span className="text-dark">
                                                  Contact Person{" "}
                                                </span>
                                                :
                                                <span className="text-dark">
                                                  {this.state.contact_person}
                                                </span>
                                              </li>
                                            ) : null}
                                            {this.state.contact_email !== "" ? (
                                              <li className="invoice-date border-bottom">
                                                <span className="text-dark">
                                                  Email
                                                </span>
                                                :
                                                <span className="text-dark">
                                                  {this.state.contact_email}
                                                </span>
                                              </li>
                                            ) : null}
                                          </ul>
                                        </div>
                                      </div>
                                      <div className="invoice-bills">
                                        <div className="table-responsive">
                                          <table className="table table-striped">
                                            <thead>
                                              <tr>
                                                <th
                                                  style={{
                                                    paddingLeft: "1.25rem",
                                                  }}
                                                >
                                                  ITEM ID
                                                </th>
                                                <th className="w-60">
                                                  PRODUCT NAME
                                                </th>
                                                <th>QTY</th>
                                                <th>UNIT</th>
                                                <th>PRICE($)</th>
                                                <th>TOTAL($)</th>
                                              </tr>
                                            </thead>
                                            {this.state.rows &&
                                            this.state.rows.length > 0 ? (
                                              <>
                                                <tbody>
                                                  {this.state.rows.map(
                                                    (row, index) => (
                                                      <>
                                                        {row.data.type &&
                                                        row.data.type ===
                                                          "service" ? (
                                                          <tr
                                                            key={`ind${index}`}
                                                          >
                                                            <td>
                                                              {row.data.id &&
                                                              row.data.id !== ""
                                                                ? row.data.id
                                                                : "---"}
                                                            </td>
                                                            <td>
                                                              {row.data.name !==
                                                              ""
                                                                ? row.data
                                                                    .name &&
                                                                  row.data
                                                                    .name !== ""
                                                                  ? row.data
                                                                      .name
                                                                  : "---"
                                                                : null}
                                                            </td>
                                                            <td>
                                                              {
                                                                row.data
                                                                  .quantity
                                                              }
                                                            </td>
                                                            <td>
                                                              {row.data.unit}
                                                            </td>
                                                            <td>
                                                              
                                                              {this.formatAsCurrencySimple(
                                                                Number(
                                                                  row.data.price
                                                                )
                                                              )}
                                                            </td>
                                                            <td>
                                                              {this.formatAsCurrency(
                                                                Number(
                                                                  row.data.total
                                                                )
                                                              )}
                                                            </td>
                                                          </tr>
                                                        ) : row.data.type ===
                                                          "section" ? (
                                                          <tr
                                                            key={`ind${index}`}
                                                          >
                                                            <td
                                                              className="text-left"
                                                              colSpan={6}
                                                            >
                                                              {row.data.name !==
                                                              ""
                                                                ? row.data
                                                                    .name &&
                                                                  row.data
                                                                    .name !== ""
                                                                  ? row.data
                                                                      .name
                                                                  : "---"
                                                                : null}
                                                            </td>
                                                          </tr>
                                                        ) : row.data.type ===
                                                          "custom" ? (
                                                          <tr
                                                            key={`ind${index}`}
                                                          >
                                                            <td>
                                                              {row.data.id &&
                                                              row.data.id !== ""
                                                                ? row.data.id
                                                                : "---"}
                                                            </td>
                                                            <td>
                                                              {row.data.name !==
                                                              "" ? (
                                                                <>
                                                                  <div>
                                                                    {row.data
                                                                      .name ||
                                                                      "---"}
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      fontSize:
                                                                        "0.8rem",
                                                                      color:
                                                                        "#8A94AA",
                                                                    }}
                                                                  >
                                                                    {row.data
                                                                      .description ||
                                                                      ""}
                                                                  </div>
                                                                </>
                                                              ) : null}
                                                            </td>
                                                            <td>
                                                              {
                                                                row.data
                                                                  .quantity
                                                              }
                                                            </td>
                                                            <td>
                                                              {row.data.unit}
                                                            </td>
                                                            <td>
                                                              
                                                              {this.formatAsCurrencySimple(
                                                                Number(
                                                                  row.data.price
                                                                )
                                                              )}
                                                            </td>
                                                            <td>
                                                              {this.formatAsCurrency(
                                                                Number(
                                                                  row.data.total
                                                                )
                                                              )}
                                                            </td>
                                                          </tr>
                                                        ) : null}
                                                      </>
                                                    )
                                                  )}
                                                </tbody>
                                                <tfoot>
                                                  <tr>
                                                    <td colSpan="3"></td>
                                                    <td colSpan="2">
                                                      Subtotal
                                                    </td>
                                                    <td>
                                                      {this.formatAsCurrency(
                                                        Number(
                                                          this.state
                                                            .service_grand_sub_total
                                                        )
                                                      )}
                                                    </td>
                                                  </tr>
                                                  {this.state
                                                    .discount_value_total &&
                                                  this.state
                                                    .discount_value_total !==
                                                    "" ? (
                                                    <tr>
                                                      <td colSpan="3"></td>
                                                      <td colSpan="2">
                                                        Discount
                                                      </td>
                                                      <td>
                                                        {this.formatAsCurrency(
                                                          Number(
                                                            this.state
                                                              .discount_value_total
                                                          )
                                                        )}
                                                      </td>
                                                    </tr>
                                                  ) : null}
                                                  {this.state
                                                    .shipping_value_total &&
                                                  this.state
                                                    .shipping_value_total !==
                                                    "" ? (
                                                    <tr>
                                                      <td colSpan="3"></td>
                                                      <td colSpan="2">
                                                        Shipping & Delivery
                                                      </td>
                                                      <td>
                                                        {this.formatAsCurrency(
                                                          Number(
                                                            this.state
                                                              .shipping_value_total
                                                          )
                                                        )}
                                                      </td>
                                                    </tr>
                                                  ) : null}
                                                  {this.state.tax_value_total &&
                                                  this.state.tax_value_total !==
                                                    "" ? (
                                                    <tr>
                                                      <td colSpan="3"></td>
                                                      <td colSpan="2">TAX</td>
                                                      <td>
                                                        {this.formatAsCurrency(
                                                          Number(
                                                            this.state
                                                              .tax_value_total
                                                          )
                                                        )}
                                                      </td>
                                                    </tr>
                                                  ) : null}
                                                  <tr>
                                                    <td colSpan="3"></td>
                                                    <td colSpan="2">
                                                      Grand Total
                                                    </td>
                                                    <td>
                                                      {this.formatAsCurrency(
                                                        Number(
                                                          this.state
                                                            .service_grand_total
                                                        )
                                                      )}
                                                    </td>
                                                  </tr>
                                                </tfoot>
                                              </>
                                            ) : null}
                                          </table>
                                        </div>
                                        <div className="col-xxl-12 mt-4">
                                          <div
                                            id="accordion-2"
                                            className="accordion accordion-s3"
                                          >
                                            {this.state.quote_notes &&
                                            this.state.quote_notes !== "" ? (
                                              <div className="accordion-item">
                                                <a
                                                  href="#"
                                                  className="accordion-head"
                                                  data-toggle="collapse"
                                                  data-target="#accordion-item-2-1"
                                                >
                                                  <h6 className="title">
                                                    Notes
                                                  </h6>
                                                  <span className="accordion-icon"></span>
                                                </a>
                                                <div
                                                  className="accordion-body collapse show"
                                                  id="accordion-item-2-1"
                                                  data-parent="#accordion-2"
                                                >
                                                  <div className="accordion-inner">
                                                    <div
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          this.state
                                                            .quote_notes,
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}
                                            {this.state.rows_optional &&
                                            this.state.rows_optional.length >
                                              0 ? (
                                              <div className="accordion-item">
                                                <a
                                                  href="#"
                                                  className="accordion-head collapsed"
                                                  data-toggle="collapse"
                                                  data-target="#accordion-item-2-2"
                                                >
                                                  <h6 className="title">
                                                    Optional Products
                                                  </h6>
                                                  <span className="accordion-icon"></span>
                                                </a>
                                                <div
                                                  className="accordion-body collapse"
                                                  id="accordion-item-2-2"
                                                  data-parent="#accordion-2"
                                                >
                                                  <div className="accordion-inner">
                                                    <div className="col-xxl-12">
                                                      <table className="table table-striped">
                                                        <thead>
                                                          <tr>
                                                            <th
                                                              style={{
                                                                paddingLeft:
                                                                  "1.25rem",
                                                              }}
                                                            >
                                                              ITEM ID
                                                            </th>
                                                            <th className="w-60">
                                                              PRODUCT NAME
                                                            </th>
                                                            <th>QTY</th>
                                                            <th>UNIT</th>
                                                            <th>PRICE($)</th>
                                                            <th>TOTAL($)</th>
                                                          </tr>
                                                        </thead>

                                                        <tbody>
                                                          {this.state.rows_optional.map(
                                                            (row, index) => (
                                                              <>
                                                                {row.data
                                                                  .type &&
                                                                row.data
                                                                  .type ===
                                                                  "service" ? (
                                                                  <tr
                                                                    key={`ind${index}`}
                                                                  >
                                                                    <td>
                                                                      {row.data
                                                                        .id &&
                                                                      row.data
                                                                        .id !==
                                                                        ""
                                                                        ? row
                                                                            .data
                                                                            .id
                                                                        : "---"}
                                                                    </td>
                                                                    <td>
                                                                      {row.data
                                                                        .name !==
                                                                      ""
                                                                        ? row
                                                                            .data
                                                                            .name &&
                                                                          row
                                                                            .data
                                                                            .name !==
                                                                            ""
                                                                          ? row
                                                                              .data
                                                                              .name
                                                                          : "---"
                                                                        : null}
                                                                    </td>
                                                                    <td>
                                                                      {
                                                                        row.data
                                                                          .quantity
                                                                      }
                                                                    </td>
                                                                    <td>
                                                                      {
                                                                        row.data
                                                                          .unit
                                                                      }
                                                                    </td>
                                                                    <td>
                                                                      $
                                                                      {Number(
                                                                        row.data
                                                                          .price
                                                                      ).toFixed(
                                                                        2
                                                                      )}
                                                                    </td>
                                                                    <td>
                                                                      $
                                                                      {
                                                                        row.data
                                                                          .total
                                                                      }
                                                                    </td>
                                                                  </tr>
                                                                ) : row.data
                                                                    .type ===
                                                                  "section" ? (
                                                                  <tr
                                                                    key={`ind${index}`}
                                                                  >
                                                                    <td
                                                                      className="text-left"
                                                                      colSpan={
                                                                        6
                                                                      }
                                                                    >
                                                                      {row.data
                                                                        .name !==
                                                                      ""
                                                                        ? row
                                                                            .data
                                                                            .name &&
                                                                          row
                                                                            .data
                                                                            .name !==
                                                                            ""
                                                                          ? row
                                                                              .data
                                                                              .name
                                                                          : "---"
                                                                        : null}
                                                                    </td>
                                                                  </tr>
                                                                ) : null}
                                                              </>
                                                            )
                                                          )}
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}
                                            {this.state.rfq_requirements &&
                                            this.state.rfq_requirements !==
                                              "" ? (
                                              <div className="accordion-item">
                                                <a
                                                  href="#"
                                                  className="accordion-head collapsed"
                                                  data-toggle="collapse"
                                                  data-target="#accordion-item-2-3"
                                                >
                                                  <h6 className="title">
                                                    Received RFQ
                                                  </h6>
                                                  <span className="accordion-icon"></span>
                                                </a>
                                                <div
                                                  className="accordion-body collapse "
                                                  id="accordion-item-2-3"
                                                  data-parent="#accordion-2"
                                                >
                                                  <div className="accordion-inner">
                                                    <div className="row g-gs">
                                                      <div className="col-xxl-12">
                                                        <h5>Requirements</h5>
                                                        <div
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              this.state
                                                                .rfq_requirements,
                                                          }}
                                                        />
                                                      </div>
                                                      {this.state
                                                        .rfq_attachment &&
                                                      this.state
                                                        .rfq_attachment !==
                                                        "" ? (
                                                        <div className="col-xxl-12">
                                                          <h5>Attachment</h5>
                                                          <table className="table table-striped">
                                                            <tbody>
                                                              <tr>
                                                                <td>
                                                                  Download
                                                                  Attachment
                                                                </td>
                                                                <td>
                                                                  <a
                                                                    onClick={() =>
                                                                      this.downloadPdfRfq(
                                                                        this
                                                                          .state
                                                                          .rfq_attachment
                                                                      )
                                                                    }
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                    title="Download Attachment"
                                                                  >
                                                                    <span className="badge badge-outline-secondary">
                                                                      {" "}
                                                                      <em className="icon ni ni-download"></em>{" "}
                                                                      Download
                                                                      Attachment
                                                                    </span>
                                                                  </a>
                                                                </td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </div>
                                                      ) : null}
                                                      {this.state
                                                        .rows_rfq_services &&
                                                      this.state
                                                        .rows_rfq_services
                                                        .length > 0 ? (
                                                        <div className="col-xxl-12">
                                                          <h5>
                                                            RFQ Products &
                                                            Services
                                                          </h5>

                                                          <table className="table table-striped">
                                                            <thead>
                                                              <tr>
                                                                <th
                                                                  className="w-150px"
                                                                  style={{
                                                                    paddingLeft:
                                                                      "1.25rem",
                                                                  }}
                                                                >
                                                                  ITEM ID
                                                                </th>
                                                                <th className="w-60">
                                                                  Product name
                                                                </th>
                                                                <th>
                                                                  Quantity
                                                                </th>
                                                                <th>Unit</th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {this.state.rows_rfq_services.map(
                                                                (
                                                                  row,
                                                                  index
                                                                ) => (
                                                                  <tr
                                                                    key={index}
                                                                  >
                                                                    <td>
                                                                      {
                                                                        row.data
                                                                          .id
                                                                      }
                                                                    </td>
                                                                    <td>
                                                                      {
                                                                        row.data
                                                                          .name
                                                                      }
                                                                    </td>
                                                                    <td>
                                                                      {
                                                                        row.data
                                                                          .quantity
                                                                      }
                                                                    </td>
                                                                    <td>
                                                                      {
                                                                        row.data
                                                                          .unit
                                                                      }
                                                                    </td>
                                                                  </tr>
                                                                )
                                                              )}
                                                            </tbody>
                                                          </table>
                                                        </div>
                                                      ) : null}
                                                      {this.state
                                                        .rows_rfq_questions &&
                                                      this.state
                                                        .rows_rfq_questions
                                                        .length > 0 ? (
                                                        <div className="col-xxl-12">
                                                          <h5>Questions</h5>

                                                          <table className="table table-striped">
                                                            <thead>
                                                              <tr>
                                                                <th
                                                                  className="w-150px"
                                                                  style={{
                                                                    paddingLeft:
                                                                      "1.25rem",
                                                                  }}
                                                                >
                                                                  Question
                                                                </th>
                                                                <th
                                                                  className="w-60"
                                                                  style={{
                                                                    paddingLeft:
                                                                      "1.25rem",
                                                                    textAlign:
                                                                      "left",
                                                                  }}
                                                                >
                                                                  Answer
                                                                </th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {this.state.rows_rfq_questions.map(
                                                                (
                                                                  rfq,
                                                                  index
                                                                ) => (
                                                                  <tr
                                                                    key={index}
                                                                  >
                                                                    <td
                                                                      style={{
                                                                        textAlign:
                                                                          "left",
                                                                      }}
                                                                    >
                                                                      {
                                                                        rfq.question
                                                                      }
                                                                    </td>
                                                                    <td
                                                                      style={{
                                                                        textAlign:
                                                                          "left",
                                                                      }}
                                                                    >
                                                                      {
                                                                        rfq.answer
                                                                      }
                                                                    </td>
                                                                  </tr>
                                                                )
                                                              )}
                                                            </tbody>
                                                          </table>
                                                        </div>
                                                      ) : null}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/**** Quotation View ****/}
                  {/**** Duplicate Modal ****/}

                  <div
                    className="modal fade zoom"
                    tabIndex="-1"
                    id="modalFormDuplicate"
                  >
                    <div
                      className="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <div
                            className="modal-title"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <strong>
                              <em className="icon ni ni-list-round"></em> Copy
                              Quotation{" "}
                            </strong>
                          </div>
                          <a
                            href="#"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                        </div>

                        <div className="modal-body">
                          {/* NRC DIVS START */}

                          {this.state.formLoaderDuplicate === true ? (
                            FormLoader()
                          ) : (
                            <>
                              <div>
                                <div className="row g-gs mb-3">
                                  <div className="col-md-12">
                                    {this.state.errorMessageDuplicate !== "" ? (
                                      <div className="example-alert mb-2">
                                        <div className="alert alert-pro alert-danger alert-dismissible">
                                          <div className="alert-text">
                                            <h6>Error</h6>
                                            <p>
                                              {this.state.errorMessageDuplicate}{" "}
                                            </p>
                                          </div>
                                          <button
                                            className="close"
                                            data-dismiss="alert"
                                          ></button>
                                        </div>
                                      </div>
                                    ) : null}
                                    {this.state.successMessageDuplicate !==
                                    "" ? (
                                      <div className="example-alert mb-2">
                                        <div className="alert alert-pro alert-success alert-dismissible">
                                          <div className="alert-text">
                                            <h6>Success</h6>
                                            <p>
                                              {
                                                this.state
                                                  .successMessageDuplicate
                                              }{" "}
                                            </p>
                                          </div>
                                          <button
                                            className="close"
                                            data-dismiss="alert"
                                          ></button>
                                        </div>
                                      </div>
                                    ) : null}
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          Choose Client{" "}
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group">
                                        <Select
                                          options={
                                            this.state.all_active_clients
                                          }
                                          name="choose_accountno_duplicate"
                                          placeholder="Select a client"
                                          value={
                                            this.state
                                              .choose_accountno_duplicate
                                          }
                                          autoComplete="off"
                                          emptyMessage="Clients not found"
                                          onChange={this.handleChangeDuplicate}
                                          isClearable
                                          isSearchable
                                          components={animatedComponents}
                                          styles={{
                                            control: (baseStyles, state) => ({
                                              ...baseStyles,
                                              lineHeight: "40px",
                                            }),
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <ul className="nk-stepper-pagination pt-4 gx-4 gy-2 stepper-pagination d-flex justify-content-end">
                                        <li className="step-next">
                                          {this.state.duplicate_button ===
                                          true ? (
                                            <button
                                              className="btn btn-primary"
                                              type="button"
                                              disabled
                                            >
                                              <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                              ></span>
                                              <span> Creating Copy... </span>
                                            </button>
                                          ) : (
                                            <button
                                              type="button"
                                              className="btn btn-wider btn-primary"
                                              onClick={() =>
                                                this.create_duplicate()
                                              }
                                            >
                                              <span>Create Copy</span>
                                              <em className="icon ni ni-arrow-right"></em>
                                            </button>
                                          )}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/**** Duplicate Modal ****/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
    quotationSettings: state.quotationSettings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PartnerQuotations);
