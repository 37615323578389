import { React, createRef } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import $ from "jquery";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./styles.css";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";

import Services from "../Services/index";
import Analytics from "../Analytics/index";
import CommissionReport from "../Billings/CommissionReport";

import { addSettings, addMsaInfo } from "../../../actions";
import { COMPANY_NAME } from "../../../config/config";
import {
  listServices,
  getDashboardCounts,
  getAgentDashboard,
} from "./../../../config/api_calls";
import { getAgentDashboardForms } from "./../../../config/agent_forms_api_calls";
import { dateString, dateFirstString } from "./../../../config/config";
import { DataList } from "../../../components/ContentLoaders/newLoaders/DataList";

import DataTable from "../../../components/Tables/DataTable";
import { Link } from "react-router-dom";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  columnsServices,
  APP_LIVE_URL,
} from "./../../../config/config";
import HelperClass from "./../../../config/helperClass";
const auth = new HelperClass();

class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      tableLoader: true,
      formsLoader: true,
      totalRegistered: 0,
      totalActive: 0,
      monthlyBills: "0.00",
      paidBills: "0.00",
      show_rejection_banner: "no",
      agentForms: [],
      expandedForm: null,
      descriptionLines: {},
      dashboardLoader: true,
      iframe_url: "",
      show_dashboard: "",
      columnServices: [
        { name: "Id" },
        { name: "Title" },
        {
          name: "Details",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.viewBtns()}</div>;
            },
          },
        },
        { name: "Nrc($)" },
        { name: "Mrc($)" },
        { name: "Rate Type" },
        { name: "Rate($)" },
        {
          name: "Action",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <CreateIcon
                    style={{
                      cursor: "pointer",
                    }}
                    disabled={
                      this.props.is_msa_signed === "No" ||
                      this.props.defaultSettings === "missing"
                        ? true
                        : false
                    }
                  />{" "}
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => this.setMarkUp(value, tableMeta)}
                    disabled={
                      this.props.is_msa_signed === "No" ||
                      this.props.defaultSettings === "missing"
                        ? true
                        : false
                    }
                  >
                    Set Rates
                  </a>
                </div>
              );
            },
          },
        },
      ],
    };
    this.descRefs = {};
    this.measurementDivRef = createRef();
    this.resizeTimeout = null;
  }

  setMarkUp = () => {
    //window.$("#modalForm").modal("show");
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };

  async componentDidMount() {
    const getCountsInfo = await getAgentDashboard(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("getClientDashboard: ", getCountsInfo.data);
    if (
      getCountsInfo.data.status === 403 ||
      getCountsInfo.data.errors === "authentication missing" ||
      getCountsInfo.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (getCountsInfo.data.status === 404) {
      console.log("dateFirstString: ", dateFirstString);
      await this.getDashboardForms();
      await this.setServiceList();
      await this.setGraphs();
      setTimeout(() => {
        this.calculateDescriptionLines();
      }, 500);
      window.addEventListener("resize", this.handleResize);
      this.setState({
        show_dashboard: "simple",
        dashboardLoader: false,
      });
    } else if (
      getCountsInfo.data.status === 200 &&
      getCountsInfo.data.message === "success"
    ) {
      if (
        getCountsInfo.data.data &&
        getCountsInfo.data.data.length > 0 &&
        getCountsInfo.data.iframeData !== "integration_disabled"
      ) {
        this.setState({
          show_dashboard: "report",
          iframe_url: getCountsInfo.data.iframeData,
          dashboardLoader: false,
        });
      } else {
        console.log("dateFirstString: ", dateFirstString);
        await this.getDashboardForms();
        await this.setServiceList();
        await this.setGraphs();
        setTimeout(() => {
          this.calculateDescriptionLines();
        }, 500);
        window.addEventListener("resize", this.handleResize);
        this.setState({
          show_dashboard: "simple",
          dashboardLoader: false,
        });
      }
    } else {
      console.log("dateFirstString: ", dateFirstString);
      await this.getDashboardForms();
      await this.setServiceList();
      await this.setGraphs();
      setTimeout(() => {
        this.calculateDescriptionLines();
      }, 500);
      window.addEventListener("resize", this.handleResize);
      this.setState({
        show_dashboard: "simple",
        dashboardLoader: false,
      });
    }

    /*console.log("dateFirstString: ", dateFirstString);
    await this.getDashboardForms();
    await this.setServiceList();
    await this.setGraphs();
    setTimeout(() => {
      this.calculateDescriptionLines();
    }, 500);
    window.addEventListener("resize", this.handleResize);*/
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.agentForms !== this.state.agentForms) {
      setTimeout(() => {
        this.calculateDescriptionLines();
      }, 0);
    }
  }

  handleResize = () => {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout(() => {
      this.calculateDescriptionLines();
    }, 150);
  };

  calculateDescriptionLines = () => {
    const newDescriptionLines = {};
    if (!this.measurementDivRef.current) {
      const div = document.createElement("div");
      div.style.position = "absolute";
      div.style.visibility = "hidden";
      div.style.height = "auto";
      div.style.width = "100%";
      div.style.whiteSpace = "normal";
      document.body.appendChild(div);
      this.measurementDivRef.current = div;
    }

    Object.keys(this.descRefs).forEach((key) => {
      const element = this.descRefs[key];
      if (element) {
        const measureDiv = this.measurementDivRef.current;
        const parentWidth = element.parentElement.offsetWidth;
        measureDiv.style.width = `${parentWidth}px`;
        measureDiv.innerHTML = element.innerHTML;

        // Copy all relevant styles
        const computedStyle = window.getComputedStyle(element);
        measureDiv.style.fontSize = computedStyle.fontSize;
        measureDiv.style.fontFamily = computedStyle.fontFamily;
        measureDiv.style.lineHeight = computedStyle.lineHeight;
        measureDiv.style.padding = computedStyle.padding;
        measureDiv.style.margin = computedStyle.margin;
        measureDiv.style.border = computedStyle.border;
        measureDiv.style.boxSizing = computedStyle.boxSizing;

        const lineHeight = parseFloat(computedStyle.lineHeight);
        const totalHeight = measureDiv.offsetHeight;
        const lines = Math.ceil(totalHeight / lineHeight);

        newDescriptionLines[key] = lines;
      }
    });

    this.setState({ descriptionLines: newDescriptionLines });
  };

  getDashboardForms = async () => {
    const servicesResponce = await getAgentDashboardForms(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("getForms: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        formsLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        agentForms: servicesResponce.data.data,
        formsLoader: false,
      });
    } else {
      this.setState({
        formsLoader: false,
      });
      //window.location.replace("/error");
    }
  };

  viewModal = async (value, tableMeta) => {
    console.log("[viewModal]value: ", value);
    // console.log("[viewModal]tableMeta: ", tableMeta.rowIndex);
  };

  setGraphs = async () => {
    let getCountsInfo = await getDashboardCounts(
      auth.getAccount(),
      auth.getToken(),
      dateFirstString,
      dateString
    );
    console.log("Totals: ", getCountsInfo.data.total_registered);
    if (
      getCountsInfo.data.status === 403 ||
      getCountsInfo.data.errors === "authentication missing" ||
      getCountsInfo.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (getCountsInfo.data.status === 404) {
      // window.location.replace("/error");
    } else if (
      getCountsInfo.data.status === 200 &&
      getCountsInfo.data.message === "success"
    ) {
      this.setState({
        totalRegistered: getCountsInfo.data.total_registered,
        totalActive: getCountsInfo.data.total_active,
        monthlyBills: "0.00",
        //monthlyBills: getCountsInfo.data.data["monthly_bills"],
      });
    } else {
      // window.location.replace("/error");
    }
  };

  setServiceList = async () => {
    const servicesResponce = await listServices(
      auth.getAccount(),
      auth.getToken()
    );

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        show_rejection_banner:
          servicesResponce.data.rejction_data.show_rejection_banner,
        tableLoader: false,
      });
    } else {
      //window.location.replace("/error");
    }
  };

  // header = () => {
  //   return (
  //     <div className="nk-block-head nk-block-head-sm">
  //       <div className="nk-block-between">
  //         <div className="nk-block-head-content" style={{ mrginLeft: "2.5%" }}>
  //           <h3 className="nk-block-title page-title">
  //             <strong>Welcome to</strong> {auth.getCompName()}{" "}
  //             <strong>Dashboard</strong>
  //           </h3>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  render() {
    $("#Services_Block.nk-content").css("padding", "0px");
    $("#Services_Block.nk-content .container-fluid").css("padding-left", "0px");
    $("#Services_Block.nk-content .container-fluid").css(
      "padding-right",
      "0px"
    );

    $("#Analytics_Block.nk-content").css("padding", "0px");
    $("#Analytics_Block.nk-content").css("padding-bottom", "20px");
    $("#Analytics_Block.nk-content .container-fluid").css(
      "padding-left",
      "0px"
    );
    $("#Analytics_Block.nk-content .container-fluid").css(
      "padding-right",
      "0px"
    );
    $("#HeadContent").hide();
    const { shouldRenderServices, shouldRenderCommission } = this.props;
    const { expandedForm, descriptionLines } = this.state;
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {this.state.show_rejection_banner === "yes" ? (
                <div class="example-alert mb-4">
                  <div
                    class="alert alert-danger alert-icon alert-dismissible"
                    style={{ color: "#9d7a09", fontWeight: "700" }}
                  >
                    <em class="icon ni ni-cross-circle"></em>
                    Your submitted document has been rejected. Kindly{" "}
                    <a href={`${APP_LIVE_URL}documents`}>
                      <span>Click Here</span>
                    </a>{" "}
                    to check the details and take necessary actions.
                    <button class="close" data-dismiss="alert"></button>
                  </div>
                </div>
              ) : null}
              <div className="nk-block">
                <div className="nk-block-head nk-block-head-sm">
                  <div className="nk-block-between">
                    <div className="nk-block-head-content">
                      <h3 className="nk-block-title page-title">
                        <strong>Welcome to</strong> {auth.getCompName()}{" "}
                        <strong>Dashboard</strong>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              {/* {this.header()} */}
              {this.state.show_dashboard === "simple" ? (
                <>
                  {this.state.formsLoader === true ? (
                    <div className="row g-gs" style={{ clear: "both" }}>
                      <div className="col-lg-12 col-xxl-12">
                        <div
                          className="card card-bordered"
                          style={{ clear: "both" }}
                        >
                          <div className="card-inner">{DataList()}</div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {this.state.agentForms &&
                      this.state.agentForms.length > 0 ? (
                        <div class="nk-block">
                          <div class="card card-bordered">
                            <div class="card-inner-group">
                              <div class="card-inner">
                                <div class="between-center flex-wrap flex-md-nowrap g-3">
                                  <div class="nk-block-text">
                                    <h4>Important Forms to Complete</h4>
                                    <p class="text-dark">
                                      To ensure we have the most accurate and
                                      up-to-date information, please take a
                                      moment to complete the following forms.
                                      Your prompt response helps us serve you
                                      better and maintain compliance with
                                      regulatory standards. Thank you for your
                                      cooperation!
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {this.state.agentForms.map((forms, idx) => (
                                <div class="card-inner">
                                  <div class="between-center flex-wrap flex-md-nowrap g-3">
                                    <div class="nk-block-text">
                                      <h6>{forms.form_title}</h6>
                                      <p
                                        className={`text-soft ${
                                          expandedForm === idx
                                            ? "expanded"
                                            : "truncated"
                                        }`}
                                        ref={(el) => (this.descRefs[idx] = el)}
                                      >
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: forms.form_desc,
                                          }}
                                        />
                                      </p>

                                      {descriptionLines[idx] > 2 && (
                                        <span
                                          className="small-text"
                                          onClick={() =>
                                            this.setState({
                                              expandedForm:
                                                expandedForm === idx
                                                  ? null
                                                  : idx,
                                            })
                                          }
                                          style={{
                                            cursor: "pointer",
                                            textDecoration: "none",
                                            fontSize: "0.8rem",
                                            color: "#6576ff",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {expandedForm === idx
                                            ? "Read Less"
                                            : "Read More"}
                                        </span>
                                      )}
                                    </div>
                                    <div class="nk-block-actions flex-shrink-0">
                                      {forms.link_form &&
                                      forms.link_form === 1 ? (
                                        <a
                                          style={{
                                            cursor: "pointer",
                                            color: "#ffffff",
                                          }}
                                          href={forms.external_link}
                                          target="_blank"
                                          class="btn btn-primary"
                                        >
                                          {forms.btn_text}
                                        </a>
                                      ) : (
                                        <a
                                          style={{
                                            cursor: "pointer",
                                            color: "#ffffff",
                                          }}
                                          onClick={() =>
                                            this.addMenuHistory(
                                              "/agent_form_submit/" +
                                                forms.form_link
                                            )
                                          }
                                          class="btn btn-primary"
                                        >
                                          {forms.btn_text}
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </>
                  )}

                  <div className="nk-block">
                    {/* FIRST ROW START */}

                    <div className="row g-gs" style={{ clear: "both" }}>
                      <div className="col-lg-12 col-xxl-12">
                        <div
                          className="card card-bordered"
                          style={{ clear: "both" }}
                        >
                          <div className="card-inner">
                            <div className="card-title-group pb-3 g-2">
                              <div className="card-title card-title">
                                <h4 className="title">Executive Summary</h4>
                                <p>
                                  Overall User / System and Revenue Analysis
                                </p>
                              </div>
                            </div>
                            <div className="analytic-ov">
                              <div className="analytic-data-group analytic-ov-group g-3">
                                <div className="col-sm-12 col-lg-3 col-xxl-3">
                                  <div className="nk-order-ovwg-data buy  text-center">
                                    <div className="amount">
                                      {this.state.totalRegistered}{" "}
                                      <small className="currenct currency-usd"></small>
                                    </div>
                                    <div className="info">
                                      Total registered companies{" "}
                                      <strong>
                                        {" "}
                                        <span className="currenct currency-usd"></span>
                                      </strong>
                                    </div>
                                    <div className="title">
                                      <em className="icon ni ni-users"></em>{" "}
                                      Total Registered
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-12 col-lg-3 col-xxl-3">
                                  <div className="nk-order-ovwg-data sell  text-center">
                                    <div className="amount">
                                      {this.state.totalActive}{" "}
                                      <small className="currenct currency-usd"></small>
                                    </div>
                                    <div className="info">
                                      Total active companies{" "}
                                      <strong>
                                        {" "}
                                        <span className="currenct currency-usd"></span>
                                      </strong>
                                    </div>
                                    <div className="title">
                                      <em className="icon ni ni-user-check"></em>{" "}
                                      Total Active
                                    </div>
                                  </div>
                                </div>

                                <div className="col-sm-12 col-lg-3 col-xxl-3">
                                  <div className="nk-order-ovwg-data sell  text-center">
                                    <div className="amount">
                                      ${this.state.paidBills}{" "}
                                      <small className="currenct currency-usd">
                                        USD
                                      </small>
                                    </div>
                                    <div className="info">
                                      Total Commission (Inc. Credit)
                                      <strong>
                                        {" "}
                                        <span className="currenct currency-usd"></span>
                                      </strong>
                                    </div>
                                    <div className="title">
                                      <em className="icon ni ni-sign-usd-alt"></em>{" "}
                                      Total Commission
                                    </div>
                                  </div>
                                </div>

                                <div className="col-sm-12 col-lg-3 col-xxl-3">
                                  <div className="nk-order-ovwg-data buy  text-center">
                                    <div className="amount">
                                      ${this.state.paidBills}{" "}
                                      <small className="currenct currency-usd">
                                        USD
                                      </small>
                                    </div>
                                    <div className="info">
                                      Received
                                      <strong>
                                        {" "}
                                        <span className="currenct currency-usd"></span>
                                      </strong>
                                    </div>
                                    <div className="title">
                                      <em className="icon ni ni-sign-usd-alt"></em>{" "}
                                      Total Commission Received
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div class="nk-block">
                    <div class="row g-gs" style={{ clear: "both" }}>
                      <div class="col-lg-12 col-xxl-12">
                        <div
                          class="card card-bordered"
                          style={{ clear: "both" }}
                        >
                          <div class="card-inner">
                            {shouldRenderCommission && (
                              <CommissionReport from="dashboard" />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <br />
                  <br />
                  {shouldRenderServices && <Services from="dashboard" />}
                </>
              ) : this.state.show_dashboard === "report" ? (
                <iframe
                  src={this.state.iframe_url}
                  height="100%"
                  width="100%"
                  style={{
                    height: "100vh",
                    marginTop: "30px",
                    marginLeft: "-5px",
                    border: "0",
                  }}
                  id="report_iframe"
                ></iframe>
              ) : (
                <div className="card card-bordered" style={{ clear: "both" }}>
                  <div className="card-inner">{tableLoader()}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
