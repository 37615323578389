import { React } from "react";
import { Component } from "react";
import axios from "axios";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  ValidateEmail,
  axiosConfig,
  normalizePhone,
  ValidatePasswordPattern,
} from "../../../config/utility";
import {
  getRfqInitialDetails,
  getRfqEditDetails,
  getAgentServiceDescription,
  addBusinessInformation,
  addFinancialInformation,
  addTradeReferences,
  saveSignature,
  saveChooseSignature,
  configureCreditApplication,
} from "./../../../config/client_api_calls";

import $ from "jquery";

import Questions from "../../../components/Client/RequestForQuotation/questions";
import ExtraDetails from "../../../components/Client/RequestForQuotation/extra";

import DataTableExtended from "../../../components/Tables/DataTableExtended";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { tableLoaderListing } from "../../../components/ContentLoaders/table_loader_listing";
import { McqLoader } from "../../../components/ContentLoaders/newLoaders/Mcq";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  APP_LIVE_URL,
} from "./../../../config/config";
import MutextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";
import html2canvas from "html2canvas";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import Typography from "@material-ui/core/Typography";
import { Editor } from "@tinymce/tinymce-react";

const auth = new HelperClass();

class EditRfq extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
      serviceData: [],
      tableDataBundle: [],
      completeData: [],
      defaultServices: [],
      errorMessage: "",
      serviceId: "",
      successMessage: "",
      errorMessageFinance: "",
      successMessageFinance: "",
      errorMessageTrade: "",
      errorMessageSignatory: "",
      successMessageSignatory: "",
      waitMessage: "",
      tableLoader: true,
      tableLoaderListing: true,
      mcqLoader: true,
      tableLoaderBundle: true,
      errorMessageEmail: "",
      VerifyBusinessButton: false,
      VerifyFinancialButton: false,
      VerifyTradeButton: false,
      verifyDetailsButton: false,
      resendPinButton: false,
      successMessageEmail: "",
      errorMessagePassword: "",
      errorMessageConsent: "",
      errorMessageFinal: "",
      step: 1,
      email: "",
      disabled: false,
      //Business Info
      customer_name: "",
      customer_address1: "",
      customer_address2: "",
      customer_city: "",
      customer_state: "",
      customer_zip: "",
      customer_phone: "",
      duns_number: "",
      //Financial Info
      bank_details: false,
      bank_name: "",
      bank_contact: "",
      bank_address: "",
      bank_contact_phone: "",
      bank_contact_email: "",
      bank_routing_numbers: "",
      bank_account_number: "",
      //Trade Reference
      current_carrier_1: "",
      account_number_1: "",
      current_invoice_value_1: "",
      payment_terms_1: "",
      contact_name_1: "",
      contact_phone_1: "",
      contact_email_1: "",
      current_carrier_2: "",
      account_number_2: "",
      current_invoice_value_2: "",
      payment_terms_2: "",
      contact_name_2: "",
      contact_phone_2: "",
      contact_email_2: "",
      //Signatory Details
      sig_customer_name: "",
      sig_printed_name: "",
      sig_title: "",
      signatureText: "",
      signatureClass: "'Amita', cursive",
      signature_type: "draw",
      rfq_accountno: "",
      signature_image: "",

      rfq_comments: "",
      rfq_comments_error: "",
      rfq_answers: [{}],
      rfq_questions_rows: [],
      step: 1,
      selectedFiles: [],
      file_upload_rows: [{}],
      services_rows: [{}],
      service_grand_total: 0,
      service_grand_sub_total: 0,
      rfq_attachment: "",
      rfq_status: "",
      currentStep: "",
      currentStepTitle: "",
      currentStepDesc: "",
      currentStepIndex: "",
      submitRfq_button_loader: false,
      fileErrors: {},

      uploadsInProgress: {},
      downloadLoading: false,
    };
    this.rfq_questions_rows = [];
    this.rfq_questions_groups = [];
  }

  async componentDidMount() {
    if (
      this.props.props.match.params.rfq_id &&
      this.props.props.match.params.rfq_id !== ""
    ) {
      let credit_id = this.props.props.match.params.rfq_id;
      await this.getRfqAnswers(credit_id);
    }
  }

  getRfqAnswers = async (rfq_accountno) => {
    const saveResponce = await getRfqEditDetails(
      auth.getClientAccount(),
      auth.getClientToken(),
      rfq_accountno
    );
    console.log("getRfqAnswers: ", saveResponce.data);
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "Not Verified"
    ) {
      this.setState({
        mcqLoader: false,
      });
    } else if (saveResponce.data.status === 200) {
      if (
        saveResponce.data.rfqDetails &&
        saveResponce.data.rfqDetails.length > 0
      ) {
        this.setState({
          rfq_comments: saveResponce.data.rfqDetails[0].rfq_comments,
          rfq_attachment: saveResponce.data.rfqDetails[0].rfq_attachement,
          rfq_accountno: rfq_accountno,
          rfq_status: saveResponce.data.rfqDetails[0].status,
        });
      }
      let sharelink_services = [];
      if (
        saveResponce.data.servicesListing &&
        saveResponce.data.servicesListing.length > 0
      ) {
        let all_services = saveResponce.data.servicesListing;
        let selectedServices = saveResponce.data.rfqServices;

        for (let i = 0; i < all_services.length; i++) {
          let service_checked = false;
          let service_quantity = 0;
          let service_unit = "";

          for (let j = 0; j < selectedServices.length; j++) {
            if (all_services[i]["id"] === selectedServices[j].service_id) {
              service_checked = true;
              service_quantity = selectedServices[j].quantity;
              service_unit = selectedServices[j].service_unit;
            }
          }
          let innerArray = {
            service_checked: service_checked,
            service_id: all_services[i]["id"],
            service_title: all_services[i]["service_title"],
            service_price: all_services[i]["rate"],
            service_quantity: service_quantity,
            service_unit: service_unit,
            service_sub_total: all_services[i]["rate"] * 1,
          };
          sharelink_services.push(innerArray);
        }
        this.setState({
          services_rows: sharelink_services,
        });
      }
      let rfq_questions_rows = [];
      if (
        saveResponce.data.rfqQuestionGroupsListing &&
        saveResponce.data.rfqQuestionGroupsListing.length > 0
      ) {
        if (
          saveResponce.data.rfqQuestionsListing &&
          saveResponce.data.rfqQuestionsListing.length > 0
        ) {
          const groupArray = saveResponce.data.rfqQuestionGroupsListing
            .map((item) => ({
              group_id: item.group_id,
              group_title: item.group_title,
              group_desc: item.group_desc,
              group_sequence: item.group_sequence,
            }))
            .sort((a, b) => a.group_sequence - b.group_sequence);
          this.rfq_questions_groups = groupArray;
          let all_questions = saveResponce.data.rfqQuestionsListing;
          for (let i = 0; i < all_questions.length; i++) {
            let innerArray1 = {
              question_id: all_questions[i]["id"],
              question_title: all_questions[i]["question_title"],
              question_type: all_questions[i]["question_type"],
              answer_id: all_questions[i]["answer_id"],
              all_answers: all_questions[i]["question_options"],
              selected_answer: all_questions[i]["answer_id"],
            };
            if (
              [
                "checkbox",
                "radio",
                "dropdown",
                "fileinput",
                "simple_text",
              ].includes(innerArray1.question_type)
            ) {
              innerArray1.question_answered =
                all_questions[i]["question_options"] &&
                all_questions[i]["question_options"].length > 0
                  ? true
                  : false;
            } else {
              innerArray1.question_answered =
                all_questions[i]["answer_id"] &&
                all_questions[i]["answer_id"] !== ""
                  ? true
                  : false;
            }
            let dynamicPropertyName =
              "selected_answer_" + all_questions[i]["id"];

            innerArray1[dynamicPropertyName] =
              all_questions[i]["question_options"] &&
              all_questions[i]["question_options"].length > 0 &&
              all_questions[i]["question_type"] === "radio"
                ? Number(all_questions[i]["answer_id"])
                : all_questions[i]["question_options"] &&
                  all_questions[i]["question_options"].length > 0 &&
                  all_questions[i]["question_type"] === "checkbox" &&
                  all_questions[i]["answer_id"]
                ? JSON.parse(all_questions[i]["answer_id"])
                : all_questions[i]["answer_id"];
            innerArray1["field_name"] = dynamicPropertyName;
            innerArray1["isMandatory"] = all_questions[i]["isMandatory"];
            innerArray1["group_id"] = all_questions[i]["group_id"];
            if (all_questions[i]["question_type"] === "fileinput") {
              innerArray1.selected_answer = all_questions[i]["answer_id"];
            }
            rfq_questions_rows.push(innerArray1);
          }
          //console.log("my rows for initialize ", rfq_questions_rows );
        }
      }
      this.rfq_questions_rows = rfq_questions_rows;
      if (this.rfq_questions_rows.length === 0) {
        this.nextStep("extra", "Additional Information", "", "1");
      } else {
        this.nextStep(
          this.rfq_questions_groups[0].group_id,
          this.rfq_questions_groups[0].group_title,
          this.rfq_questions_groups[0].group_desc,
          "1"
        );
      }
      this.setState({
        mcqLoader: false,
        rfq_questions_rows: rfq_questions_rows,
      });
    } else {
      this.setState({
        mcqLoader: false,
      });
    }
  };

  handleFileChangeFun = (questionId, event) => {
    this.setState((prevState) => ({
      fileErrors: {
        ...prevState.fileErrors,
        [questionId]: "",
      },
      uploadsInProgress: {
        ...prevState.uploadsInProgress,
        [questionId]: true,
      },
    }));

    if (event.target && event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const allowedExtensions = [
        "png",
        "jpg",
        "jpeg",
        "doc",
        "docx",
        "pdf",
        "csv",
        "xlsx",
        "xls",
      ];
      const maxSize = 50 * 1024 * 1024;

      if (!allowedExtensions.includes(fileExtension)) {
        this.showFileError(
          questionId,
          "File type not supported. Please upload a supported file format."
        );
        // Reset the input
        event.target.value = "";
        this.setState((prevState) => ({
          uploadsInProgress: {
            ...prevState.uploadsInProgress,
            [questionId]: false,
          },
        }));
        return;
      }

      if (file.size > maxSize) {
        this.showFileError(
          questionId,
          "File size exceeds 50MB limit. Please upload a smaller file."
        );
        // Reset the input
        event.target.value = "";
        this.setState((prevState) => ({
          uploadsInProgress: {
            ...prevState.uploadsInProgress,
            [questionId]: false,
          },
        }));
        return;
      }

      if (file) {
        const formData = new FormData();
        formData.append("file", file);

        // Store the filename before upload
        let rfq_questions_rows = [...this.state.rfq_questions_rows];
        rfq_questions_rows.forEach((q) => {
          if (q.question_id === questionId) {
            q.selected_filename = file.name;
          }
        });
        this.setState({ rfq_questions_rows });

        // Reset the input value right after setting the filename
        event.target.value = "";

        axios
          .post(
            `${process.env.REACT_APP_API_URL}rfqs/rfq_file_response`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            const fileUrl = response.data.default;
            rfq_questions_rows.forEach((q) => {
              if (q.question_id === questionId) {
                q.selected_answer = fileUrl;
                q[`selected_answer_${q.question_id}`] = fileUrl;
                q.question_answered = !!fileUrl;
              }
            });
            this.setState((prevState) => ({
              rfq_questions_rows,
              uploadsInProgress: {
                ...prevState.uploadsInProgress,
                [questionId]: false,
              },
            }));
          })
          .catch((error) => {
            console.error("File upload error:", error);
            rfq_questions_rows.forEach((q) => {
              if (q.question_id === questionId) {
                delete q.selected_filename;
              }
            });
            this.setState((prevState) => ({
              rfq_questions_rows,
              fileErrors: {
                ...prevState.fileErrors,
                [questionId]: "Error uploading file. Please try again.",
              },
              uploadsInProgress: {
                ...prevState.uploadsInProgress,
                [questionId]: false,
              },
            }));
          });
      }
    } else {
      console.error("No file selected.");
      this.setState((prevState) => ({
        uploadsInProgress: {
          ...prevState.uploadsInProgress,
          [questionId]: false,
        },
      }));
    }
  };

  areUploadsInProgress = () => {
    return Object.values(this.state.uploadsInProgress).some(
      (isUploading) => isUploading
    );
  };

  showFileError = (questionId, errorMessage) => {
    this.setState((prevState) => ({
      fileErrors: { ...prevState.fileErrors, [questionId]: errorMessage },
    }));

    setTimeout(() => {
      this.setState((prevState) => {
        let newFileErrors = { ...prevState.fileErrors };
        delete newFileErrors[questionId]; // Remove error after 5 seconds
        return { fileErrors: newFileErrors };
      });
    }, 5000);
  };

  downloadPdf = async (d_f_name) => {
    //var url = d_f_name;
    let file_name = "RFQ-" + this.getFileNameFromUrl(url);
    console.log(file_name);
    if (d_f_name && d_f_name !== "") {
      //let file_name = d_f_name;
      var url = d_f_name;
      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = file_name;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          this.setState({
            errorMessage:
              "There is some error while downloading the attachment.",
          });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 4000);
          // Handle any errors that occurred during the fetch
          console.error("Fetch error:", error);
        });
    } else {
      this.setState({
        errorMessage: "Attachement not found.",
      });
      setTimeout(() => {
        this.setState({ errorMessage: "" });
      }, 4000);
    }
  };

  getFileNameFromUrlFront = (url) => {
    try {
      // Create a URL object and extract the pathname
      const parsedUrl = new URL(url);
      const pathname = parsedUrl.pathname;
      // Extract the file name from the pathname
      return pathname.substring(pathname.lastIndexOf("/") + 1);
    } catch (error) {
      console.error("Invalid URL:", error);
      return url;
    }
  };

  getFileNameFromUrl = (url) => {
    try {
      const urlObject = new URL(url);
      // Using the pathname for simplicity; adjust as needed based on your URL structure
      const fileName = urlObject.pathname.split("/").pop();
      return fileName;
    } catch (error) {
      console.error("Error extracting file name:", error.message);
      return this.state.rfq_accountno;
    }
  };

  showServiceDescription = async (service_id) => {
    this.setState({
      errorMessageModal: "",
      successMessageModal: "",
    });
    const servicesResponce = await getAgentServiceDescription(
      auth.getClientAccount(),
      auth.getClientToken(),
      service_id
    );

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessageModal:
          "There is some error while getting the service data.",
        successMessageModal: "",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        serviceData: servicesResponce.data.data,
        tableLoader: false,
        tableLoaderListing: false,
        errorMessageModal: "",
      });
    } else {
      this.setState({
        errorMessageModal:
          "There is some error while getting the service data.",
        successMessageModal: "",
      });
    }
    window.$("#modalFormDetailDescription").modal("show");
  };

  stopAndShowBundle = async () => {
    window.$("#modalFormDetailDescription").modal("hide");
  };

  fieldValidationUsername = () => {
    let { rfq_comments } = this.state;

    if (rfq_comments === "") {
      this.setState({
        errorMessage: "Customer requirements are required.",
        VerifyBusinessButton: false,
      });
      document
        .getElementById("rfq_Block")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return false;
    } else {
      this.setState({
        errorMessage: "",
        disabled: true,
      });
    }
    return true;
  };

  submitRfq = async () => {
    //e.preventDefault();
    let {
      rfq_comments,
      add_picture,
      add_picture_name,
      rfq_questions_rows,
      services_rows,
      rfq_accountno,
      rfq_attachment,
      rfq_status,
    } = this.state;

    this.setState({
      verifyPasswordButton: true,
      submitRfq_button_loader: true,
    });
    if (this.fieldValidationUsername() === true) {
      const formData = new FormData();
      formData.append("token", process.env.REACT_APP_API_TOKEN);
      formData.append("api_accountno", process.env.REACT_APP_API_ACCOUNTNO);
      formData.append("accountno", auth.getClientAccount());
      formData.append("file", add_picture);
      formData.append("fileName", add_picture_name);
      formData.append("rfq_comments", rfq_comments);
      formData.append("rfq_accountno", rfq_accountno);
      formData.append("rfq_attachment", rfq_attachment);
      formData.append("rfq_status", rfq_status);
      formData.append("rfq_questions_rows", JSON.stringify(rfq_questions_rows));
      formData.append("services_rows", JSON.stringify(services_rows));

      try {
        axios
          .post(
            process.env.REACT_APP_API_URL + "rfqs/update_rfq",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Credentials": true,
              },
            },
            {}
          )
          .then((response) => {
            console.log("update_rfq: ", response.data);
            if (
              response.data.status === 404 &&
              response.data.message === "no_record"
            ) {
              this.setState({
                errorMessage: "Client does not found.",
                disabled: false,
                verifyPasswordButton: false,
                successMessage: "",
              });
              document
                .getElementById("rfq_Block")
                ?.scrollIntoView({ top: 0, behavior: "smooth" });
              setTimeout(() => {
                this.setState({
                  errorMessage: "",
                });
              }, 4000);
            } else if (response.data.status === 403) {
              this.setState({
                errorMessage: response.data.message,
                disabled: false,
                verifyPasswordButton: false,
                successMessage: "",
              });
              document
                .getElementById("rfq_Block")
                ?.scrollIntoView({ top: 0, behavior: "smooth" });
              setTimeout(() => {
                this.setState({
                  errorMessage: "",
                });
              }, 4000);
            } else if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                successMessage:
                  "Your request for quotation is Updated successfully.",
                errorMessage: "",
                //final_login_url: response.data.login_url,
                showLoginButton: true,
              });
              document
                .getElementById("rfq_Block")
                ?.scrollIntoView({ top: 0, behavior: "smooth" });
              setTimeout(() => {
                window.location.replace(
                  "/" + auth.getResellerRegToken() + "/client/Rfqs"
                );
              }, 3000);

              //this.nextStep();
            } else {
              this.setState({
                errorMessage:
                  "There is some error while Updating the request for quotation.",
                disabled: false,
                successMessage: "",
                verifyPasswordButton: false,
              });
              document
                .getElementById("rfq_Block")
                ?.scrollIntoView({ top: 0, behavior: "smooth" });
              setTimeout(() => {
                this.setState({
                  errorMessage: "",
                });
              }, 4000);
            }
            this.setState({
              submitRfq_button_loader: false,
            });
          });
      } catch (error) {
        this.setState({
          errorMessage:
            "There is some error while submitting the request for quotation.",
          disabled: false,
          successMessage: "",
          verifyPasswordButton: false,
        });
        document
          .getElementById("rfq_Block")
          ?.scrollIntoView({ top: 0, behavior: "smooth" });
        setTimeout(() => {
          this.setState({
            errorMessage: "",
          });
        }, 4000);
      }
    }
  };

  // handleChangeRfq = (idx) => (e) => {
  //   const { name, value } = e.target;
  //   console.log("name: ",name,", value: ",value);
  //   this.setState(prevState => {
  //     const updatedRfqQuestionsRows = prevState.rfq_questions_rows.map(row => {
  //       // Clone the row object to avoid mutating the original state
  //       const updatedRow = { ...row };

  //       // Check if the current row contains the selected key
  //       if (name in updatedRow) {
  //         // Update the value if it's not empty

  //           updatedRow[name] = value;
  //           updatedRow.answer_id = value;
  //           if(value !== ""){
  //             updatedRow.question_answered = true;
  //           }else{
  //             updatedRow.question_answered = false;
  //           }

  //       }
  //       return updatedRow;
  //     });
  //     console.log("updatedRfqQuestionsRows",updatedRfqQuestionsRows);
  //     return { rfq_questions_rows: updatedRfqQuestionsRows };
  //   });
  // };

  handleChangeRfq = (idx) => (e) => {
    let rfq_questions_rows = this.state.rfq_questions_rows;
    const { name, value } = e.target;
    let selected_key = name;

    if (rfq_questions_rows && rfq_questions_rows.length > 0) {
      for (let i = 0; i < rfq_questions_rows.length; i++) {
        var mm = Object.entries(rfq_questions_rows[i]);

        if (selected_key in rfq_questions_rows[i]) {
          rfq_questions_rows[i][selected_key] = value;
          rfq_questions_rows[i].answer_id = value;
          if (value !== "") {
            rfq_questions_rows[i].question_answered = true;
          } else {
            rfq_questions_rows[i].question_answered = false;
          }
        }
      }
    }
    this.rfq_questions_rows = rfq_questions_rows;
    this.setState({
      rfq_questions_rows: rfq_questions_rows,
    });
    //
  };

  handleChangeRfqCheckboxes = (idx) => (e) => {
    const { target } = e;
    const value = target.type === "checkbox" ? target.value : target.checked;
    const { name } = target;

    let rfq_questions_rows = this.rfq_questions_rows;

    if (rfq_questions_rows && rfq_questions_rows.length > 0) {
      for (let i = 0; i < rfq_questions_rows.length; i++) {
        if (name in rfq_questions_rows[i]) {
          const selected_answers = rfq_questions_rows[i][name];
          const newValue = Number(value);

          if (target.checked && !selected_answers.includes(newValue)) {
            rfq_questions_rows[i][name] = [...selected_answers, newValue];
          } else if (!target.checked && selected_answers.includes(newValue)) {
            rfq_questions_rows[i][name] = selected_answers.filter(
              (item) => item !== newValue
            );
          }

          rfq_questions_rows[i].question_answered =
            rfq_questions_rows[i][name].length > 0;
        }
      }
    }

    this.rfq_questions_rows = rfq_questions_rows;
    this.setState({
      rfq_questions_rows: rfq_questions_rows,
    });
  };

  handleEditorChange = (content, editor) => {
    this.setState({
      rfq_comments: content,
    });
    //console.log("Content was updated:", content);
  };

  handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      return; // No file selected
    }

    // File type validation selectedFiles
    const allowedFileTypes = [
      ".png",
      ".jpg",
      ".jpeg",
      ".pdf",
      ".doc",
      ".docx",
      ".csv",
      ".xlxs",
      ".xls",
    ];
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

    if (!allowedFileTypes.includes(`.${fileExtension}`)) {
      this.setState({
        docErrorMessage:
          "Invalid file type. Please select a valid file format.",
      });
      return;
    }

    // File size validation (e.g., limit to 50MB)
    const maxSizeBytes = 50 * 1024 * 1024; // 50MB
    if (selectedFile.size > maxSizeBytes) {
      this.setState({
        docErrorMessage:
          "File size exceeds the limit (50MB). Please choose a smaller file.",
      });
      return;
    }
    this.setState({ uploadStatus: "uploading" });
    // Clear any previous errors
    setTimeout(() => {
      this.setState({
        add_picture: selectedFile,
        add_picture_name: selectedFile.name,
        docErrorMessage: "",
        uploadStatus: "done", // Mark upload as complete
      });
    }, 1500);

    //return true;
  };

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  addNewService = async () => {
    const { select_service, services_rows } = this.state;
    if (select_service !== "") {
      if (services_rows && services_rows.length > 0) {
        for (let i = 0; i < services_rows.length; i++) {
          if (services_rows[i].service_id == select_service) {
            services_rows[i].service_checked = true;
          }
        }
      }
      this.setState({
        services_rows: services_rows,
        select_service: "",
      });
    }
  };

  removeService = async (remove_service) => {
    const { services_rows } = this.state;

    if (services_rows && services_rows.length > 0) {
      for (let i = 0; i < services_rows.length; i++) {
        if (services_rows[i].service_id == remove_service) {
          services_rows[i].service_checked = false;
        }
      }
    }
    this.setState({
      services_rows: services_rows,
      remove_service: "",
    });
  };

  handleChangeServices = (idx) => (e) => {
    const { name, value } = e.target;
    const services_rows = [...this.state.services_rows];
    let sub_total = 0;
    if (services_rows[idx]) {
      if (name === "service_quantity") {
        sub_total = services_rows[idx].service_price * parseInt(value);
        services_rows[idx].service_sub_total = sub_total;
      }
      services_rows[idx][name] = value;
    } else {
      this.setState((prevState) => {
        const services_rows = [...prevState.services_rows];
        if (services_rows[idx]) {
          services_rows[idx][name] = value;
        }
      });
    }

    this.setState({
      services_rows,
      isBlocking: false,
    });
    if (name === "service_quantity") {
      this.calculateTotalRate();
    }
    //
  };

  calculateTotalRate = async () => {
    const { services_rows, discount_value_total } = this.state;

    let total = 0;

    if (services_rows && services_rows.length > 0) {
      for (let i = 0; i < services_rows.length; i++) {
        if (services_rows[i].service_checked === true) {
          total =
            total +
            parseFloat(services_rows[i].service_price) *
              parseInt(services_rows[i].service_quantity);
        }
      }
    }
    let with_discount = total - parseFloat(discount_value_total);
    //Calculate Tax
    let tax_value_total = this.state.tax_value_total;
    with_discount = with_discount + parseFloat(tax_value_total);
    //Calculate Shipping
    let shipping_value_total = this.state.shipping_value_total;
    with_discount = with_discount + parseFloat(shipping_value_total);

    //Calculate Shipping and Discount

    //with_discount = with_discount + total_tax;
    console.table({
      with_discount_shipping_tax: with_discount,
      discount_value_total: discount_value_total,
      tax_value_total: tax_value_total,
      shipping_value_total: shipping_value_total,
    });

    this.setState({
      service_grand_total:
        with_discount && with_discount > 0 ? with_discount.toFixed(2) : "0.00",
      service_grand_sub_total: total && total > 0 ? total.toFixed(2) : "0.00",
    });
  };

  updateSignatureType = (type) => {
    this.setState({
      signature_type: type,
    });
  };

  nextStep = (step_id, step_title, step_desc, step_index) => {
    const errorelements = document.querySelectorAll(".field_errorMsg");
    errorelements.forEach((element) => {
      element.remove();
    });
    this.setState({
      currentStep: step_id,
      currentStepTitle: step_title,
      currentStepDesc: step_desc,
      currentStepIndex: step_index,
    });
  };

  prevStep = () => {
    let step_id;
    let step_title;
    let step_desc;
    let step_index;
    if (this.state.currentStep === "extra") {
      step_id =
        this.rfq_questions_groups[this.rfq_questions_groups.length - 1]
          .group_id;
      step_title =
        this.rfq_questions_groups[this.rfq_questions_groups.length - 1]
          .group_title;
      step_desc =
        this.rfq_questions_groups[this.rfq_questions_groups.length - 1]
          .group_desc;
      step_index = this.rfq_questions_groups.length;
    } else {
      const currentIndex = this.rfq_questions_groups.findIndex(
        (group) => group.group_id === this.state.currentStep
      );
      if (currentIndex > 0) {
        step_id = this.rfq_questions_groups[currentIndex - 1].group_id;
        step_title = this.rfq_questions_groups[currentIndex - 1].group_title;
        step_desc = this.rfq_questions_groups[currentIndex - 1].group_desc;
        step_index = Number(this.state.currentStepIndex) - 1;
      } else {
        return;
      }
    }

    const errorelements = document.querySelectorAll(".field_errorMsg");
    errorelements.forEach((element) => {
      element.remove();
    });
    this.setState({
      currentStep: step_id,
      currentStepTitle: step_title,
      currentStepDesc: step_desc,
      currentStepIndex: step_index,
    });
  };

  firstStep = () => {
    this.setState({
      step: 1,
    });
  };

  openServices = () => {
    window.$("#modalFormServices").modal("show");
  };

  closeServices = () => {
    window.$("#modalFormServices").modal("hide");
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  submitGroup = async () => {
    const { rfq_answers } = this.state;
    let rfq_questions_rows = this.rfq_questions_rows;
    let currentStep = this.state.currentStep;
    var is_validated = true;
    const errorelements = document.querySelectorAll(".field_errorMsg");
    errorelements.forEach((element) => {
      element.remove();
    });
    if (rfq_questions_rows && rfq_questions_rows.length > 0) {
      for (let i = 0; i < rfq_questions_rows.length; i++) {
        if (rfq_questions_rows[i].question_type === "fileinput") {
          rfq_questions_rows[i].question_answered =
            !!rfq_questions_rows[i].selected_answer;
        }

        if (rfq_questions_rows[i].question_type === "label") {
          rfq_questions_rows[i].question_answered =
            rfq_questions_rows[i].selected_answer?.trim() !== "";
        }

        if (
          rfq_questions_rows[i].question_type === "simple_text" &&
          rfq_questions_rows[i].all_answers &&
          rfq_questions_rows[i].all_answers.length > 0
        ) {
          const dynamicPropertyName = `selected_answer_${rfq_questions_rows[i].question_id}`;
          const cleanText = rfq_questions_rows[
            i
          ].all_answers[0].question_options
            .replace(/<[^>]*>/g, "")
            .replace(/\s+/g, " ")
            .trim();

          rfq_questions_rows[i][dynamicPropertyName] = cleanText;
          rfq_questions_rows[i].selected_answer = cleanText;
          rfq_questions_rows[i].question_answered = true;
        }
        if (rfq_questions_rows[i].question_type === "radio") {
          const radioOptions = document.getElementsByName(
            rfq_questions_rows[i].field_name
          );
          rfq_questions_rows[i].question_answered = Array.from(
            radioOptions
          ).some((radio) => radio.checked);
        }
        if (
          rfq_questions_rows[i].question_answered === false &&
          rfq_questions_rows[i].isMandatory === 1 &&
          rfq_questions_rows[i].group_id === currentStep
        ) {
          is_validated = false;
          var fieldName = rfq_questions_rows[i].field_name;

          var elements = document.getElementsByName(fieldName);
          if (elements.length > 0) {
            var element = elements[0];
            if (element) {
              var el_id = element.id;
              var el_type = element.type;
              let colElement =
                element.parentElement.parentElement.parentElement;
              if (
                el_type === "checkbox" ||
                el_type === "radio" ||
                el_type === "select-one"
              ) {
                colElement = colElement.parentElement;
              }
              const formGroupElement = colElement.querySelector(".form-group");
              if (formGroupElement) {
                formGroupElement.insertAdjacentHTML(
                  "afterend",
                  '<p class="field_errorMsg" style="color: red;font-size: 12px; margin-top: -20px;">* This field is mandatory</p>'
                );
                setTimeout(() => {
                  const errorMsg = formGroupElement.nextElementSibling;
                  if (
                    errorMsg &&
                    errorMsg.classList.contains("field_errorMsg")
                  ) {
                    errorMsg.remove();
                  }
                }, 4000);
              }
            }
          }
        }
      }
    }

    if (is_validated) {
      //this.setState({rfq_questions_rows: rfq_questions_rows});
      const lastGroupId =
        this.rfq_questions_groups[this.rfq_questions_groups.length - 1]
          .group_id;
      const currentIndex = this.rfq_questions_groups.findIndex(
        (group) => group.group_id === this.state.currentStep
      );

      if (
        currentIndex !== -1 &&
        currentIndex === this.rfq_questions_groups.length - 1
      ) {
        this.nextStep(
          "extra",
          "Additional Information",
          "",
          this.rfq_questions_groups.length + 1
        );
      } else {
        const nextGroupId =
          this.rfq_questions_groups[currentIndex + 1].group_id;
        const nextGroupTitle =
          this.rfq_questions_groups[currentIndex + 1].group_title;
        const nextGroupDesc =
          this.rfq_questions_groups[currentIndex + 1].group_desc;
        this.nextStep(
          nextGroupId,
          nextGroupTitle,
          nextGroupDesc,
          Number(this.state.currentStepIndex) + 1
        );
      }
      document
        .getElementById("start_container")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
    } else {
      document
        .querySelectorAll(".field_errorMsg")[0]
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      this.setState({
        errorMessage: "Please answer all the questions.",
        errorMessageTitle: "Missing Information",
      });
      setTimeout(() => {
        this.setState({
          errorMessage: "",
          errorMessageTitle: "Error",
        });
      }, 4000);
    }
  };

  handleChangeRfqDropdown = (idx, groupId) => (e) => {
    let rfq_questions_rows = [...this.state.rfq_questions_rows];
    const { name, value } = e.target;

    if (rfq_questions_rows.length > 0) {
      for (let i = 0; i < rfq_questions_rows.length; i++) {
        if (
          rfq_questions_rows[i].group_id === groupId &&
          rfq_questions_rows[i].question_id === parseInt(name.split("_").pop())
        ) {
          rfq_questions_rows[i][
            `selected_answer_${rfq_questions_rows[i].question_id}`
          ] = value;
          rfq_questions_rows[i].question_answered = value !== "";
        }
      }
    }

    this.setState({ rfq_questions_rows });
  };

  downloadPdfRFQ = async (d_f_name) => {
    var url = d_f_name;
    url = APP_LIVE_URL + "files_data/rfq/" + url;
    const file_name = "RFQ-" + d_f_name.split("/").pop();
    this.setState({
      downloadLoading: true,
    });
    fetch(url)
      .then((x) => x.blob())
      .then((b) => {
        const url = window.URL.createObjectURL(b);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
        this.setState({
          errorMessage: "",
          downloadingIndex: null,
          downloadLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          errorMessage: "There is some error while downloading the attachment.",
          downloadingIndex: null,
          downloadLoading: false,
        });
        setTimeout(() => {
          this.setState({ errorMessage: "", downloadLoading: false });
        }, 4000);
        console.error("Fetch error:", error);
      });
  };

  handleFileRemove = (questionId) => {
    this.setState((prevState) => {
      let rfq_questions_rows = [...prevState.rfq_questions_rows];

      rfq_questions_rows.forEach((q) => {
        if (q.question_id === questionId) {
          q.selected_answer = "";
          q[`selected_answer_${q.question_id}`] = "";
          q.selected_filename = "";
          q.question_answered = false;
        }
      });

      return {
        rfq_questions_rows,
      };
    });
  };

  handleFileRemoveFun = () => {
    this.setState({
      add_picture: null,
      add_picture_name: "",
      uploadStatus: "",
      downloadStatus: "",
    });

    document.getElementById("add_picture").value = "";
  };

  handleDownload = () => {
    if (!this.state.add_picture) return;

    this.setState({ downloadStatus: "downloading" });

    setTimeout(() => {
      const url = URL.createObjectURL(this.state.add_picture);
      const a = document.createElement("a");
      a.href = url;
      a.download = this.state.add_picture_name; // Ensures actual download
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

      this.setState({ downloadStatus: "done" });
    }, 1500); // Simulating download delay
  };

  render() {
    //$(".nk-block .MUIDataTable-paper-2").css("padding", "10px");
    return (
      <div className="nk-content rfq_Block" id="rfq_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        color="inherit"
                        onClick={() =>
                          this.addMenuHistory(
                            "/" + auth.getResellerRegToken() + "/client/"
                          )
                        }
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <HomeIcon fontSize="inherit" className="" />
                        Home
                      </Link>
                      <Link
                        color="inherit"
                        onClick={() =>
                          this.addMenuHistory(
                            "/" + auth.getResellerRegToken() + "/client/Rfqs"
                          )
                        }
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        RFQ's
                      </Link>

                      <Typography color="textPrimary" className="">
                        Edit RFQ
                      </Typography>
                    </Breadcrumbs>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        {/*<em className="icon ni ni-more-v"></em>*/}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      <div className="card card-bordered">
                        <div className="card-inner">
                          <h3 className="image-side-txt">
                            {" "}
                            Request For Quotation
                          </h3>
                          <p
                            style={{
                              color: "rgb(99, 115, 129)",
                              marginTop: "25px",
                            }}
                            className="rfq_description"
                          >
                            {" "}
                            A Request for Quotation (RFQ) is a document or
                            formal process used in business and procurement to
                            solicit price quotes from potential suppliers or
                            vendors. It typically includes specific details
                            about the products or services required, the
                            quantity, quality standards, and delivery terms. The
                            primary purpose of an RFQ is to gather competitive
                            price proposals from multiple suppliers, enabling
                            organizations to make informed decisions and select
                            the most cost-effective solution that meets their
                            needs. Once the RFQ responses are received,
                            organizations can compare offers and negotiate terms
                            before making a purchasing decision. RFQs play a
                            crucial role in procurement, helping businesses save
                            costs and ensure they receive the best value for
                            their investments.
                          </p>
                          {this.state.errorMessage !== "" ? (
                            <div
                              className="example-alert example-alert-revoke mt-3 mb-3"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.successMessage !== "" ? (
                            <div
                              className="example-alert  mt-3 mb-3"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.successMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.mcqLoader === true ? (
                            McqLoader()
                          ) : (
                            <>
                              <div className="nk-block">
                                <div className="card card-bordered">
                                  <div className="nk-kycfm">
                                    <div
                                      className="nk-kycfm-head"
                                      style={{ background: "#f5f6fa" }}
                                    >
                                      <div className="nk-kycfm-count">
                                        {this.state.currentStepIndex}
                                      </div>
                                      <div className="nk-kycfm-title">
                                        <h5 className="title">
                                          {this.state.currentStepTitle}
                                        </h5>
                                        <p className="sub-title">
                                          {this.state.currentStepDesc}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="nk-kycfm-content">
                                      {this.state.currentStep !== "" &&
                                      this.state.currentStep !== "extra" ? (
                                        <>
                                          {this.rfq_questions_groups &&
                                          this.rfq_questions_groups.length >
                                            0 ? (
                                            <>
                                              {this.state.rfq_questions_rows &&
                                              this.state.rfq_questions_rows
                                                .length > 0 ? (
                                                <>
                                                  <div className="nk-stepper-step active">
                                                    {/* <h5 className="title mb-3 mt-5">{this.state.currentStepTitle}</h5>
                                                    <p className="title mb-3">{this.state.currentStepDesc}</p> */}
                                                    <div className="row g-gs">
                                                      {this.state.rfq_questions_rows
                                                        .filter(
                                                          (rfq) =>
                                                            rfq.group_id ===
                                                            this.state
                                                              .currentStep
                                                        )
                                                        .map((rfq, bid) => (
                                                          <div
                                                            className={
                                                              rfq.question_type ===
                                                                "label" ||
                                                              rfq.question_type ===
                                                                "simple_text"
                                                                ? "col-12"
                                                                : "col-sm-6 mt-3"
                                                            }
                                                            key={`question_${bid}`}
                                                          >
                                                            {rfq.question_type ===
                                                            "label" ? (
                                                              <div className="form-group">
                                                                <h6>
                                                                  {
                                                                    rfq.question_title
                                                                  }
                                                                </h6>
                                                              </div>
                                                            ) : rfq.question_type ===
                                                              "simple_text" ? (
                                                              <div className="form-group">
                                                                <label className="form-label">
                                                                  {
                                                                    rfq.question_title
                                                                  }
                                                                </label>
                                                              </div>
                                                            ) : (
                                                              <div className="form-group">
                                                                <label className="form-label">
                                                                  {bid + 1}.{" "}
                                                                  {
                                                                    rfq.question_title
                                                                  }
                                                                  {rfq.isMandatory ===
                                                                    1 && (
                                                                    <sup className="text-danger">
                                                                      *
                                                                    </sup>
                                                                  )}
                                                                </label>
                                                              </div>
                                                            )}
                                                            {rfq.question_type ===
                                                            "radio" ? (
                                                              <>
                                                                {rfq.all_answers &&
                                                                rfq.all_answers
                                                                  .length >
                                                                  0 ? (
                                                                  <div className="form-pm-group">
                                                                    <ul className="buysell-pm-list">
                                                                      {rfq.all_answers.map(
                                                                        (
                                                                          rfq_question,
                                                                          rid
                                                                        ) => (
                                                                          <li
                                                                            className="buysell-pm-item"
                                                                            key={`options_${rid}`}
                                                                          >
                                                                            <input
                                                                              //className="buysell-pm-control"
                                                                              className={`buysell-pm-control ${
                                                                                rfq.isMandatory ===
                                                                                1
                                                                                  ? "answer_required"
                                                                                  : ""
                                                                              }`}
                                                                              type="radio"
                                                                              name={`selected_answer_${rfq.question_id}`}
                                                                              id={`question_${rid}_${bid}`}
                                                                              onChange={this.handleChangeRfq(
                                                                                rid
                                                                              )}
                                                                              checked={
                                                                                rfq_question.id ===
                                                                                Number(
                                                                                  rfq[
                                                                                    `selected_answer_${rfq.question_id}`
                                                                                  ]
                                                                                )
                                                                                  ? true
                                                                                  : false
                                                                              }
                                                                              value={
                                                                                rfq_question.id
                                                                              }
                                                                            />
                                                                            <label
                                                                              className="buysell-pm-label"
                                                                              htmlFor={`question_${rid}_${bid}`}
                                                                            >
                                                                              <span className="pm-name">
                                                                                {
                                                                                  rfq_question.question_options
                                                                                }
                                                                              </span>
                                                                              <span className="pm-icon">
                                                                                {/*<em className="icon ni ni-question"></em>*/}
                                                                              </span>
                                                                            </label>
                                                                          </li>
                                                                        )
                                                                      )}
                                                                    </ul>
                                                                  </div>
                                                                ) : null}
                                                              </>
                                                            ) : rfq.question_type ===
                                                              "checkbox" ? (
                                                              <>
                                                                {rfq.all_answers &&
                                                                rfq.all_answers
                                                                  .length >
                                                                  0 ? (
                                                                  <div className="form-pm-group">
                                                                    <ul className="buysell-pm-list">
                                                                      {rfq.all_answers.map(
                                                                        (
                                                                          rfq_question,
                                                                          cid
                                                                        ) => (
                                                                          <li
                                                                            className="buysell-pm-item ourCheckbox"
                                                                            key={`options_${cid}`}
                                                                          >
                                                                            <input
                                                                              type="checkbox"
                                                                              className={`buysell-pm-control ${
                                                                                rfq.isMandatory ===
                                                                                1
                                                                                  ? "answer_required"
                                                                                  : ""
                                                                              }`}
                                                                              //className="buysell-pm-control"
                                                                              name={`selected_answer_${rfq.question_id}`}
                                                                              onChange={this.handleChangeRfqCheckboxes(
                                                                                cid
                                                                              )}
                                                                              id={`question_${cid}_${bid}`}
                                                                              checked={
                                                                                rfq[
                                                                                  `selected_answer_${rfq.question_id}`
                                                                                ] &&
                                                                                Array.isArray(
                                                                                  rfq[
                                                                                    `selected_answer_${rfq.question_id}`
                                                                                  ]
                                                                                ) &&
                                                                                rfq[
                                                                                  `selected_answer_${rfq.question_id}`
                                                                                ].includes(
                                                                                  rfq_question.id
                                                                                )
                                                                              }
                                                                              value={
                                                                                rfq_question.id
                                                                              }
                                                                            />
                                                                            <label
                                                                              className="buysell-pm-label"
                                                                              htmlFor={`question_${cid}_${bid}`}
                                                                            >
                                                                              <span className="pm-name">
                                                                                {
                                                                                  rfq_question.question_options
                                                                                }
                                                                              </span>
                                                                              <span className="pm-icon">
                                                                                {/*<em className="icon ni ni-question"></em>*/}
                                                                              </span>
                                                                            </label>
                                                                          </li>
                                                                        )
                                                                      )}
                                                                    </ul>
                                                                  </div>
                                                                ) : null}
                                                              </>
                                                            ) : rfq.question_type ===
                                                              "textfield" ? (
                                                              <>
                                                                <div className="form-group">
                                                                  <div className="form-control-wrap">
                                                                    <input
                                                                      id={`question_0_${bid}`}
                                                                      className={`form-control form-control-lg ${
                                                                        rfq.isMandatory ===
                                                                        1
                                                                          ? "answer_required"
                                                                          : ""
                                                                      }`}
                                                                      name={`selected_answer_${rfq.question_id}`}
                                                                      defaultValue={
                                                                        rfq[
                                                                          `selected_answer_${rfq.question_id}`
                                                                        ]
                                                                      }
                                                                      onChange={this.handleChangeRfq(
                                                                        0
                                                                      )}
                                                                      type="text"
                                                                      placeholder=""
                                                                      maxLength={
                                                                        100
                                                                      }
                                                                    />
                                                                  </div>
                                                                </div>
                                                              </>
                                                            ) : rfq.question_type ===
                                                              "textarea" ? (
                                                              <div className="form-group">
                                                                <div className="form-control-wrap">
                                                                  <textarea
                                                                    className={`form-control form-control-lg ${
                                                                      rfq.isMandatory ===
                                                                      1
                                                                        ? "answer_required"
                                                                        : ""
                                                                    }`}
                                                                    id={`question_0_${bid}`}
                                                                    name={`selected_answer_${rfq.question_id}`}
                                                                    onChange={this.handleChangeRfq(
                                                                      0
                                                                    )}
                                                                    defaultValue={
                                                                      rfq[
                                                                        `selected_answer_${rfq.question_id}`
                                                                      ]
                                                                    }
                                                                    placeholder=""
                                                                    maxLength={
                                                                      500
                                                                    }
                                                                  ></textarea>
                                                                </div>
                                                              </div>
                                                            ) : rfq.question_type ===
                                                              "dropdown" ? (
                                                              <>
                                                                <div className="form-group">
                                                                  <div className="form-control-wrap">
                                                                    <div className="form-control-select">
                                                                      <select
                                                                        className={`form-control form-control-lg ${
                                                                          rfq.isMandatory ===
                                                                          1
                                                                            ? "answer_required"
                                                                            : ""
                                                                        }`}
                                                                        id={`question_${rfq.group_id}_${bid}`}
                                                                        name={`selected_answer_${rfq.question_id}`}
                                                                        label="Select an Option"
                                                                        value={
                                                                          rfq[
                                                                            `selected_answer_${rfq.question_id}`
                                                                          ] ||
                                                                          ""
                                                                        }
                                                                        onChange={this.handleChangeRfqDropdown(
                                                                          bid,
                                                                          rfq.group_id
                                                                        )}
                                                                      >
                                                                        <option
                                                                          key="sets100"
                                                                          value=""
                                                                        >
                                                                          Select
                                                                          an
                                                                          option{" "}
                                                                        </option>
                                                                        {rfq.all_answers.map(
                                                                          (
                                                                            rfq_question,
                                                                            rid
                                                                          ) => (
                                                                            <option
                                                                              key={`sets${rid}`}
                                                                              value={
                                                                                rfq_question.id
                                                                              }
                                                                            >
                                                                              {
                                                                                rfq_question.question_options
                                                                              }
                                                                            </option>
                                                                          )
                                                                        )}
                                                                      </select>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </>
                                                            ) : rfq.question_type ===
                                                              "fileinput" ? (
                                                              <>
                                                                <div className="form-group">
                                                                  <div
                                                                    className="form-control-group"
                                                                    id="fileContainer"
                                                                  >
                                                                    {this.state
                                                                      .fileErrors[
                                                                      rfq
                                                                        .question_id
                                                                    ] && (
                                                                      <div
                                                                        style={{
                                                                          color:
                                                                            "red",
                                                                          fontSize:
                                                                            "0.85rem",
                                                                          marginBottom:
                                                                            "8px",
                                                                        }}
                                                                      >
                                                                        {
                                                                          this
                                                                            .state
                                                                            .fileErrors[
                                                                            rfq
                                                                              .question_id
                                                                          ]
                                                                        }
                                                                      </div>
                                                                    )}
                                                                    <div
                                                                      className="selected-file mt-2"
                                                                      style={{
                                                                        position:
                                                                          "absolute",
                                                                        top: "9px",
                                                                        left: "130px",
                                                                      }}
                                                                    >
                                                                      {rfq.selected_filename
                                                                        ? rfq.selected_filename
                                                                        : "No file chosen"}
                                                                    </div>
                                                                    <input
                                                                      type="file"
                                                                      id={`add_picture_${rfq.question_id}`}
                                                                      className="file-block-rfq"
                                                                      name="add_picture"
                                                                      accept=".png, .jpg, .jpeg, .pdf, .doc, .docx, .xls, .xlsx, .csv"
                                                                      onChange={(
                                                                        e
                                                                      ) =>
                                                                        this.handleFileChangeFun(
                                                                          rfq.question_id,
                                                                          e
                                                                        )
                                                                      }
                                                                      value=""
                                                                      style={{
                                                                        color:
                                                                          "transparent",
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                    />
                                                                    {this.state
                                                                      .uploadsInProgress &&
                                                                    this.state
                                                                      .uploadsInProgress[
                                                                      rfq
                                                                        .question_id
                                                                    ] ? (
                                                                      <div
                                                                        style={{
                                                                          marginBottom:
                                                                            "10px",
                                                                          marginTop:
                                                                            "5px",
                                                                        }}
                                                                      >
                                                                        <span className="badge badge-outline-primary">
                                                                          <span
                                                                            className="spinner-border spinner-border-sm mr-1"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                            style={{
                                                                              width:
                                                                                "0.8rem",
                                                                              height:
                                                                                "0.8rem",
                                                                              borderWidth:
                                                                                "1px",
                                                                            }}
                                                                          ></span>{" "}
                                                                          Uploading...
                                                                        </span>
                                                                      </div>
                                                                    ) : this
                                                                        .state
                                                                        .downloadLoading ? (
                                                                      <div
                                                                        style={{
                                                                          marginBottom:
                                                                            "10px",
                                                                          marginTop:
                                                                            "5px",
                                                                        }}
                                                                      >
                                                                        <span className="badge badge-outline-primary">
                                                                          <span
                                                                            className="spinner-border spinner-border-sm mr-1"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                            style={{
                                                                              width:
                                                                                "0.8rem",
                                                                              height:
                                                                                "0.8rem",
                                                                              borderWidth:
                                                                                "1px",
                                                                            }}
                                                                          ></span>{" "}
                                                                          Downloading...
                                                                        </span>
                                                                      </div>
                                                                    ) : rfq[
                                                                        `selected_answer_${rfq.question_id}`
                                                                      ] ? (
                                                                      <div
                                                                        style={{
                                                                          marginBottom:
                                                                            "10px",
                                                                          marginTop:
                                                                            "5px",
                                                                        }}
                                                                      >
                                                                        <a
                                                                          onClick={() =>
                                                                            this.downloadPdfRFQ(
                                                                              rfq[
                                                                                `selected_answer_${rfq.question_id}`
                                                                              ]
                                                                            )
                                                                          }
                                                                          style={{
                                                                            cursor:
                                                                              "pointer",
                                                                          }}
                                                                        >
                                                                          <span class="badge badge-outline-primary">
                                                                            <em class="icon ni ni-download"></em>{" "}
                                                                            View
                                                                            /
                                                                            Download
                                                                            (
                                                                            {
                                                                              rfq[
                                                                                `selected_answer_${rfq.question_id}`
                                                                              ]
                                                                            }
                                                                            )
                                                                          </span>
                                                                        </a>
                                                                        <a
                                                                          onClick={() =>
                                                                            this.handleFileRemove(
                                                                              rfq.question_id
                                                                            )
                                                                          }
                                                                          style={{
                                                                            cursor:
                                                                              "pointer",
                                                                          }}
                                                                        >
                                                                          <span class="badge badge-outline-danger ml-1">
                                                                            <em class="icon ni ni-trash"></em>{" "}
                                                                            Remove
                                                                          </span>
                                                                        </a>
                                                                      </div>
                                                                    ) : null}
                                                                    <p
                                                                      style={{
                                                                        fontSize:
                                                                          "0.8rem",
                                                                        margin:
                                                                          "5px 0 0 3px",
                                                                      }}
                                                                    >
                                                                      Supported
                                                                      file
                                                                      formats
                                                                      include:
                                                                      PNG, JPG,
                                                                      JPEG, DOC,
                                                                      DOCX, PDF,
                                                                      CSV, XLSX,
                                                                      XLS.
                                                                    </p>
                                                                    <p
                                                                      style={{
                                                                        fontSize:
                                                                          "0.8rem",
                                                                        margin:
                                                                          "2px 0 0 3px",
                                                                      }}
                                                                    >
                                                                      Maximum
                                                                      file size
                                                                      allowed:
                                                                      50MB.
                                                                    </p>
                                                                    {/* File Preview */}
                                                                    <div
                                                                      className="uploaded-file-preview"
                                                                      id={`filePreview_${rfq.question_id}`}
                                                                    ></div>
                                                                  </div>
                                                                </div>
                                                              </>
                                                            ) : rfq.question_type ===
                                                              "simple_text" ? (
                                                              <>
                                                                <div className="form-group">
                                                                  {console.log(
                                                                    "Full RFQ object:",
                                                                    rfq
                                                                  )}
                                                                  {rfq.all_answers &&
                                                                    rfq
                                                                      .all_answers
                                                                      .length >
                                                                      0 && (
                                                                      <div
                                                                        dangerouslySetInnerHTML={{
                                                                          __html:
                                                                            rfq
                                                                              .all_answers[0]
                                                                              .question_options,
                                                                        }}
                                                                      />
                                                                    )}
                                                                </div>
                                                              </>
                                                            ) : null}
                                                          </div>
                                                        ))}
                                                    </div>
                                                    <ul
                                                      className="nk-stepper-pagination pt-4 gx-4 gy-2 stepper-pagination d-flex justify-content-end"
                                                      style={{
                                                        marginBottom: "50px",
                                                      }}
                                                    >
                                                      {this.state
                                                        .currentStep ===
                                                      this
                                                        .rfq_questions_groups[0]
                                                        ?.group_id ? null : (
                                                        <li className="step-next">
                                                          <button
                                                            className="btn btn-lg btn-outline-primary"
                                                            onClick={(
                                                              event
                                                            ) => {
                                                              this.prevStep();
                                                            }}
                                                            disabled={
                                                              this.areUploadsInProgress() ||
                                                              this.state
                                                                .downloadLoading
                                                            }
                                                          >
                                                            <em className="icon ni ni-arrow-left"></em>
                                                            <span>Back</span>
                                                          </button>
                                                        </li>
                                                      )}
                                                      <li className="step-next">
                                                        <button
                                                          type="button"
                                                          className="btn btn-lg btn-primary"
                                                          onClick={
                                                            this.submitGroup
                                                          }
                                                          disabled={
                                                            this.areUploadsInProgress() ||
                                                            this.state
                                                              .downloadLoading
                                                          }
                                                        >
                                                          Continue
                                                          <em className="icon ni ni-arrow-right"></em>
                                                        </button>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </>
                                              ) : null}
                                            </>
                                          ) : null}
                                        </>
                                      ) : (
                                        <>
                                          {this.state.currentStep ===
                                          "extra" ? (
                                            <>
                                              <div className="nk-stepper-step active row g-gs">
                                                <div className="col-lg-12 mt-4 mb-2 border-top">
                                                  <div className="nk-block-head">
                                                    <div className="nk-block-head-content d-flex">
                                                      <div
                                                        className="col-md-8 col-sm-12 col-xs-12"
                                                        style={{
                                                          paddingLeft: "0px",
                                                        }}
                                                      >
                                                        <h5 className="nk-block-title title">
                                                          Add product / service{" "}
                                                        </h5>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-lg-12 mt-2 ">
                                                  <div className="row g-gs">
                                                    <div className="col-sm-8">
                                                      <div className="form-group">
                                                        <div className="form-label-group">
                                                          <label className="form-label">
                                                            Select product and
                                                            add
                                                          </label>
                                                        </div>
                                                        <div className="form-control-group">
                                                          <MutextField
                                                            id="select_service"
                                                            name="select_service"
                                                            select
                                                            label="Select Product"
                                                            value={
                                                              this.state
                                                                .select_service
                                                            }
                                                            onChange={
                                                              this.handleChange
                                                            }
                                                            SelectProps={{
                                                              native: true,
                                                            }}
                                                            helperText="Please select a product from available products and add."
                                                            variant="outlined"
                                                            fullWidth
                                                          >
                                                            <option value="default_option">
                                                              Select product /
                                                              service
                                                            </option>
                                                            {this.state
                                                              .services_rows &&
                                                            this.state
                                                              .services_rows
                                                              .length > 0 ? (
                                                              <>
                                                                {this.state.services_rows.map(
                                                                  (
                                                                    service,
                                                                    sid
                                                                  ) => (
                                                                    <>
                                                                      {service.service_checked ===
                                                                      false ? (
                                                                        <option
                                                                          key={`sets${sid}`}
                                                                          value={
                                                                            service.service_id
                                                                          }
                                                                        >
                                                                          {
                                                                            service.service_title
                                                                          }
                                                                        </option>
                                                                      ) : null}
                                                                    </>
                                                                  )
                                                                )}
                                                              </>
                                                            ) : null}
                                                          </MutextField>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                      <div className="form-group">
                                                        <label className="form-label">
                                                          &nbsp;
                                                        </label>
                                                        <div className="form-control-wrap">
                                                          <button
                                                            type="button"
                                                            className="btn btn-lg btn-primary"
                                                            onClick={() =>
                                                              this.addNewService()
                                                            }
                                                            style={{
                                                              marginTop: "6px",
                                                            }}
                                                          >
                                                            Add
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div className="row g-gs">
                                                    <div className="col-sm-12 mt-4">
                                                      <div className="invoice-bills">
                                                        <div className="table-responsive">
                                                          <table className="table table-striped table-condensed">
                                                            <thead>
                                                              <tr>
                                                                <th
                                                                  style={{
                                                                    textAlign:
                                                                      "left",
                                                                    paddingLeft:
                                                                      "1.25rem",
                                                                  }}
                                                                >
                                                                  Product Name
                                                                </th>
                                                                <th
                                                                  style={{
                                                                    textAlign:
                                                                      "left",
                                                                  }}
                                                                >
                                                                  Description
                                                                </th>
                                                                <th
                                                                  style={{
                                                                    textAlign:
                                                                      "left",
                                                                  }}
                                                                >
                                                                  Action
                                                                </th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              {this.state
                                                                .services_rows &&
                                                              this.state
                                                                .services_rows
                                                                .length > 0 ? (
                                                                <>
                                                                  {this.state.services_rows.map(
                                                                    (
                                                                      service,
                                                                      sid
                                                                    ) => (
                                                                      <>
                                                                        {service.service_checked ===
                                                                        true ? (
                                                                          <tr
                                                                            key={`ser${sid}`}
                                                                          >
                                                                            <td>
                                                                              {
                                                                                service.service_title
                                                                              }{" "}
                                                                            </td>
                                                                            <td>
                                                                              <div className="form-control-wrap">
                                                                                {
                                                                                  <a
                                                                                    onClick={() =>
                                                                                      this.showServiceDescription(
                                                                                        service.service_id
                                                                                      )
                                                                                    }
                                                                                    style={{
                                                                                      cursor:
                                                                                        "pointer",
                                                                                      color:
                                                                                        "#6576ff",
                                                                                    }}
                                                                                  >
                                                                                    <span className="badge badge-outline-secondary">
                                                                                      View
                                                                                    </span>
                                                                                  </a>
                                                                                }
                                                                              </div>
                                                                            </td>
                                                                            {/*<td>
                                                                      <div className="form-control-wrap">
                                                                        <input
                                                                          type="text"
                                                                          className="form-control"
                                                                          id="service_quantity"
                                                                          name="service_quantity"
                                                                          onChange={this.handleChangeServices(
                                                                            sid
                                                                          )}
                                                                          placeholder=""
                                                                          style={{
                                                                            width: "60px",
                                                                          }}
                                                                          value={
                                                                            service.service_quantity
                                                                          }
                                                                          required
                                                                        />
                                                                      </div>
                                                                    </td>*/}
                                                                            <td
                                                                              style={{
                                                                                textAlign:
                                                                                  "left",
                                                                                paddingTop:
                                                                                  "12px",
                                                                              }}
                                                                            >
                                                                              <a
                                                                                onClick={() =>
                                                                                  this.removeService(
                                                                                    service.service_id
                                                                                  )
                                                                                }
                                                                                style={{
                                                                                  cursor:
                                                                                    "pointer",
                                                                                  color:
                                                                                    "red",
                                                                                  fontSize:
                                                                                    "20px",
                                                                                }}
                                                                              >
                                                                                <em class="icon ni ni-trash-fill"></em>
                                                                              </a>
                                                                            </td>
                                                                          </tr>
                                                                        ) : null}
                                                                      </>
                                                                    )
                                                                  )}
                                                                </>
                                                              ) : null}
                                                            </tbody>
                                                          </table>
                                                        </div>

                                                        {/*<div class="example-alert mt-5 mb-2">
                                                  <div class="alert alert-light">
                                                    <strong>NOTE:</strong> After adding the
                                                    product, if you're unsure about the
                                                    quantity, you can leave it as 0.
                                                  </div>
                                                </div>*/}
                                                        {/* {this.state.services_rows &&
                                                this.state.services_rows.length > 0 ? (
                                                  <div class="col-md-12 mt-3 mb-2 d-flex justify-content-end">
                                                    <button
                                                      type="button"
                                                      className="btn btn-light"
                                                      onClick={() => this.closeServices()}
                                                    >
                                                      Done
                                                    </button>
                                                  </div>
                                                ) : null} */}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-lg-12 mt-4 mt-2">
                                                  <div className="nk-block-head">
                                                    <div className="nk-block-head-content d-flex">
                                                      <div
                                                        className="col-md-8 col-sm-12 col-xs-12"
                                                        style={{
                                                          paddingLeft: "0px",
                                                        }}
                                                      >
                                                        <h5 className="nk-block-title title">
                                                          Additional Information{" "}
                                                        </h5>
                                                      </div>
                                                      {/* <div className="col-md-4  col-sm-12  col-xs-12 d-flex justify-content-end">
                                                <button
                                                  type="button"
                                                  className="btn btn-dim btn-outline-primary"
                                                  onClick={this.openServices}
                                                >
                                                  Add Product / Services
                                                </button>
                                              </div> */}

                                                      <p>&nbsp;</p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className="row g-3"
                                                  style={{ width: "100%" }}
                                                >
                                                  <div className="col-sm-12">
                                                    <div className="form-group">
                                                      <label
                                                        className="form-label"
                                                        htmlFor="rfq_comments"
                                                      >
                                                        <Tooltip
                                                          title="Describe your complete requirements in request for quotation."
                                                          placement="right"
                                                        >
                                                          <em
                                                            className="icon ni ni-info"
                                                            style={{
                                                              color: "#6576ff",
                                                            }}
                                                          ></em>
                                                        </Tooltip>{" "}
                                                        Describe Your
                                                        Requirements{" "}
                                                        <sup>*</sup>
                                                      </label>
                                                      <div className="form-control-wrap">
                                                        <textarea
                                                          id="rfq_comments"
                                                          name="rfq_comments"
                                                          value={
                                                            this.state
                                                              .rfq_comments
                                                          }
                                                          onChange={
                                                            this.handleChange
                                                          }
                                                          className="form-control"
                                                          // style={{ height: "500px" }}
                                                        />
                                                        {/* <Editor
                                                  id="rfq_comments"
                                                  name="rfq_comments"
                                                  apiKey="nb3xxzwletiuxjj0vxvl62020cjdnjddx0qmuck99x1knvbt"
                                                  initialValue={this.state.rfq_comments}
                                                  selector="textarea#full-featured"
                                                  init={{
                                                    height: 500,
                                                    menubar: true,
                                                    plugins: [
                                                      "code print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                                    ],
                                                    toolbar:
                                                      "code undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor casechange removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | showcomments addcomment",
                                                  }}
                                                  onEditorChange={this.handleEditorChange}
                                                /> */}
                                                        <span
                                                          id="rfq_comments_error"
                                                          class="invalid"
                                                          style={{
                                                            color: "red",
                                                          }}
                                                        >
                                                          {
                                                            this.state
                                                              .rfq_comments_error
                                                          }
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div className="col-lg-12 mt-4">
                                                    <div className="nk-block-head">
                                                      <div className="nk-block-head-content">
                                                        <h5 className="nk-block-title title">
                                                          Attachment{" "}
                                                          <Tooltip
                                                            title="User requirement document"
                                                            placement="right"
                                                          >
                                                            <em
                                                              className="icon ni ni-info"
                                                              style={{
                                                                color:
                                                                  "#6576ff",
                                                              }}
                                                            ></em>
                                                          </Tooltip>
                                                        </h5>
                                                        <p>&nbsp;</p>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div className="col-lg-6">
                                                    <div className="form-group">
                                                      <div
                                                        className="form-control-group"
                                                        id="fileContainer"
                                                      >
                                                        <input
                                                          type="file"
                                                          id="add_picture"
                                                          className="file-block"
                                                          name="add_picture"
                                                          defaultValue={
                                                            this.state
                                                              .add_picture
                                                          }
                                                          accept=".png, .jpg, .jpeg, .pdf, .doc, .docx, .xls, .xlsx, .csv"
                                                          onChange={
                                                            this
                                                              .handleFileChange
                                                          }
                                                          required
                                                        />
                                                        {this.state
                                                          .uploadStatus ===
                                                          "uploading" && (
                                                          <div
                                                            style={{
                                                              marginBottom:
                                                                "10px",
                                                              marginTop: "5px",
                                                            }}
                                                          >
                                                            <span className="badge badge-outline-primary">
                                                              <span
                                                                className="spinner-border spinner-border-sm mr-1"
                                                                role="status"
                                                                aria-hidden="true"
                                                                style={{
                                                                  width:
                                                                    "0.8rem",
                                                                  height:
                                                                    "0.8rem",
                                                                  borderWidth:
                                                                    "1px",
                                                                }}
                                                              ></span>{" "}
                                                              Uploading...
                                                            </span>
                                                          </div>
                                                        )}
                                                        {this.state
                                                          .add_picture &&
                                                          this.state
                                                            .uploadStatus ===
                                                            "done" && (
                                                            <div
                                                              style={{
                                                                marginBottom:
                                                                  "10px",
                                                                marginTop:
                                                                  "5px",
                                                              }}
                                                            >
                                                              {/* Downloading status */}
                                                              {this.state
                                                                .downloadStatus ===
                                                              "downloading" ? (
                                                                <span className="badge badge-outline-primary">
                                                                  <span
                                                                    className="spinner-border spinner-border-sm mr-1"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                    style={{
                                                                      width:
                                                                        "0.8rem",
                                                                      height:
                                                                        "0.8rem",
                                                                      borderWidth:
                                                                        "1px",
                                                                    }}
                                                                  ></span>{" "}
                                                                  Downloading...
                                                                </span>
                                                              ) : (
                                                                <>
                                                                  {/* View/Download Button */}
                                                                  <a
                                                                    onClick={
                                                                      this
                                                                        .handleDownload
                                                                    }
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                  >
                                                                    <span className="badge badge-outline-primary">
                                                                      <em className="icon ni ni-download"></em>{" "}
                                                                      View /
                                                                      Download (
                                                                      {
                                                                        this
                                                                          .state
                                                                          .add_picture_name
                                                                      }
                                                                      )
                                                                    </span>
                                                                  </a>

                                                                  {/* Remove Button */}
                                                                  <a
                                                                    onClick={
                                                                      this
                                                                        .handleFileRemoveFun
                                                                    }
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                  >
                                                                    <span className="badge badge-outline-danger ml-1">
                                                                      <em className="icon ni ni-trash"></em>{" "}
                                                                      Remove
                                                                    </span>
                                                                  </a>
                                                                </>
                                                              )}
                                                            </div>
                                                          )}
                                                      </div>
                                                      {this.state
                                                        .rfq_attachment &&
                                                      this.state
                                                        .rfq_attachment !==
                                                        "" ? (
                                                        <div className="col-md-12 mt-2 pl-0">
                                                          <a
                                                            onClick={() =>
                                                              this.downloadPdf(
                                                                this.state
                                                                  .rfq_attachment
                                                              )
                                                            }
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            <span class="badge badge-outline-primary">
                                                              <em class="icon ni ni-download"></em>{" "}
                                                              View / Download (
                                                              {this.getFileNameFromUrlFront(
                                                                this.state
                                                                  .rfq_attachment
                                                              )}
                                                              )
                                                            </span>
                                                          </a>
                                                          <a
                                                            onClick={() => {
                                                              this.setState({
                                                                rfq_attachment:
                                                                  "",
                                                              });
                                                            }}
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            <span class="badge badge-outline-danger ml-2">
                                                              <em class="icon ni ni-trash"></em>{" "}
                                                              Remove
                                                            </span>
                                                          </a>
                                                        </div>
                                                      ) : null}

                                                      <p
                                                        style={{
                                                          fontSize: "0.8rem",
                                                          margin: "5px 0 0 3px",
                                                        }}
                                                      >
                                                        Supported file formats
                                                        include: PNG, JPG, JPEG,
                                                        DOC, DOCX, PDF, CSV,
                                                        XLSX, XLS.
                                                      </p>
                                                      <p
                                                        style={{
                                                          fontSize: "0.8rem",
                                                          margin: "2px 0 0 3px",
                                                        }}
                                                      >
                                                        Maximum file size
                                                        allowed: 50MB.
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <ul
                                                className="nk-stepper-pagination pt-4 gx-4 gy-2 stepper-pagination d-flex justify-content-end"
                                                style={{ marginBottom: "50px" }}
                                              >
                                                {!this.rfq_questions_groups ||
                                                this.rfq_questions_groups
                                                  .length < 1 ? null : (
                                                  <li className="step-next">
                                                    <button
                                                      className="btn btn-lg btn-outline-primary"
                                                      onClick={(event) => {
                                                        this.prevStep();
                                                      }}
                                                      disabled={
                                                        this.state
                                                          .uploadStatus ===
                                                          "uploading" ||
                                                        this.state
                                                          .downloadStatus ===
                                                          "downloading"
                                                      }
                                                    >
                                                      <em className="icon ni ni-arrow-left"></em>
                                                      <span>Back</span>
                                                    </button>
                                                  </li>
                                                )}
                                                {this.state
                                                  .submitRfq_button_loader ? (
                                                  <li className="step-next">
                                                    <button
                                                      type="button"
                                                      className="btn btn-lg btn-primary"
                                                    >
                                                      Processing...
                                                    </button>
                                                  </li>
                                                ) : (
                                                  <li className="step-next">
                                                    <button
                                                      type="button"
                                                      className="btn btn-lg btn-primary"
                                                      onClick={this.submitRfq}
                                                      disabled={
                                                        this.state
                                                          .uploadStatus ===
                                                          "uploading" ||
                                                        this.state
                                                          .downloadStatus ===
                                                          "downloading"
                                                      }
                                                    >
                                                      Edit RFQ
                                                    </button>
                                                  </li>
                                                )}
                                              </ul>
                                            </>
                                          ) : null}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          {/* SERVICES MODEL */}
                          <div className="modal fade" id="modalFormServices">
                            <div
                              className="modal-dialog modal-dialog-top modal-xl"
                              role="document"
                            >
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5 className="modal-title">
                                    Add product / service
                                  </h5>
                                  <a
                                    onClick={() => this.closeServices()}
                                    style={{ cursor: "pointer" }}
                                    className="close"
                                  >
                                    <em className="icon ni ni-cross"></em>
                                  </a>
                                </div>
                                <div className="modal-body">
                                  <div className="row g-gs">
                                    <div className="col-sm-8">
                                      <div className="form-group">
                                        <div className="form-label-group">
                                          <label className="form-label">
                                            Select product and add
                                          </label>
                                        </div>
                                        <div className="form-control-group">
                                          <MutextField
                                            id="select_service"
                                            name="select_service"
                                            select
                                            label="Select Product"
                                            value={this.state.select_service}
                                            onChange={this.handleChange}
                                            SelectProps={{
                                              native: true,
                                            }}
                                            helperText="Please select a product from available products and add."
                                            variant="outlined"
                                            fullWidth
                                          >
                                            <option value="default_option">
                                              Select product / service
                                            </option>
                                            {this.state.services_rows &&
                                            this.state.services_rows.length >
                                              0 ? (
                                              <>
                                                {this.state.services_rows.map(
                                                  (service, sid) => (
                                                    <>
                                                      {service.service_checked ===
                                                      false ? (
                                                        <option
                                                          key={`sets${sid}`}
                                                          value={
                                                            service.service_id
                                                          }
                                                        >
                                                          {
                                                            service.service_title
                                                          }
                                                        </option>
                                                      ) : null}
                                                    </>
                                                  )
                                                )}
                                              </>
                                            ) : null}
                                          </MutextField>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-4">
                                      <div className="form-group">
                                        <label className="form-label">
                                          &nbsp;
                                        </label>
                                        <div className="form-control-wrap">
                                          <button
                                            type="button"
                                            className="btn btn-lg btn-primary"
                                            onClick={() => this.addNewService()}
                                          >
                                            Add
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row g-gs">
                                    <div className="col-sm-12">
                                      <div className="invoice-bills">
                                        <div className="table-responsive">
                                          <table className="table table-striped table-condensed">
                                            <thead>
                                              <tr>
                                                <th
                                                  style={{
                                                    textAlign: "left",
                                                    paddingLeft: "1.25rem",
                                                  }}
                                                >
                                                  Product Name
                                                </th>
                                                <th
                                                  style={{ textAlign: "left" }}
                                                >
                                                  Description
                                                </th>

                                                <th
                                                  style={{ textAlign: "left" }}
                                                >
                                                  Action
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {this.state.services_rows &&
                                              this.state.services_rows.length >
                                                0 ? (
                                                <>
                                                  {this.state.services_rows.map(
                                                    (service, sid) => (
                                                      <>
                                                        {service.service_checked ===
                                                        true ? (
                                                          <tr key={`ser${sid}`}>
                                                            <td>
                                                              {
                                                                service.service_title
                                                              }
                                                            </td>
                                                            <td>
                                                              <div className="form-control-wrap">
                                                                {
                                                                  <a
                                                                    onClick={() =>
                                                                      this.showServiceDescription(
                                                                        service.service_id
                                                                      )
                                                                    }
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                      color:
                                                                        "#6576ff",
                                                                    }}
                                                                  >
                                                                    <span className="badge badge-outline-secondary">
                                                                      View
                                                                    </span>
                                                                  </a>
                                                                }
                                                              </div>
                                                            </td>
                                                            {/*<td>
                                                              <div className="form-control-wrap">
                                                                <input
                                                                  type="text"
                                                                  className="form-control"
                                                                  id="service_quantity"
                                                                  name="service_quantity"
                                                                  onChange={this.handleChangeServices(
                                                                    sid
                                                                  )}
                                                                  placeholder=""
                                                                  style={{
                                                                    width:
                                                                      "60px",
                                                                  }}
                                                                  value={
                                                                    service.service_quantity
                                                                  }
                                                                  required
                                                                />
                                                              </div>
                                                            </td>*/}

                                                            <td
                                                              style={{
                                                                textAlign:
                                                                  "left",
                                                              }}
                                                            >
                                                              <a
                                                                onClick={() =>
                                                                  this.removeService(
                                                                    service.service_id
                                                                  )
                                                                }
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                  color: "red",
                                                                  fontSize:
                                                                    "20px",
                                                                }}
                                                              >
                                                                <em class="icon ni ni-trash-fill"></em>
                                                              </a>
                                                            </td>
                                                          </tr>
                                                        ) : null}
                                                      </>
                                                    )
                                                  )}
                                                </>
                                              ) : null}
                                            </tbody>
                                          </table>
                                        </div>

                                        {/*<div class="example-alert mt-5 mb-2">
                                          <div class="alert alert-light">
                                            <strong>NOTE:</strong> After adding
                                            the product, if you're unsure about
                                            the quantity, you can leave it as 0.
                                          </div>
                                        </div>*/}
                                        {this.state.services_rows &&
                                        this.state.services_rows.length > 0 ? (
                                          <div class="col-md-12 mt-3 mb-2 d-flex justify-content-end">
                                            <button
                                              type="button"
                                              className="btn btn-light"
                                              onClick={() =>
                                                this.closeServices()
                                              }
                                            >
                                              Done
                                            </button>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/*  DETAILS MODAL*/}
                          <div
                            className="modal fade zoom"
                            tabIndex="-1"
                            id="modalFormDetailDescription"
                          >
                            <div
                              className="modal-dialog modal-xl modal-dialog-top"
                              role="document"
                            >
                              <div className="modal-content">
                                <div className="modal-header">
                                  <div
                                    className="modal-title"
                                    style={{
                                      textAlign: "center",
                                      fontSize: "20px",
                                    }}
                                  >
                                    <strong>
                                      <em className="icon ni ni-list-round"></em>{" "}
                                      Product Description
                                    </strong>
                                  </div>
                                  <a
                                    href="#"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <em className="icon ni ni-cross"></em>
                                  </a>
                                </div>

                                <div className="modal-body">
                                  {/* NRC DIVS START */}

                                  {this.state.serviceData ? (
                                    <>
                                      <div>
                                        {this.state.serviceData.map(
                                          (services, idx) => (
                                            <div
                                              className="card card-bordered"
                                              key={`servi${idx}`}
                                            >
                                              <div className="card-inner-group">
                                                <div className="card-inner">
                                                  <div className="sp-plan-head-group">
                                                    <div className="sp-plan-head">
                                                      {/* <h6 className="title">
                                                        Short Description
                                                      </h6> */}
                                                      <span className="text-dark">
                                                        <div
                                                          dangerouslySetInnerHTML={{
                                                            __html: services[3],
                                                          }}
                                                        />
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* DETAILS MODAL */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditRfq);
