import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import {
  listAgentMetabaseReports,
} from "./../../../config/reseller_api_calls";
import HelperClass from "./../../../config/helperClass";
import { addSettings, addMsaInfo } from "../../../actions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { Fileupload } from "../../../components/Image/Fileupload";
import PageTitle from "../ExtraComponents/PageTitle";
import DataTableExtended from "../../../components/Tables/DataTableExtended";
import { TableLoader } from "../../../components/ContentLoaders/newLoaders/TableLoader";
import Tooltip from "@material-ui/core/Tooltip";

const auth = new HelperClass();

class UserReports extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      errorMessage: "",
      agentId: "",
      successMessage: "",
      waitMessage: "",
      tableLoader: true,
      signup_link: "",
      client_login_link: "",
      copied: false,
      copied_client: false,
      copied2: false,
      cpied_value: "",
      current_id: "",
      reg_button: false,
      cancel_button: false,
      tableLoader: true,
      login_url: "",
      logo: "",
      signature_image: "",
      reports_allowed: "",
      columnServices: [
        { name: "title", label: "Title" },
        {
          name: "created_at",
          label: "Created Date",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{auth.formatDateTime(value)}</div>;
            },
          },
        },
        {
          name: "published_at",
          label: "Published Date",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{auth.formatDateTime(value)}</div>;
            },
          },
        },
        {
          name: "status",
          label: "Status",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setStatus(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "report_status",
          label: "Published Status",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setStatusPublished(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "url",
          label: "Report",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setReportLink(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "id",
          label: "Action",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setEditLink(value)}</div>;
            },
          },
        },
      ],
    };
  }

  async componentDidMount() {
    const servicesResponce = await listAgentMetabaseReports(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("Available RFQs: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "integration_missing"
    ) {
      //window.location.replace("/error");
      this.setState({
        tableLoader: false,
        tableLoaderListing: false,
        reports_allowed: "No",
      });
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message !== "integration_missing"
    ) {
      //window.location.replace("/error");
      this.setState({
        tableLoader: false,
        tableLoaderListing: false,
      });
    } else if (servicesResponce.data.status === 200) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
        tableLoaderListing: false,
        reports_allowed: "Yes",
      });
    } else {
      this.setState({
        tableLoader: false,
        tableLoaderListing: false,
      });
      //window.location.replace("/error");
    }
  }

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  setStatus = (value, tableMeta) => {
      let domain_str = value;
      //console.log("domain_str: ", domain_str);
      if (domain_str === "processing") {
        return (
          <div>
            <span className="badge badge-dim badge-danger">
              {" "}
              <em className="icon ni ni-alert-c" title="Active"></em> In process
            </span>
          </div>
        );
      } else {
        return (
          <div>
            <span className="badge badge-success">
              {" "}
              <em className="icon ni ni-done "></em> Processed
            </span>
          </div>
        );
      }
    };
  
    setStatusPublished = (value, tableMeta) => {
      let domain_str = value;
      //console.log("domain_str: ", domain_str);
      if (domain_str === 0) {
        return (
          <div>
            <span className="badge badge-dim badge-danger">
              {" "}
              <em className="icon ni ni-alert-c" title="Active"></em> Not
              Published
            </span>
          </div>
        );
      } else {
        return (
          <div>
            <span className="badge badge-success">
              {" "}
              <em className="icon ni ni-done "></em> Published
            </span>
          </div>
        );
      }
    };
  
    setReportLink = (value, tableMeta) => {
      let domain_str = value;
      let report_id = tableMeta.rowData[6];
      let is_published = tableMeta.rowData[4];
      if (domain_str && domain_str !== "") {
        if (is_published === 1) {
          return (
            <Tooltip title="View report">
              <a
                onClick={() =>
                  this.addMenuHistory(
                    "/viewAgentRequest/" +
                      report_id
                  )
                }
                style={{ cursor: "pointer" }}
              >
                <em
                  class="icon ni ni-reports text-primary"
                  style={{ fontSize: "22px" }}
                ></em>
              </a>
            </Tooltip>
          );
        } else {
          return "---";
        }
      } else {
        return "---";
      }
    };
  
    setEditLink = (value, tableMeta) => {
      let domain_str = value;
      //console.log("domain_str: ", domain_str);
      if (domain_str && domain_str !== "") {
        return (
          <Tooltip title="Edit report">
            <a
              onClick={() =>
                this.addMenuHistory(
                  "/editAgentRequest/" +
                    domain_str
                )
              }
              style={{ cursor: "pointer" }}
            >
              <em
                class="icon ni ni-edit text-primary"
                style={{ fontSize: "22px" }}
              ></em>
            </a>
          </Tooltip>
        );
      } else {
        return "---";
      }
    };

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MSA WARNING END */}
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle name="Reports" icon="icon ni ni-reports" />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          {this.state.reports_allowed === "Yes" ? (
                            <li className="nk-block-tools-opt">
                              
                                <a
                                  onClick={() =>
                                    this.addMenuHistory("/requestAgentReport")
                                  }
                                  style={{
                                    color: "#fff",
                                  }}
                                  className="btn btn-primary"
                                >
                                  <em className="icon ni ni-plus-round"></em>
                                  <span>Request Report</span>
                                </a>
                              
                            </li>
                          ) : null}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      <div className="card card-bordered">
                        <div className="card-inner">
                          {this.state.errorMessage !== "" ? (
                            <div
                              className="example-alert example-alert-revoke mt-3 mb-3"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.tableLoader === true ? (
                            TableLoader()
                          ) : (
                            <>
                              {this.state.reports_allowed === "Yes" ? (
                                <DataTableExtended
                                  columns={this.state.columnServices}
                                  tableData={this.state.tableData}
                                  title=""
                                />
                              ) : this.state.reports_allowed === "No" ? (
                                <div
                                  className="example-alert example-alert-revoke mt-3 mb-3"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Missing Integration</h4>
                                      <p>
                                        Reports are not enabled. Please contact
                                        administrator to enable it.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserReports);
