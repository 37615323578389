import { React } from "react";
import { Component } from "react";
import axios from "axios";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addSettings } from "../../../actions";
import {
  getSingleRequest,
  getSingleRfq,
} from "./../../../config/client_api_calls";

import $ from "jquery";

import BusinessInformation from "../../../components/Client/CreditApplication/BusinessInformation";
import FinancialInformation from "../../../components/Client/CreditApplication/FinancialInformation";
import TradeReferences from "../../../components/Client/CreditApplication/TradeReferences";
import SignatoryDetails from "../../../components/Client/CreditApplication/SignatoryDetails";
import SsoSuccess from "../../../components/Client/CreditApplication/Success";

import DataTableExtended from "../../../components/Tables/DataTableExtended";
import { TableLoader } from "../../../components/ContentLoaders/newLoaders/TableLoader";
import { tableLoaderListing } from "../../../components/ContentLoaders/table_loader_listing";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import RichTextEditorComponent from "../../../components/Reseller/RichTextEditorComponent";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  APP_LIVE_URL,
  ADMIN_API_URL,
  ADMIN_URL,
} from "./../../../config/config";
import MutextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import TextField from "../../../components/InputFields/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import { extendMoment } from "moment-range";
import dateFormat, { masks } from "dateformat";
const extendedMoment = extendMoment(moment);
const auth = new HelperClass();

class EditRequest extends Component {
  constructor() {
    super();
    const today = extendedMoment();
    const monthAgo = moment().subtract(7, "day");
    this.state = {
      tableData: [],
      tableDataSingle: [],
      serviceData: [],
      tableLoaderListing: true,
      tableLoader: true,
      formLoader: true,
      errorMessage: "",
      errorMessageTitle: "",
      successMessage: "",
      title: "",
      start_date: monthAgo.format("MMMM DD, YYYY"),
      end_date: today.format("MMMM DD, YYYY"),
      value: moment.range(monthAgo, today),
      description: "",
      add_picture: "",
      report_id: "",
      added_file: [],
      isEditorReady: false,
      add_pictures: [],
      add_picture_names: [],
    };
  }

  async componentDidMount() {
    this.setState({
      tableLoader: false,
      tableLoaderListing: false,
    });
    if (
      this.props.props.match.params.request_id &&
      this.props.props.match.params.request_id !== ""
    ) {
      let request_id = this.props.props.match.params.request_id;
      this.setState({ report_id: request_id });
      await this.getSingleRequest(request_id);
    }
  }

  getSingleRequest = async (request_id) => {
    const saveResponce = await getSingleRequest(
      auth.getClientAccount(),
      auth.getClientToken(),
      request_id
    );
    console.log("getSingleRequest: ", saveResponce.data);
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (
      saveResponce.data.status === 404 &&
      saveResponce.data.message === "Not Verified"
    ) {
      this.setState({
        mcqLoader: false,
        isEditorReady: true,
      });
    } else if (saveResponce.data.status === 200) {
      let added_file = [];

      if (
        saveResponce.data.data[0]["files"] &&
        saveResponce.data.data[0]["files"].length > 0
      ) {
        added_file = saveResponce.data.data[0]["files"];
        let updatedData = added_file.map((item) => ({
          ...item,
          added_file: item.added_file.split(", "), // Split string into an array by comma and space
        }));
        this.setState({
          added_file: updatedData,
        });
      }
      console.log(added_file);

      const start_date = saveResponce.data.data[0]["start_date"]
        ? moment(saveResponce.data.data[0]["start_date"])
        : moment();
      const end_date = saveResponce.data.data[0]["end_date"]
        ? moment(saveResponce.data.data[0]["end_date"])
        : moment().add(7, "days");

      this.setState({
        title: saveResponce.data.data[0]["title"],
        start_date: start_date.format("MMMM DD, YYYY"),
        end_date: end_date.format("MMMM DD, YYYY"),
        value: moment.range(start_date, end_date),
        description: saveResponce.data.data[0]["description"],
        add_picture: "",
        report_id: saveResponce.data.data[0]["id"],
        //added_file: added_file,
        isEditorReady: true,
      });
    } else {
      this.setState({
        mcqLoader: false,
        isEditorReady: true,
      });
    }
  };

  handleEditorChange = (updatedContent) => {
    //console.log('updatedContent: ',updatedContent);
    this.setState({ description: updatedContent });
  };

  setStatus = (value, tableMeta) => {
    let domain_str = value;
    //console.log("domain_str: ", domain_str);
    if (domain_str === "processing") {
      return (
        <div>
          <span className="badge badge-dim badge-danger">
            {" "}
            <em className="icon ni ni-alert-c" title="Active"></em> In process
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <span className="badge badge-success">
            {" "}
            <em className="icon ni ni-done "></em> Processed
          </span>
        </div>
      );
    }
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, h:MM:ss TT");
  };

  handleChanges = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  handleChangeCheckbox = (input) => (e) => {
    this.setState({
      [input]:
        e.target.type === "checkbox" ? e.target.checked : e.target.checked,
    });
    //console.log("handleChangeCheckbox: ", input);
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  addMenuHistory = (link) => {
    this.props.history.push(link);
  };

  onSelect = (value) => {
    this.setState({
      value,
      start_date: value.start.format("MMMM DD, YYYY"),
      end_date: value.end.format("MMMM DD, YYYY"),
      isOpen: false,
    });
  };

  onToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files); // Convert FileList to an array

    if (!selectedFiles.length) return; // No files selected

    // File type validation
    const allowedFileTypes = [".txt", ".pdf", ".csv", ".xlsx", ".xls", ".zip"];
    const invalidFiles = selectedFiles.filter(
      (file) =>
        !allowedFileTypes.includes(
          `.${file.name.split(".").pop().toLowerCase()}`
        )
    );

    if (invalidFiles.length > 0) {
      this.setState({
        docErrorMessage:
          "Invalid file type detected. Please select valid files.",
      });
      return;
    }

    // File size validation (50MB max per file)
    const maxSizeBytes = 50 * 1024 * 1024;
    const oversizedFiles = selectedFiles.filter(
      (file) => file.size > maxSizeBytes
    );

    if (oversizedFiles.length > 0) {
      this.setState({
        docErrorMessage:
          "Some files exceed the 50MB limit. Choose smaller files.",
      });
      return;
    }

    // Debugging output to ensure files are being processed correctly
    console.log("Selected Files: ", selectedFiles);
    console.log("Current add_pictures in state: ", this.state.add_pictures);

    // Append new files to existing array
    this.setState((prevState) => {
      const updatedPictures = [...prevState.add_pictures, ...selectedFiles];
      console.log("Updated Pictures: ", updatedPictures);
      // **Limit total files to 5**
      if (updatedPictures.length > 5) {
        return {
          docErrorMessage: "You can upload a maximum of 5 files.",
        };
      }
      return {
        add_pictures: updatedPictures,
        docErrorMessage: "", // Clear error message
        add_picture_names: updatedPictures.map((file) => file.name),
      };
    });
  };

  fieldValidationUsername = () => {
    let { title, description } = this.state;

    if (title === "") {
      this.setState({
        errorMessageTitle: "Missing Field",
        errorMessage: "Title is required.",
        disabled: false,
      });
      document
        .getElementById("Support_Block")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return false;
    } else if (description === "") {
      this.setState({
        errorMessageTitle: "Missing Field",
        errorMessage: "Description is required.",
        disabled: false,
      });
      document
        .getElementById("Support_Block")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return false;
    } else {
      this.setState({
        errorMessageTitle: "",
        errorMessage: "",
        disabled: true,
      });
    }
    return true;
  };

  submitRequest = async (e) => {
    e.preventDefault();
    let {
      title,
      description,
      start_date,
      end_date,
      add_picture,
      add_picture_name,
      report_id,
      added_file,
      add_pictures,
    } = this.state;

    this.setState({
      verifyPasswordButton: true,
      disabled: true,
      errorMessageTitle: "",
      errorMessage: "",
    });
    if (this.fieldValidationUsername() === true) {
      const formData = new FormData();
      formData.append("token", process.env.REACT_APP_API_TOKEN);
      formData.append("api_accountno", process.env.REACT_APP_API_ACCOUNTNO);
      formData.append("accountno", auth.getClientAccount());
      //formData.append("file", add_picture);
      //formData.append("fileName", add_picture_name);
      formData.append("title", title);
      formData.append("description", description);
      formData.append("start_date", start_date);
      formData.append("end_date", end_date);
      formData.append("report_id", report_id);
      formData.append("added_file", JSON.stringify(added_file));
      add_pictures.forEach((file) => {
        formData.append("files", file);
      });
      try {
        axios
          .post(
            ADMIN_API_URL + "metabase_external/edit_report_request",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Credentials": true,
              },
            },
            {}
          )
          .then((response) => {
            console.log("response: ", response.data);
            if (
              response.data.status === 404 &&
              response.data.message === "no_record"
            ) {
              this.setState({
                errorMessageTitle: "Missing Client",
                errorMessage: "Client does not found.",
                disabled: false,
                submitRfq_button_loader: false,
                successMessage: "",
              });
              document
                .getElementById("Support_Block")
                ?.scrollIntoView({ top: 0, behavior: "smooth" });
              setTimeout(() => {
                this.setState({
                  errorMessage: "",
                });
              }, 4000);
            } else if (response.data.status === 403) {
              this.setState({
                errorMessage: response.data.message,
                disabled: false,
                submitRfq_button_loader: false,
                successMessage: "",
              });
              document
                .getElementById("Support_Block")
                ?.scrollIntoView({ top: 0, behavior: "smooth" });
              setTimeout(() => {
                this.setState({
                  errorMessage: "",
                });
              }, 4000);
            } else if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                successMessage: "Your request has been updated successfully.",
                errorMessage: "",
                final_login_url: response.data.login_url,
                showLoginButton: true,
              });
              document
                .getElementById("Support_Block")
                ?.scrollIntoView({ top: 0, behavior: "smooth" });
              setTimeout(() => {
                window.location.replace(
                  "/" + auth.getResellerRegToken() + "/client/MetabaseReports"
                );
              }, 3000);

              //this.nextStep();
            } else {
              this.setState({
                errorMessageTitle: "System Error",
                errorMessage: "There is some error while updated the request.",
                disabled: false,
                successMessage: "",
                submitRfq_button_loader: false,
              });
              document
                .getElementById("Support_Block")
                ?.scrollIntoView({ top: 0, behavior: "smooth" });
              setTimeout(() => {
                this.setState({
                  errorMessage: "",
                });
              }, 4000);
            }
            this.setState({
              submitRfq_button_loader: false,
            });
          });
      } catch (error) {
        this.setState({
          errorMessageTitle: "System Error",
          errorMessage: "There is some error while updated the request.",
          disabled: false,
          successMessage: "",
          submitRfq_button_loader: false,
        });
        document
          .getElementById("Support_Block")
          ?.scrollIntoView({ top: 0, behavior: "smooth" });
        setTimeout(() => {
          this.setState({
            errorMessage: "",
          });
        }, 4000);
      }
    }
  };

  handleRemoveFile = (index) => {
    this.setState((prevState) => {
      const updatedPictures = [...prevState.add_pictures];
      const updatedNames = [...prevState.add_picture_names];

      // Remove file from both arrays by index
      updatedPictures.splice(index, 1);
      updatedNames.splice(index, 1);

      return {
        add_pictures: updatedPictures,
        add_picture_names: updatedNames,
      };
    });
  };

  handleRemoveAddedFile = (index) => {
    console.log("handleRemoveAddedFile: ", this.state.added_file);
    this.setState((prevState) => {
      const updatedNames = [...prevState.added_file];

      // Remove file from both arrays by index

      updatedNames.splice(index, 1);
      console.log("handleRemoveAddedFileAfter: ", updatedNames);
      return {
        added_file: updatedNames,
      };
    });
  };

  downloadPdf = async (d_f_name) => {
    //var url = d_f_name;
    //let file_name = this.extractFileNameFromUrl(d_f_name);
    var url = ADMIN_URL + "files_data/pages/" + d_f_name;
    //console.log("downloadPdfRfq: ", url);
    fetch(url)
      .then((x) => x.blob())
      .then((b) => {
        // console.log("B INSTANCE", b instanceof Blob);
        const url = window.URL.createObjectURL(b);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = d_f_name;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        this.setState({
          errorMessage: "There is some error while downloading the attachment.",
        });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 4000);
        // Handle any errors that occurred during the fetch
        console.error("Fetch error:", error);
      });
  };

  render() {
    const { step, isOpen, value, start_date, end_date } = this.state;

    const formattedDateRange = start_date ? `${start_date} - ${end_date}` : "";
    const { email, VerifyUsernameButton } = this.state;
    const values = {
      email,
      VerifyUsernameButton,
    };
    //$(".nk-block .MUIDataTable-paper-2").css("padding", "10px");
    return (
      <div
        className={
          this.props.from === "dashboard"
            ? "nk-content newcontent_padding"
            : "nk-content"
        }
        id="Support_Block"
      >
        <div
          className={
            this.props.from === "dashboard"
              ? "container-fluid newcontainerfluid_padding"
              : "container-fluid"
          }
        >
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}

              {/* RIGHTS MODAL End */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    {/*<Breadcrumbs
                  separator={<b> / </b>}
                  item={NavLink}
                  finalItem={"b"}
                  finalProps={{
                    style: { color: "red" },
                  }}
                />*/}
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        color="inherit"
                        onClick={() => this.addMenuHistory("/client")}
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <HomeIcon fontSize="inherit" className="" />
                        Home
                      </Link>
                      <Link
                        color="inherit"
                        onClick={() =>
                          this.addMenuHistory("/client/MetabaseReports")
                        }
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        Requests
                      </Link>
                      <Typography color="textPrimary" className="">
                        Edit Request
                      </Typography>
                    </Breadcrumbs>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      <div className="card card-bordered">
                        <div className="card-inner">
                          {this.state.errorMessage !== "" ? (
                            <div
                              className="example-alert example-alert-revoke mt-3 mb-3"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.successMessage !== "" ? (
                            <div
                              className="example-alert example-alert-revoke mt-3 mb-3"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Suucess</h4>
                                  <p>{this.state.successMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.tableLoader === true ? (
                            TableLoader()
                          ) : (
                            <form
                              method="post"
                              className="pageForm"
                              onSubmit={this.submitRequest}
                            >
                              <div className="row g-4">
                                <div className="col-lg-6 mt-4">
                                  <div class="form-group">
                                    <label class="form-label" for="title">
                                      Title <sup>*</sup>
                                    </label>
                                    <div class="form-control-wrap">
                                      <input
                                        type="text"
                                        class="form-control form-control-lg"
                                        id="title"
                                        name="title"
                                        value={this.state.title}
                                        onChange={this.handleChanges}
                                        placeholder=""
                                      />
                                      <small>
                                        Enter descriptive title for your report
                                        request.
                                      </small>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 mt-4">
                                  <div className="form-group">
                                    <div onClick={this.onToggle} className="">
                                      <TextField
                                        label="Date and Time"
                                        variant="outlined"
                                        fullWidth
                                        value={formattedDateRange}
                                        InputProps={{
                                          onClick: this.onToggle,
                                        }}
                                        placeholder="Select Date Range"
                                      />
                                      {/*<small>
                                        Select date and time for data range you
                                        want to include in the report.
                                      </small>*/}
                                    </div>
                                  </div>
                                  {isOpen && (
                                    <div
                                      className="fixed inset-0"
                                      style={{
                                        zIndex: 1000,
                                        position: "absolute",
                                        marginTop: "-20px",
                                      }}
                                    >
                                      <div
                                        className="absolute inset-0"
                                        onClick={this.onToggle}
                                      ></div>
                                      <div
                                        className="absolute top-40 left-1/2 -translate-x-1/2 bg-white shadow-lg rounded-lg p-4"
                                        style={{ zIndex: 1001 }}
                                      >
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "10px",
                                            right: "10px",
                                            cursor: "pointer",
                                            zIndex: 1002,
                                          }}
                                          onClick={this.onToggle}
                                        >
                                          <em className="icon ni ni-cross"></em>
                                        </div>
                                        <DateRangePicker
                                          value={value}
                                          onSelect={this.onSelect}
                                          singleDateRange={true}
                                          numberOfCalendars={2}
                                          showLegend={true}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>

                                <div
                                  className="col-lg-12"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <h6
                                    style={{ margin: 0, marginRight: "10px" }}
                                  >
                                    Description <sup>*</sup>
                                  </h6>
                                </div>

                                <div className="col-lg-12">
                                  <div className="form-group">
                                    {this.state.isEditorReady ? (
                                      <>
                                        <RichTextEditorComponent
                                          agreementContent={
                                            this.state.description
                                          }
                                          onContentChange={
                                            this.handleEditorChange
                                          }
                                        />
                                      </>
                                    ) : null}
                                  </div>
                                </div>

                                <div className="col-lg-12 mt-4">
                                  <div className="nk-block-head">
                                    <div className="nk-block-head-content">
                                      <h5 className="nk-block-title title">
                                        Other Attachment{" "}
                                        <Tooltip
                                          title="Any other supporting attachments"
                                          placement="right"
                                        >
                                          <em
                                            className="icon ni ni-info"
                                            style={{
                                              color: "#6576ff",
                                            }}
                                          ></em>
                                        </Tooltip>
                                      </h5>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <div
                                      className="form-control-group"
                                      id="fileContainer"
                                    >
                                      <input
                                        type="file"
                                        id="add_picture"
                                        className="file-block"
                                        name="add_picture"
                                        defaultValue={this.state.add_picture}
                                        accept=".png, .jpg, .jpeg, .pdf, .doc, .docx, .xlx, .xlxs, .csv"
                                        onChange={this.handleFileChange}
                                      />
                                    </div>
                                    <p
                                      style={{
                                        fontSize: "0.8rem",
                                        margin: "5px 0 0 3px",
                                      }}
                                    >
                                      Any other supporting attachments.
                                      <br />
                                      Supported file formats include: PDF, CSV,
                                      ZIP, TXT.
                                    </p>
                                    <p
                                      style={{
                                        fontSize: "0.8rem",
                                        margin: "2px 0 0 3px",
                                      }}
                                    >
                                      Maximum file size allowed: 50MB.
                                    </p>
                                    {this.state.add_picture_names.length >
                                      0 && (
                                      <ul style={{ marginLeft: "30px" }}>
                                        {this.state.add_picture_names.map(
                                          (name, index) => (
                                            <li
                                              class="text-primary"
                                              style={{ listStyle: "disc" }}
                                              key={index}
                                            >
                                              {name}{" "}
                                              <a
                                                onClick={() =>
                                                  this.handleRemoveFile(index)
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                  marginLeft: "10px",
                                                  fontSize: "16px",
                                                }}
                                                class="text-danger"
                                              >
                                                <em class="icon ni ni-trash"></em>
                                              </a>
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    )}
                                    {this.state.added_file &&
                                    this.state.added_file.length > 0 ? (
                                      <>
                                        {this.state.added_file.map(
                                          (item, index) => (
                                            <div key={index}>
                                              {item.added_file.map(
                                                (file, fileIndex) => (
                                                  <div
                                                    class="text-primary"
                                                    key={fileIndex}
                                                  >
                                                    <Tooltip title="View / Download file">
                                                      <a
                                                        onClick={() =>
                                                          this.downloadPdf(file)
                                                        }
                                                        style={{
                                                          cursor: "pointer",
                                                          marginLeft: "10px",
                                                        }}
                                                        class="text-primary"
                                                      >
                                                        {index + 1}. {file}{" "}
                                                      </a>
                                                    </Tooltip>
                                                    <Tooltip title="Delete File. Please click `Submit` after deleting this file.">
                                                      <a
                                                        onClick={() =>
                                                          this.handleRemoveAddedFile(
                                                            index
                                                          )
                                                        }
                                                        style={{
                                                          cursor: "pointer",
                                                          marginLeft: "10px",
                                                          fontSize: "16px",
                                                          color: "red",
                                                        }}
                                                        class="text-danger"
                                                      >
                                                        <em class="icon ni ni-trash"></em>
                                                      </a>
                                                    </Tooltip>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          )
                                        )}
                                      </>
                                    ) : null}
                                  </div>
                                </div>

                                <div className="col-lg-12">
                                  <div className="form-group text-right">
                                    <button
                                      type="submit"
                                      className="btn btn-lg btn-primary"
                                      disabled={this.state.disabled}
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditRequest));
